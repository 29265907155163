import React from 'react'

const hero = props => {
  let { appState } = props
    , columnSize = appState.itemSize

  if (appState.item === 2) return <div className='hero'>
    <div className='container' style={{ height: (columnSize) - 2, background: `url("${props.mainBanner}")` }}>
      <div className='column' style={{ width: columnSize - 2 }}>
        <div className='item item-top' />
      </div>
      <div className='column' style={{ width: columnSize }}>
        <div className='item item-top' />
      </div>
    </div>
    <div className='hero-info'
      style={{ height: `${(appState.itemSize * 2) - 4}px`, width: `${(appState.itemSize * 2) - 4}px`, background: appState.colorTheme.base }}>
      <div>
        {props.shortDescription}
      </div>
    </div>
  </div>

  if (appState.item === 4) return <div className='hero'>
    <div className='container' >
      <div className='column no-left' style={{ width: columnSize - 2 }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
    </div>
    <div>
      <div className='container' style={{ height: (columnSize * 2) - 2, background: `url("${props.mainBanner}")` }}>
        <div className='column' style={{ width: columnSize - 2 }}>
          <div className='item item-top' />
          <div className='item item-bottom item-top' />
        </div>
        <div className='column' style={{ width: columnSize }}>
          <div className='item item-top' />
          <div className='item item-bottom item-top' />
        </div>
        <div className='column' style={{ width: columnSize }}>
          <div className='item item-top' />
          <div className='item item-bottom item-top' />
        </div>
      </div>
      <div className='container' style={{ height: columnSize - 2, background: appState.colorTheme.base }}>
        <div className='column' style={{ width: columnSize }}>
          <div className='item item-top item-bottom' />
        </div>
        <div className='column' style={{ width: columnSize }}>
          <div className='item item-top item-bottom' />
        </div>
        <div className='column' style={{ width: columnSize }}>
          <div className='item item-bottom' />
        </div>
      </div>
    </div>
    <div className='hero-info'
      style={{ height: `${(appState.itemSize * 2) - 2}px`, width: `${(appState.itemSize * 3) - 2}px`, background: appState.colorTheme.base }}>
      <div>
        {props.shortDescription}
      </div>
    </div>
  </div>

  return <div className='hero'>
    <div className='container' style={{ background: `url("${props.mainBanner}")` }}>
      <div className='column no-left' style={{ width: columnSize - 2 }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
      <div className='column' style={{ width: columnSize }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
      <div className='column' style={{ width: columnSize }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
      <div className='column' style={{ width: columnSize }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
      <div className='column' style={{ width: columnSize }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
    </div>
    <div className='container' >
      <div className='column' style={{ width: columnSize }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
    </div>

    <div className='hero-info'
      style={{ height: `${(appState.itemSize * 2) - 2}px`, width: `${(appState.itemSize * 3) - 2}px`, background: appState.colorTheme.base }}>
      <div>
        {props.shortDescription}
      </div>
    </div>
  </div>

}
export default hero