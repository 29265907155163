import React from 'react'

const langText = {
  'es': {
    btnTitle: 'Ver años anteriores',
  },
  'en': {
    btnTitle: 'See previous years',
  }
}

const infoDesc = props => {
  let { fullDescription, appState } = props
    , lang = langText[appState.lang]

  return <div className='info-text'>
    <div>
      {
        fullDescription.switch
          ? <div style={{ color: appState.colorTheme.secondary }} onClick={props.fullDescription.onChange} className='switcher-button'>
            <div>{fullDescription.switch}</div>
          </div>
          : null
      }
      <h3>{fullDescription.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: fullDescription.paragraph }} />
      {
        props.onSeeMoreHandler
          ? <div onClick={props.onSeeMoreHandler} style={{ color: appState.colorTheme.secondary }} className='more-button'>
            <div>{lang.btnTitle}</div>
            <span className="icon-search-05"></span>
          </div>
          : null
      }
      {
        fullDescription.onClick
          ? <div onClick={fullDescription.onClick} style={{ color: appState.colorTheme.secondary }} className='more-button'>
            <div>{lang.btnTitle}</div>
            <span className="icon-search-05"></span>
          </div>
          : null
      }
    </div>
  </div>
}

export default infoDesc