import React from 'react'
import { connect } from 'react-redux'
import Menu from '../../../baseComponents/MenuButton'
import Logo from '../../../baseComponents/Logo'

import Grid from '../../../baseComponents/HeaderGrid'
import getTexts from '../../../services/langTexts'

const items = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo' },
    { x: 1, y: 0, w: 4, h: 1, component: 'Title' },
    { x: 5, y: 0, w: 1, h: 1, component: 'Menu' },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo' },
    { x: 1, y: 0, w: 2, h: 1, component: 'Title' },
    { x: 3, y: 0, w: 1, h: 1, component: 'Menu' }
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: null },
  ]
}

/* 
const Search = props => <div onClick={props.onActiveSearch} className='centered search-btn header-item'>
  <span className="icon-search-02"></span>
</div> */


const Title = props => {
  let color = props.appState.colorThemeString
    , texts = getTexts('objects-screen', props.appState.lang)
  color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1

  return <div className='centered' style={{ background: color }}>
    <div>
      <h1 className='header-title'>{texts.archiveTitle}</h1>
      {
        props.appState.item !== 2
          ? <h2 className='header-subtitle'>{texts.archiveSubtitle}</h2>
          : null
      }
    </div>
  </div>
}

const getComponent = (component, props) => {
  if (component === null) return component
  switch (component) {
    case 'Logo': return <Logo />
    case 'Title': return <Title {...props} />
    case 'Menu': return <Menu />
    default: return null
  }
}


const header = props => <Grid noScroll>
  {
    items[props.appState.headerItem].map((item, index) => <div
      className='header-item'
      style={{ background: props.appState.colorTheme.base }}
      key={index} values={{ x: item.x, y: item.y, w: item.w, h: item.h }}>
      {getComponent(item.component, { ...props, ...item })}
    </div>)
  }
</Grid>

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(header)
