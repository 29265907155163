import React, { useState, useEffect } from 'react'
import { getUrl } from '../../../utils/routesDefinition'
import { Link } from 'react-router-dom'

const langTexts = {
  'es': {
    title: 'Noticia',
    bottomText: 'Leer'
  },
  'en': {
    title: 'News',
    bottomText: 'Read'
  }
}

const News = props => {
  let data = props.news
    , lang = props.appState.lang
    , isVisible = props.isVisible
    , animationEnable = false

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  animationEnable = animate

  if (props.notAnimate) animationEnable = true

  return <Link to={`${getUrl('objectsObject')}?id=${data.id}`} className='item item-button item-container item-grid news' data-title={data.title} style={{ height: '100%', marginTop: animationEnable ? 0 : props.appState.itemSize }}>
    <div>
      <h2>{langTexts[lang].title}</h2>
      <h4>{data.title}</h4>
    </div>
    <div className='item-action-button'>
      <p>{langTexts[lang].bottomText}</p>
      <span className="icon-search-03"></span>
    </div>
  </Link>
}

export default News