import axios from './instance'
import store from '../redux/store'
import { authUser } from '../redux/actions/userAuth'


export const createUser = user => {
  let form = new FormData()
  form.append('name', user.name)
  form.append('email', user.email)
  form.append('password', user.password)
  form.append('password_confirmation', user.confirmPassword)
  form.append('birthYear', user.birthYear)
  form.append('country', user.country)
  form.append('state', user.state)
  form.append('ocupation', user.occupation)
  form.append('gender', user.gender)
  form.append('avatar', user.avatar)
  return axios({
    url: '/create_user',
    method: 'POST',
    data: form
  })
    .then(res => { return res.data })
}

export const authUserService = user => {
  let form = new FormData()
  form.append('email', user.email)
  form.append('password', user.password)
  return axios({
    url: '/login',
    method: 'POST',
    data: form,
  })
    .then(res => {
      let data = res.data
      if (res.data.id) {
        data.user.country = typeof data.user.country === 'string' ? data.user.country : ''
        data.user.state = typeof data.user.state === 'string' ? data.user.country : ''
        store.dispatch(authUser(data))
      }
      return data
    })
    .then(async (res) => {
      let user = store.getState().userAuth

      if (user.id && user.hasFavToUpdate) {
        await favUserHandler(user.hasFavToUpdate)
      }
      return res
    })
}

export const updateUserService = user => {
  let form = new FormData()
  form.append('name', user.name)
  form.append('email', user.email)
  form.append('birthYear', user.birthYear)
  form.append('state', user.state)
  form.append('ocupation', user.occupation)
  form.append('country', user.country)
  form.append('avatar', user.image)
  return axios({
    url: `/update_user/${user.id}`,
    method: 'POST',
    data: form,
  })
    .then(async (res) => {
      let user = store.getState().userAuth
      if (res.data.user.id) {
        store.dispatch(authUser({ ...user, user: res.data.user, avatar: res.data.avatar }))
        localStorage.setItem('0mZTFbxo1u', btoa(JSON.stringify({ ...user, user: res.data.user, avatar: res.data.avatar })))
        await getFavObjects()
        await getFavPersons()
      }
      return res.data
    })
}

export const favUserHandler = async (fav) => {
  let user = store.getState().userAuth
    , form = new FormData()
    , isNew = !(user.objects[fav.id] || user.person[fav.id])
  form.append('id_user', user.id)
  form.append('id_object', fav.id)
  form.append('object_type', fav.objectType)
  return axios({
    url: isNew ? '/add_favorite' : '/delete_favorite',
    method: 'POST',
    data: form
  })
    .then(async (res) => {
      await getFavObjects()
      await getFavPersons()
      return res
    })
}




export const getBanner = () => {
  return axios.get('/banners')
    .then(res => {
      let banners = res.data
      return banners[Math.floor(Math.random() * banners.length - 1)]
    })
}

export const getFavObjects = () => {
  let id = store.getState().userAuth.id
  return axios.get(`/favorited_objects/${id}`)
    .then(res => {
      let favs = {}
      for (let index = 0; index < res.data.length; index++) {
        favs[res.data[index].id] = res.data[index]
      }
      store.dispatch(authUser({ ...store.getState().userAuth, objects: favs }))
      return res.data
    })
}


export const getFavPersons = () => {
  let id = store.getState().userAuth.id
  return axios.get(`/favorited_persons/${id}`)
    .then(res => {
      let favs = {}
      for (let index = 0; index < res.data.length; index++) {
        favs[res.data[index].id] = res.data[index]
      }
      store.dispatch(authUser({ ...store.getState().userAuth, person: favs }))
      return res.data
    })
}

export const getFavExclusiveVideos = () => {
  // let id = store.getState().userAuth.id
  return axios.get('/private_videos')
    .then(res => {
      return res.data
    })
}



export const askForPassword = (email) => {
  return axios.get(`/ask_for_password/${email}`)
    .then(res => {
      return res.data
    })
}


export const validateToken = (token) => {
  return axios.get(`/check_token/${token}`)
    .then(res => {
      return res.data
    })
}


export const regeneratePwd = (token, pwd) => {
  let form = new FormData()
  form.append('token', token)
  form.append('password', pwd)
  form.append('password_confirmation', pwd)
  return axios({
    url: '/regenerate_password',
    method: 'POST',
    data: form
  })
    .then((res) => {
      return res.data
    })
}