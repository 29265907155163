import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Menu from '../../../baseComponents/MenuButton'
import { getUrl } from '../../../utils/routesDefinition'
import Grid from '../../../baseComponents/HeaderGrid'
import Logo from '../../../baseComponents/Logo'

import getTexts from '../../../services/langTexts'


const items = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo' },
    { x: 1, y: 0, w: 3, h: 1, component: 'Title' },
    { x: 4, y: 0, w: 1, h: 1, component: 'Search' },
    { x: 5, y: 0, w: 1, h: 1, component: 'Menu' },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo' },
    { x: 1, y: 0, w: 1, h: 1, component: 'Search' },
    { x: 2, y: 0, w: 1, h: 1, component: 'Title' },
    { x: 3, y: 0, w: 1, h: 1, component: 'Menu' }
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: null },
  ]
}



const Search = props => <Link className='centered header-close' to={getUrl('pes')}>
  <span className="icon-search-04"></span>
</Link>


const Title = props => {
  let color = props.appState.colorThemeString
    , texts = getTexts('PES', props.appState.lang)
  color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1

  if (props.appState.lang === 'en') return <div className={`centered animated-text ${props.isScrollActive ? 'in' : ''}`} data-title={texts.title} style={{ background: color }}>
    <h1 style={{ textAlign: 'left' }} className='header-title header-fix'>{texts.title}</h1>
  </div>

  return <div className={`centered animated-text ${props.isScrollActive ? 'in' : ''}`} data-title='PES' style={{ background: color }}>
    <h1 style={{ textAlign: 'left' }} className='header-title header-fix'>{texts.title}</h1>
    <h1 style={{ textAlign: 'left' }} className='header-title header-fix'>{texts.title2}</h1>
  </div>
}

const getComponent = (component, props) => {
  if (component === null) return component
  switch (component) {
    case 'Logo': return <Logo isScrollActive={props.isScrollActive} />
    case 'Search': return <Search {...props} />
    case 'Title': return <Title {...props} />
    case 'Menu': return <Menu />
    default: return null
  }
}


const header = props => <Grid isScrollActive={props.isScrollActive}>
  {
    items[props.appState.headerItem].map((item, index) => <div
      className='header-item'
      style={{ background: props.appState.colorTheme.base }}
      key={index} values={{ x: item.x, y: item.y, w: item.w, h: item.h }}>
      {getComponent(item.component, { ...props, ...item })}
    </div>)
  }
</Grid>

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(header)
