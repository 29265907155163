const templateOne = {
  6: [
    { x: 0, y: 0, w: 3, h: 1, component: 'Announcement', className: 'animated-home', time: 1250 },
    { x: 3, y: 0, w: 1, h: 1, component: 'Shape', value: 10, axisX: 270, axisY: 180, className: 'animated-home', time: 1350 },
    { x: 4, y: 0, w: 2, h: 2, component: 'Activity', className: 'container animated-home', time: 1150 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 9, className: 'animated-home', time: 1000 },
    { x: 1, y: 1, w: 1, h: 1, component: 'Shape', value: 21, axisX: 90, className: 'animated-home', time: 1250 },
    { x: 2, y: 1, w: 1, h: 1, component: 'Shape', value: 10, className: 'animated-home', time: 1150 },
    { x: 3, y: 1, w: 1, h: 1, component: 'Shape', value: 8, className: 'animated-home', time: 1300 },
    { x: 0, y: 2, w: 2, h: 2, component: 'DailyObject', className: 'container animated-home', time: 1150 },
    { x: 2, y: 2, w: 1, h: 1, component: 'Shape', value: 7, className: 'animated-home', time: 1500 },
    { x: 3, y: 2, w: 2, h: 1, component: 'Qoute', className: 'animated-home', time: 1350 },
    { x: 5, y: 2, w: 1, h: 1, component: 'Shape', value: 9, className: 'animated-home', time: 1100 },
    { x: 2, y: 3, w: 1, h: 1, component: 'Shape', value: 9, className: 'animated-home', time: 1300 },
    { x: 3, y: 3, w: 3, h: 2, component: 'Event', className: 'container animated-home', time: 1350 },
    { x: 0, y: 4, w: 1, h: 1, component: 'News', value: 0, className: 'animated-home', time: 1000 },
    { x: 1, y: 4, w: 1, h: 1, component: 'Shape', value: 20, axisX: 180, className: 'animated-home', time: 1500 },
    { x: 2, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisY: 180, className: 'animated-home', time: 1500 },
    { x: 0, y: 5, w: 4, h: 1, component: 'Contact' },
    { x: 4, y: 5, w: 2, h: 1, component: 'Newsletter' },
  ],
  4: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement', className: 'animated-home', time: 1250 },
    { x: 2, y: 0, w: 2, h: 2, component: 'Activity', className: 'container animated-home', time: 1050 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 6, axisX: 180, axisY: 180, className: 'animated-home', time: 1100 },
    { x: 1, y: 1, w: 1, h: 1, component: 'Shape', value: 7, className: 'animated-home', time: 1300 },
    { x: 0, y: 2, w: 2, h: 2, component: 'DailyObject', className: 'container animated-home', time: 1150 },
    { x: 2, y: 2, w: 2, h: 1, component: 'Qoute', className: 'animated-home', time: 1350 },
    { x: 2, y: 3, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, axisY: 180, className: 'animated-home', time: 1500 },
    { x: 3, y: 3, w: 1, h: 1, component: 'Shape', value: 3, axisX: 180, axisY: 180, className: 'animated-home', time: 1350 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, className: 'animated-home', time: 1450 },
    { x: 0, y: 5, w: 1, h: 1, component: 'Shape', value: 6, axisX: 180, className: 'animated-home', time: 1200 },
    { x: 1, y: 4, w: 3, h: 2, component: 'Event', className: 'container animated-home', time: 1500 },
    { x: 0, y: 6, w: 1, h: 1, component: 'News', value: 0, axisY: 180, className: 'animated-home', time: 1100 },
    { x: 1, y: 6, w: 1, h: 1, component: 'Shape', value: 3, axisX: 180, axisY: 180, className: 'animated-home', time: 1400 },
    { x: 2, y: 6, w: 1, h: 1, component: 'Shape', value: 7, axisX: 270, className: 'animated-home', time: 1250 },
    { x: 3, y: 6, w: 1, h: 1, component: 'Shape', value: 0, className: 'animated-home', time: 1500 },
    { x: 0, y: 7, w: 2, h: 1, component: 'Contact' },
    { x: 2, y: 7, w: 2, h: 1, component: 'Newsletter' },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement', className: 'animated-home', time: 1250 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 4, className: 'animated-home', time: 1100 },
    { x: 1, y: 1, w: 1, h: 2, component: 'Activity', className: 'container animated-home', time: 1050 },
    { x: 0, y: 2, w: 1, h: 2, component: 'DailyObject', className: 'container animated-home', time: 1150 },
    { x: 1, y: 3, w: 1, h: 1, component: 'News', value: 0, axisX: 180, className: 'animated-home', time: 500 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Qoute', className: 'animated-home', time: 500 },
    { x: 1, y: 4, w: 1, h: 2, component: 'Event', className: 'container animated-home', time: 500 },
    { x: 0, y: 5, w: 1, h: 1, component: 'Shape', value: 7, axisX: 180, className: 'animated-home', time: 500 },
    { x: 0, y: 6, w: 1, h: 1, component: 'Contact', className: 'animated-home', time: 500 },
    { x: 1, y: 6, w: 1, h: 1, component: 'Newsletter', className: 'animated-home', time: 500 },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 1, y: 0, w: 1, h: 1, component: null },
    { x: 2, y: 0, w: 1, h: 1, component: null },
    { x: 3, y: 0, w: 1, h: 1, component: null },
    { x: 4, y: 0, w: 1, h: 1, component: null },
    { x: 5, y: 0, w: 1, h: 1, component: null },
  ]
}

const templateTwo = {
  6: [
    { x: 0, y: 0, w: 3, h: 1, component: 'Announcement', className: 'animated-home', time: 500 },
    { x: 3, y: 0, w: 1, h: 1, component: 'Shape', value: 9, className: 'animated-home', time: 500 },
    { x: 4, y: 0, w: 2, h: 2, component: 'Activity', className: 'animated-home', time: 500 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 12, className: 'animated-home', time: 500 },
    { x: 1, y: 1, w: 1, h: 1, component: 'Shape', value: 3, className: 'animated-home', time: 500 },
    { x: 2, y: 1, w: 1, h: 1, component: 'Image', className: 'animated-home', time: 500 },
    { x: 3, y: 1, w: 1, h: 1, component: 'Shape', value: 13, className: 'animated-home', time: 500 },
    { x: 0, y: 2, w: 2, h: 2, component: 'DailyObject', className: 'animated-home', time: 500 },
    { x: 2, y: 2, w: 1, h: 1, component: 'Shape', value: 7, className: 'animated-home', time: 500 },
    { x: 3, y: 2, w: 2, h: 1, component: 'Qoute', className: 'animated-home', time: 500 },
    { x: 5, y: 2, w: 1, h: 1, component: 'Shape', value: 21, className: 'animated-home', time: 500 },
    { x: 2, y: 3, w: 1, h: 1, component: 'Shape', value: 20, className: 'animated-home', time: 500 },
    { x: 3, y: 3, w: 3, h: 2, component: 'Event', className: 'animated-home', time: 500 },
    { x: 0, y: 4, w: 1, h: 1, component: 'News', value: 5, className: 'animated-home', time: 500 },
    { x: 1, y: 4, w: 1, h: 1, component: 'Shape', value: 14, className: 'animated-home', time: 500 },
    { x: 2, y: 4, w: 1, h: 1, component: 'Shape', value: 18, className: 'animated-home', time: 500 },
    { x: 0, y: 5, w: 4, h: 1, component: 'Contact', className: 'animated-home', time: 500 },
    { x: 4, y: 5, w: 2, h: 1, component: 'Newsletter', className: 'animated-home', time: 500 },
  ],
  4: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement', className: 'animated-home', time: 500 },
    { x: 2, y: 0, w: 2, h: 2, component: 'Activity', className: 'container animated-home', time: 500 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 6, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 1, y: 1, w: 1, h: 1, component: 'Shape', value: 7, className: 'animated-home', time: 500 },
    { x: 0, y: 2, w: 2, h: 2, component: 'DailyObject', className: 'container animated-home', time: 500 },
    { x: 2, y: 2, w: 2, h: 1, component: 'Qoute', className: 'animated-home', time: 500 },
    { x: 2, y: 3, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 3, y: 3, w: 1, h: 1, component: 'Shape', value: 3, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, className: 'animated-home', time: 500 },
    { x: 0, y: 5, w: 1, h: 1, component: 'Shape', value: 6, axisX: 180, className: 'animated-home', time: 500 },
    { x: 1, y: 4, w: 3, h: 2, component: 'Event', className: 'container animated-home', time: 500 },
    { x: 0, y: 6, w: 1, h: 1, component: 'News', value: 0, axisY: 180, className: 'animated-home', time: 500 },
    { x: 1, y: 6, w: 1, h: 1, component: 'Shape', value: 3, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 2, y: 6, w: 1, h: 1, component: 'Shape', value: 7, axisX: 270, className: 'animated-home', time: 500 },
    { x: 3, y: 6, w: 1, h: 1, component: 'Shape', value: 0, className: 'animated-home', time: 500 },
    { x: 0, y: 7, w: 2, h: 1, component: 'Contact', className: 'animated-home', time: 500 },
    { x: 2, y: 7, w: 2, h: 1, component: 'Newsletter', className: 'animated-home', time: 500 },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement', className: 'animated-home', time: 500 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 4, className: 'animated-home', time: 500 },
    { x: 1, y: 1, w: 1, h: 2, component: 'Activity', className: 'container animated-home', time: 500 },
    { x: 0, y: 2, w: 1, h: 2, component: 'DailyObject', className: 'container animated-home', time: 500 },
    { x: 1, y: 3, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, className: 'animated-home', time: 500 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Qoute', className: 'animated-home', time: 500 },
    { x: 1, y: 4, w: 1, h: 2, component: 'Event', className: 'container animated-home', time: 500 },
    { x: 0, y: 5, w: 1, h: 1, component: 'News', value: 7, axisX: 180, className: 'animated-home', time: 500 },
    { x: 0, y: 6, w: 1, h: 1, component: 'ContactMin', className: 'animated-home', time: 500 },
    { x: 1, y: 6, w: 1, h: 1, component: 'Newsletter', className: 'animated-home', time: 500 },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 1, y: 0, w: 1, h: 1, component: null },
    { x: 2, y: 0, w: 1, h: 1, component: null },
    { x: 3, y: 0, w: 1, h: 1, component: null },
    { x: 4, y: 0, w: 1, h: 1, component: null },
    { x: 5, y: 0, w: 1, h: 1, component: null },
  ]
}

const templateThree = {
  6: [
    { x: 0, y: 0, w: 3, h: 1, component: 'Announcement', className: 'animated-home', time: 1250 },
    { x: 3, y: 0, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, className: 'animated-home', time: 1350 },
    { x: 4, y: 0, w: 2, h: 2, component: 'Activity', className: 'animated-home', time: 1150 },
    { x: 0, y: 1, w: 2, h: 1, component: 'Image', value: 1, className: 'animated-home', time: 1300 },
    { x: 2, y: 1, w: 1, h: 1, component: 'Shape', value: 0, axisX: 270, className: 'animated-home', time: 1200 },
    { x: 3, y: 1, w: 1, h: 1, component: 'Shape', value: 8, className: 'animated-home', time: 1150 },
    { x: 0, y: 2, w: 1, h: 1, component: 'Shape', value: 7, className: 'animated-home', time: 1400 },
    { x: 1, y: 2, w: 2, h: 2, component: 'DailyObject', className: 'animated-home', time: 1400 },
    { x: 3, y: 2, w: 2, h: 2, component: 'Qoute', className: 'animated-home', time: 1400 },
    { x: 5, y: 2, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, className: 'animated-home', time: 1400 },
    { x: 0, y: 3, w: 1, h: 1, component: 'Shape', value: 9, className: 'animated-home', time: 1400 },
    { x: 5, y: 3, w: 1, h: 1, component: 'Shape', value: 3, className: 'animated-home', time: 1400 },
    { x: 0, y: 4, w: 1, h: 1, component: 'News', value: 0, className: 'animated-home', time: 1400 },
    { x: 1, y: 4, w: 1, h: 1, component: 'Shape', value: 8, axisY: 180, className: 'animated-home', time: 1400 },
    { x: 2, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, axisY: 180, className: 'animated-home', time: 1400 },
    { x: 3, y: 4, w: 3, h: 1, component: 'Event', className: 'animated-home', time: 1400 },
    { x: 0, y: 5, w: 4, h: 1, component: 'Contact', },
    { x: 4, y: 5, w: 2, h: 1, component: 'Newsletter' },
  ],
  4: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement', className: 'animated-home', time: 1100 },
    { x: 2, y: 0, w: 2, h: 2, component: 'Activity', className: 'container animated-home', time: 1300 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 6, axisX: 180, axisY: 180, className: 'animated-home', time: 1150 },
    { x: 1, y: 1, w: 1, h: 1, component: 'Shape', value: 7, className: 'animated-home', time: 1200 },
    { x: 0, y: 2, w: 2, h: 2, component: 'DailyObject', className: 'container animated-home', time: 500 },
    { x: 2, y: 2, w: 2, h: 1, component: 'Qoute', className: 'animated-home', time: 500 },
    { x: 2, y: 3, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 3, y: 3, w: 1, h: 1, component: 'Shape', value: 3, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, className: 'animated-home', time: 500 },
    { x: 0, y: 5, w: 1, h: 1, component: 'Shape', value: 6, axisX: 180, className: 'animated-home', time: 500 },
    { x: 1, y: 4, w: 3, h: 2, component: 'Event', className: 'container animated-home', time: 500 },
    { x: 0, y: 6, w: 1, h: 1, component: 'News', value: 0, axisY: 180, className: 'animated-home', time: 500 },
    { x: 1, y: 6, w: 1, h: 1, component: 'Shape', value: 3, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 2, y: 6, w: 1, h: 1, component: 'Shape', value: 7, axisX: 270, className: 'animated-home', time: 500 },
    { x: 3, y: 6, w: 1, h: 1, component: 'Shape', value: 0, className: 'animated-home', time: 500 },
    { x: 0, y: 7, w: 2, h: 1, component: 'Contact' },
    { x: 2, y: 7, w: 2, h: 1, component: 'Newsletter' },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement', className: 'animated-home', time: 1250 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 4, className: 'animated-home', time: 1300 },
    { x: 1, y: 1, w: 1, h: 2, component: 'Activity', className: 'container animated-home', time: 1000 },
    { x: 0, y: 2, w: 1, h: 2, component: 'DailyObject', className: 'container animated-home', time: 1200 },
    { x: 1, y: 3, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, className: 'animated-home', time: 1300 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Qoute', className: 'animated-home', time: 500 },
    { x: 1, y: 4, w: 1, h: 2, component: 'Event', className: 'container animated-home', time: 500 },
    { x: 0, y: 5, w: 1, h: 1, component: 'News', value: 7, axisX: 180, className: 'animated-home', time: 500 },
    { x: 0, y: 6, w: 1, h: 1, component: 'ContactMin', className: 'animated-home', time: 500 },
    { x: 1, y: 6, w: 1, h: 1, component: 'Newsletter', className: 'animated-home', time: 500 },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 1, y: 0, w: 1, h: 1, component: null },
    { x: 2, y: 0, w: 1, h: 1, component: null },
    { x: 3, y: 0, w: 1, h: 1, component: null },
    { x: 4, y: 0, w: 1, h: 1, component: null },
    { x: 5, y: 0, w: 1, h: 1, component: null },
  ]
}


const templateFour = {
  6: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement', className: 'animated-home', time: 500 },
    { x: 2, y: 0, w: 3, h: 2, component: 'Video', className: 'animated-home', time: 500 },
    { x: 5, y: 0, w: 1, h: 1, component: 'Shape', value: 0, className: 'animated-home', time: 500 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 0, axisX: 270, className: 'animated-home', time: 500 },
    { x: 1, y: 1, w: 1, h: 1, component: 'Shape', value: 8, className: 'animated-home', time: 500 },
    { x: 5, y: 1, w: 1, h: 1, component: 'Shape', value: 9, className: 'animated-home', time: 500 },
    { x: 0, y: 2, w: 2, h: 2, component: 'DailyObject', className: 'animated-home', time: 500 },
    { x: 2, y: 2, w: 1, h: 1, component: 'Shape', value: 4, axisX: 90, className: 'animated-home', time: 500 },
    { x: 3, y: 2, w: 3, h: 1, component: 'WednesdayBtn', className: 'animated-home', time: 500 },
    { x: 2, y: 3, w: 1, h: 1, component: 'Shape', value: 7, axisX: 180, className: 'animated-home', time: 500 },
    { x: 0, y: 4, w: 1, h: 1, component: 'News', className: 'animated-home', time: 500 },
    { x: 1, y: 4, w: 1, h: 1, component: 'Shape', value: 8, axisX: 180, className: 'animated-home', time: 500 },
    { x: 2, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, className: 'animated-home', time: 500 },
    { x: 3, y: 3, w: 2, h: 2, component: 'Qoute', className: 'animated-home', time: 500 },
    { x: 5, y: 3, w: 1, h: 1, component: 'Image', value: 0, className: 'animated-home', time: 500 },
    { x: 5, y: 4, w: 1, h: 1, component: 'Shape', value: 3, className: 'animated-home', time: 500 },
    { x: 0, y: 5, w: 4, h: 1, component: 'Contact', className: 'animated-home', time: 500 },
    { x: 4, y: 5, w: 2, h: 1, component: 'Newsletter', className: 'animated-home', time: 500 },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Announcement', className: 'animated-home', time: 500 },
    { x: 1, y: 0, w: 3, h: 2, component: 'Video', className: 'container animated-home', time: 500 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 7, className: 'animated-home', time: 500 },
    { x: 0, y: 2, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, className: 'animated-home', time: 500 },
    { x: 1, y: 2, w: 1, h: 1, component: 'Shape', value: 17, className: 'animated-home', time: 500 },
    { x: 0, y: 3, w: 3, h: 1, component: 'WednesdayBtn', className: 'container animated-home', time: 500 },
    { x: 2, y: 2, w: 2, h: 1, component: 'Qoute', className: 'animated-home', time: 500 },
    { x: 3, y: 3, w: 1, h: 1, component: 'Shape', value: 4, axisY: 180, className: 'animated-home', time: 500 },
    { x: 2, y: 4, w: 2, h: 2, component: 'DailyObject', className: 'container animated-home', time: 500 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, className: 'animated-home', time: 500 },
    { x: 1, y: 4, w: 1, h: 1, component: 'Shape', value: 5, className: 'animated-home', time: 500 },
    { x: 0, y: 5, w: 1, h: 1, component: 'News', className: 'animated-home', time: 500 },
    { x: 1, y: 5, w: 1, h: 1, component: 'Shape', value: 7, className: 'animated-home', time: 500 },
    { x: 0, y: 6, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 1, y: 6, w: 1, h: 1, component: 'Shape', value: 3, axisX: 180, axisY: 180, className: 'animated-home', time: 500 },
    { x: 2, y: 6, w: 1, h: 1, component: 'Shape', value: 5, className: 'animated-home', time: 500 },
    { x: 3, y: 6, w: 1, h: 1, component: 'Shape', value: 7, axisX: 270, className: 'animated-home', time: 500 },

    { x: 0, y: 7, w: 2, h: 1, component: 'Contact' },
    { x: 2, y: 7, w: 2, h: 1, component: 'Newsletter' },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement' },
    { x: 0, y: 1, w: 2, h: 1, component: 'Video', className: 'container' },
    { x: 1, y: 2, w: 1, h: 2, component: 'WednesdayBtn', className: 'container' },
    { x: 0, y: 2, w: 1, h: 1, component: 'News', value: 4 },
    { x: 0, y: 3, w: 1, h: 2, component: 'DailyObject', className: 'container' },
    { x: 1, y: 4, w: 1, h: 1, component: 'Qoute' },
    { x: 0, y: 5, w: 1, h: 1, component: 'ContactMin' },
    { x: 1, y: 5, w: 1, h: 1, component: 'Newsletter' },

  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 1, y: 0, w: 1, h: 1, component: null },
    { x: 2, y: 0, w: 1, h: 1, component: null },
    { x: 3, y: 0, w: 1, h: 1, component: null },
    { x: 4, y: 0, w: 1, h: 1, component: null },
    { x: 5, y: 0, w: 1, h: 1, component: null },
  ]
}

/* const templateFive = {
  6: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement' },
    { x: 2, y: 0, w: 3, h: 2, component: 'Video' },
    { x: 5, y: 0, w: 1, h: 1, component: 'Shape', value: 0, },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 0, axisX: 270, },
    { x: 1, y: 1, w: 1, h: 1, component: 'Shape', value: 8, },
    { x: 5, y: 1, w: 1, h: 1, component: 'Shape', value: 9, },
    { x: 0, y: 2, w: 2, h: 2, component: 'DailyObject' },
    { x: 2, y: 2, w: 1, h: 1, component: 'Shape', value: 4, axisX: 90, },
    { x: 3, y: 2, w: 2, h: 2, component: 'Qoute' },
    { x: 5, y: 2, w: 1, h: 1, component: 'Image', value: 0 },
    { x: 2, y: 3, w: 1, h: 1, component: 'Shape', value: 7, axisX: 180, },
    { x: 5, y: 3, w: 1, h: 1, component: 'Shape', value: 3, },
    { x: 0, y: 4, w: 1, h: 1, component: 'News' },
    { x: 1, y: 4, w: 1, h: 1, component: 'Shape', value: 8, axisX: 180, },
    { x: 2, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, },
    { x: 3, y: 4, w: 3, h: 1, component: 'WednesdayBtn', },
    { x: 0, y: 5, w: 4, h: 1, component: 'Contact' },
    { x: 4, y: 5, w: 2, h: 1, component: 'Newsletter' },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Announcement' },
    { x: 1, y: 0, w: 3, h: 2, component: 'Video', className: 'container' },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 7, },
    { x: 0, y: 2, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, },
    { x: 1, y: 2, w: 1, h: 1, component: 'Shape', value: 17, },
    { x: 0, y: 3, w: 3, h: 1, component: 'WednesdayBtn', className: 'container' },
    { x: 2, y: 2, w: 2, h: 1, component: 'Qoute' },
    { x: 3, y: 3, w: 1, h: 1, component: 'Shape', value: 4, axisY: 180, },
    { x: 2, y: 4, w: 2, h: 2, component: 'DailyObject', className: 'container' },
    { x: 0, y: 4, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, },
    { x: 1, y: 4, w: 1, h: 1, component: 'Shape', value: 5, },
    { x: 0, y: 5, w: 1, h: 1, component: 'News' },
    { x: 1, y: 5, w: 1, h: 1, component: 'Shape', value: 7, },
    { x: 0, y: 6, w: 1, h: 1, component: 'Shape', value: 0, axisX: 180, axisY: 180 },
    { x: 1, y: 6, w: 1, h: 1, component: 'Shape', value: 3, axisX: 180, axisY: 180 },
    { x: 2, y: 6, w: 1, h: 1, component: 'Shape', value: 5, },
    { x: 3, y: 6, w: 1, h: 1, component: 'Shape', value: 7, axisX: 270 },

    { x: 0, y: 7, w: 2, h: 1, component: 'Contact' },
    { x: 2, y: 7, w: 2, h: 1, component: 'Newsletter' },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 1, component: 'Announcement' },
    { x: 0, y: 1, w: 2, h: 1, component: 'Video', className: 'container' },
    { x: 1, y: 2, w: 1, h: 2, component: 'WednesdayBtn', className: 'container' },
    { x: 0, y: 2, w: 1, h: 1, component: 'News', value: 4 },
    { x: 0, y: 3, w: 1, h: 2, component: 'DailyObject', className: 'container' },
    { x: 1, y: 4, w: 1, h: 1, component: 'Qoute' },
    { x: 0, y: 5, w: 1, h: 1, component: 'ContactMin' },
    { x: 1, y: 5, w: 1, h: 1, component: 'Newsletter' },

  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 1, y: 0, w: 1, h: 1, component: null },
    { x: 2, y: 0, w: 1, h: 1, component: null },
    { x: 3, y: 0, w: 1, h: 1, component: null },
    { x: 4, y: 0, w: 1, h: 1, component: null },
    { x: 5, y: 0, w: 1, h: 1, component: null },
  ]
} */

const resultList = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Image' },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape' },
    { x: 0, y: 2, w: 1, h: 1, component: 'Image' },
    { x: 0, y: 3, w: 1, h: 1, component: 'Shape' },
    { x: 0, y: 4, w: 1, h: 1, component: 'Image' },
    { x: 0, y: 5, w: 1, h: 1, component: 'Shape' },

    { x: 1, y: 0, w: 4, h: 6, component: 'SearchResult' },

    { x: 5, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 5, y: 1, w: 1, h: 1, component: 'Image' },
    { x: 5, y: 2, w: 1, h: 1, component: 'Shape' },
    { x: 5, y: 3, w: 1, h: 1, component: 'Image' },
    { x: 5, y: 4, w: 1, h: 1, component: 'Shape' },
    { x: 5, y: 5, w: 1, h: 1, component: 'Image' },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Image' },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape' },
    { x: 0, y: 2, w: 1, h: 1, component: 'Image' },
    { x: 0, y: 3, w: 1, h: 1, component: 'Shape' },
    { x: 0, y: 4, w: 1, h: 1, component: 'Image' },
    { x: 0, y: 5, w: 1, h: 1, component: 'Shape' },

    { x: 1, y: 0, w: 2, h: 6, component: 'SearchResult' },

    { x: 3, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 3, y: 1, w: 1, h: 1, component: 'Image' },
    { x: 3, y: 2, w: 1, h: 1, component: 'Shape' },
    { x: 3, y: 3, w: 1, h: 1, component: 'Image' },
    { x: 3, y: 4, w: 1, h: 1, component: 'Shape' },
    { x: 3, y: 5, w: 1, h: 1, component: 'Image' },
  ],
  2: [
    { x: 1, y: 0, w: 2, h: 6, component: 'SearchResult' },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Image' },
  ]
}


const getTemplate = template => {
  switch (template) {
    case 'template-1': return templateOne
    case 'template-2': return templateTwo
    case 'template-3': return templateThree
    case 'template-4': return templateFour
    case 'result': return resultList
    default: return templateOne
  }
}

export default getTemplate