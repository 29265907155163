import React from 'react'
import { connect } from 'react-redux'
import getTexts from '../services/langTexts'

const addressCard = props => {
  let address = getTexts('address', props.appState.lang)

  return <div className='address-card'>
    <div>
      <h2>{address.contact}</h2>
      <p>{address.addr1} {address.addr2}</p>
      <p>+52 55 5277 4947</p>
      <p><a href="mailto:info@somamexico.org" target="_top">info@somamexico.org</a></p>
      <div className='social-networks-mobile'>
        <a href='https://www.facebook.com/SOMAmexico/' rel='noopener noreferrer' target='_blank'>
          <span className="icon-search-08"></span>
        </a>
        <a href='https://twitter.com/@SOMAmexico' rel='noopener noreferrer' target='_blank'>
          <span className="icon-search-09"></span>
        </a>
        <a href='https://www.instagram.com/SOMAmexico/' rel='noopener noreferrer' target='_blank'>
          <span className="icon-search-07"></span>
        </a>
      </div>
    </div>
  </div>
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(addressCard)
