const themes = {
  'theme-1': { base: '#5977BA', secondary: '#EBEC8B', level1: '#F44C01', level2: 'trasparent' },
  'theme-2': { base: '#E89A80', secondary: '#EBEC8B', level1: '#2D3925', level2: '#5977BA' },
  'theme-3': { base: '#F0AC5E', secondary: '#EBEC8B', level1: '#5977BA', level2: '#FFCD94' },
  'theme-4': { base: '#8C9E8C', secondary: '#EBEC8B', level1: '#1C2D59', level2: '#5977BA' }
}

export const getTheme = theme => {
  let themeSelected = themes[theme]
  return themeSelected ? themeSelected : themes['theme-1']
}