import React from 'react'
import { getUrl } from '../../../utils/routesDefinition'
import { Link } from 'react-router-dom'
import { getMonth } from '../../../utils/monthDefinitions'
import { connect } from 'react-redux'
import moment from 'moment'

class CalendarItem extends React.Component {

  state = {
    hover: false
  }

  onHover = () => this.setState({ hover: true })
  onHoverEnd = () => this.setState({ hover: false })

  getParagraph = paragraph => {
    if (typeof paragraph !== 'string') return ''
    let p = paragraph.split('</p>')[0].replace('<p>', '')
    return p.length > 100 ? `<p>${p.substring(0, 97)}...</p>` : p
  }

  render() {
    let { fullname, id, lang, type, month, size, firstDate, lastDate, tag, showAll, title, year } = this.props
    let isFav = this.props.userAuth.objects[id]

    firstDate = moment(firstDate)
    lastDate = lastDate !== null && lastDate !== '' ? moment(lastDate) : null


    if (typeof type === 'string' && type === 'month') return <div className='calendar-month row' style={{ height: size / 4 }}>
      <div className='calendar-month-title-single' style={{ width: size, }}>
        <h2>{getMonth(month, lang)}</h2>
      </div>
      <div className='calendar-month-title-single' style={{ border: 'none', padding: '0 4vw' }}>
        <h2>{Number.isNaN(year) ? '' : year}</h2>
      </div>
    </div>


    let classes = ''
      , height = size

    if (showAll && this.props.item !== 2) {
      classes = 'slim-block'
      height = (size / 8) * 5
    }
    if (typeof title === 'string' && title.length > 90) title = `${title.substring(0, 87)}...`

    return <div className={`calendar-item ${classes}`} style={{ height }}>
      <div className='calendar-month-title' style={{ minWidth: size, }}>
        <h2>{firstDate.date() ? firstDate.date() : null} {getMonth(firstDate.month(), lang)} {lastDate !== null ? '/' : null}</h2>
        {lastDate !== null ? <h2>{lastDate.date()} {getMonth(lastDate.month(), lang)}</h2> : null}
      </div>
      <div className='calendar-item-container'>
        <div className='calendar-item-content'>
          <div className='calendar-item-tag'>
            <div className='box' />
            <span>{tag}</span>
          </div>
          <Link to={`${getUrl('objectsObject')}?id=${id}`}><h2>{title}</h2></Link>
          <h3>{fullname}</h3>
        </div>
        <div className='calendar-item-option'>
          <span onClick={e => this.props.onClick(e, { ...this.props.data, objectType: 'object' })} className={isFav ? 'icon-heart2' : 'icon-04'} />
          {
            this.props.appState.item !== 2
              ? <Link to={`${getUrl('objectsObject')}?id=${id}`}>
                <span className='icon-search-03' />
              </Link>
              : null
          }
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects,
  userAuth: state.userAuth
})
export default connect(mapStateToProps)(CalendarItem)
