import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const DailyObject = props => {
  let banner = props.dailyObject.banner[props.appState.item]
    , data = props.dailyObject
    , isVisible = props.isVisible
    , animationEnable = false

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  animationEnable = animate

  if (props.notAnimate) animationEnable = true

  if (props.appState.item === 2) {
    return <div className='dailyObject' style={{ height: props.appState.itemSize * 2 }}>
      <div className='row'>
        <div className='item item-grid image-cover-bg' style={{ borderBottom: '1px black solid', overflow: 'hidden' }}>
          <div className='item image-cover-bg block-box' style={{ borderBottom: '1px black solid', background: `url(${banner})`, marginTop: animationEnable ? 0 : props.appState.itemSize * 2 }} />
        </div>
        <div className='item item-grid image-cover-bg' style={{ borderBottom: '1px black solid', overflow: 'hidden' }}>
          <Link to={props.dailyObject.link} className='item item-button item-container ' style={{ borderTop: '2px black solid', background: props.appState.colorTheme.secondary, marginTop: animationEnable ? 0 : props.appState.itemSize, height: props.appState.itemSize }}>
            <div>
              <h2>{data.title}</h2>
              <h4>{data.subtitle}</h4>
            </div>
            <div className='item-action-button'>
              <p>{data.text}</p>
              <span className="icon-search-03"></span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  }

  return <React.Fragment>
    <div className='dailyObject image-cover-bg' style={{ height: props.appState.itemSize * 2 }}>
      <div className='row'>
        <div className='item item-grid item-left-top' style={{ height: props.appState.itemSize - 1, }} />
        <div className='item item-grid item-left-bottom' />
      </div>
      <div className='row'>
        <div className='item item-grid item-right-top' style={{ height: props.appState.itemSize - 1, }} />
        <div className='item item-grid item-right-bottom'>
          <Link to={props.dailyObject.link} className='item item-button item-container item-grid' data-title={data.title} style={{ height: '100%', background: props.appState.colorTheme.secondary, marginTop: animationEnable ? 0 : props.appState.itemSize, }}>
            <div>
              <h2>{data.title}</h2>
              <h4>{data.subtitle}</h4>
            </div>
            <div className='item-action-button'>
              <p>{data.text}</p>
              <span className="icon-search-03"></span>
            </div>
          </Link>
        </div>
      </div>
    </div>
    <div className='block-box image-cover-bg' style={{ marginTop: animationEnable ? -props.appState.itemSize * 2 : 0, background: `url(${banner})`, height: props.appState.itemSize * 2 }} />
  </React.Fragment>

}

export default DailyObject