export const filtersBase = {
  tag_program: [],
  tag_person: [],
  tag_object: [],
  tag_topic: [],
  year: [],
  text: '',
  sortMode: 'created_at',
  sort: 'DESC',
}

