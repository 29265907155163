import React from 'react'
import Slider from 'react-slick'

import '../../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../../node_modules/slick-carousel/slick/slick-theme.css'

const ImageContainer = props => {
  let { h, w, image, size } = props

  if (w === 2) return <div className='image-background' style={{ background: `url("${image}")`, height: size, minWidth: (size * 2) - 2 }} />

  return <div className='image-background' style={{ background: `url("${image}")`, height: size * h, minWidth: (size * 3) - 2 }} />
}

export default class Carousel extends React.Component {

  next = () => { this.slider.slickNext() }
  previous = () => { this.slider.slickPrev() }


  getGrid = () => {
    let { appState } = this.props
    console.log(this.props.appState.item)

    if (appState.item === 4) return <div className='slider-grid-container'>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box slider-button-container' style={{ background: appState.colorTheme.base, width: appState.itemSize, height: appState.itemSize - 2 }}>
          <div>
            <span className='icon-search-05 left' onClick={this.previous} />
            <span className='icon-search-05' onClick={this.next} />
          </div>
        </div>
      </div>
    </div>


    return <div className='slider-grid-container'>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box slider-button-container' style={{ background: appState.colorTheme.base, width: appState.itemSize, height: appState.itemSize - 2 }}>
          <div>
            <span className='icon-search-05 left' onClick={this.previous} />
            <span className='icon-search-05' onClick={this.next} />
          </div>
        </div>
      </div>
    </div>

    /*
    
    <div className='slider-button-container' style={{ background: appState.colorTheme.base, right: 0, top: appState.itemSize, width: appState.itemSize, height: appState.itemSize - 2 }}>
                <div>
                  <span className='icon-search-05 left' onClick={this.previous} />
                  <span className='icon-search-05' onClick={this.next} />
                </div>
              </div>
    
     */
  }


  render() {
    let { appState, slides, h, w } = this.props
    slides = slides.length ? slides : []

    const settings = {
      className: "slider variable-width",
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      variableWidth: true
    }

    return <div className='slider-container'>
      <Slider ref={c => (this.slider = c)} {...settings}>
        {
          slides.map((slide, index) => <ImageContainer
            key={index} h={h} w={w} size={appState.itemSize} image={slide}
          />)
        }
      </Slider>
      {
        appState.item !== 2
          ? this.getGrid()
          : <div className='slider-button-container-mobile' style={{ top: appState.itemSize, height: appState.itemSize / 2 }}>
            <div>
              <span className='icon-search-05 left' onClick={this.previous} />
              <span className='icon-search-05' onClick={this.next} />
            </div>
          </div>
      }
    </div>
  }
}
