import React from 'react'
import { connect } from 'react-redux'
import { isFieldValid } from '../../../utils/filedValidation'
import { regeneratePwd } from '../../../services/auth'
import { getUrl } from '../../../utils/routesDefinition'

class Content extends React.Component {

  state = {
    password: '',
    confirmPassword: '',
    step: 'recovery-form',
    fieldMessage: '',
    showAlert: false,
  }


  onChangePwd = () => {
    let field = { field: 'password', name: 'password', value: this.state.password, }
      , isValid = isFieldValid(field)
    if (this.state.confirmPassword !== this.state.password) isValid = { message: 'La contraseña no coincide' }
    if (isValid) {
      this.setState({ showAlert: true, fieldMessage: isValid.message })
      return
    }
    regeneratePwd(this.props.match.params.id, this.state.password)
      .then(res => {
        if (res.status === 200) this.setState({ step: 'recovery-success' })
        else this.setState({ step: 'recovery-error' })
      })
      .catch(err => this.setState({ step: 'recovery-error' }))
  }


  render() {
    let { appState } = this.props
      , { step } = this.state

    if (step === 'recovery-success') return <div className='container' style={{ background: appState.colorTheme.secondary }}>
      <div>
        <h2>¡Listo!</h2>
        <h3>Nueva contraseña activada</h3>
      </div>
      <div className='form-container'>
        <div className='form-btn-container'>
          <div className='form-btn' onClick={() => { this.props.history.push(getUrl('account')) }}>
            <span className='icon-search-05' />
            <p>Ingresar a mi cuenta</p>
          </div>
        </div>
        {this.state.showAlert ? <p>*{this.state.fieldMessage}</p> : null}
      </div>
      <div />
    </div>

    if (step === 'recovery-error') return <div className='container' style={{ background: appState.colorTheme.secondary }}>
      <div>
        <h2>¡Oh no! Algo salió mal. </h2>
        <h3>Intenta nuevamente</h3>
      </div>
      <div className='form-container'>
        <div className='form-btn-container'>
          <div className='form-btn' onClick={() => { this.props.history.push(getUrl('account')) }}>
            <span className='icon-search-05' />
            <p>Ingresar a mi cuenta</p>
          </div>
        </div>
        {this.state.showAlert ? <p>*{this.state.fieldMessage}</p> : null}
      </div>
      <div />
    </div>

    return <div className='container' style={{ background: appState.colorTheme.secondary }}>
      <h2>Nueva contraseña</h2>
      <div className='form-container'>
        <input type='password' placeholder='Introduce una nueva contraseña' value={this.state.password} onChange={e => { this.setState({ password: e.target.value }) }} />
        <input type='password' placeholder='Confirmar contraseña' value={this.state.confirmPassword} onChange={e => { this.setState({ confirmPassword: e.target.value }) }} />

        <div className='form-btn-container'>
          <div className='form-btn' onClick={this.onChangePwd}>
            <span className='icon-search-05' />
            <p>Enviar</p>
          </div>
        </div>
        {this.state.showAlert ? <p>*{this.state.fieldMessage}</p> : null}
      </div>
      <div />
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Content)

/*

if (this.state.step === 'recovery-success') return <div className='container' style={{ background: appState.colorTheme.secondary }}>
      <div>
        <h2>¡Listo!</h2>
        <p>Revisa tu correo electrónico</p>
      </div>
      <div className='form-container'>
        <div className='form-btn-container'>
          <div className='form-btn' onClick={() => { this.setState({ step: 'login-form' }) }}>
            <span className='icon-search-05' />
            <p>Regresar</p>
          </div>
        </div>
        {
          this.state.warningFields.map((warning, i) => <p key={i}>{warning.name}-{warning.message}</p>)
        }
      </div>
      <div className=''>
      </div>
    </div>

    if (this.state.step === 'recovery-form') return <div className='container' style={{ background: appState.colorTheme.secondary }}>
      <div>
        <h2>Login</h2>
        <p>Introduce tu correo electrónico</p>
      </div>
      <div className='form-container'>
        <input type='text' placeholder='Correo electrónico' value={this.state.email} onChange={e => { this.setState({ email: e.target.value }) }} />
        <div className='form-btn-container'>
          <div className='form-btn'>
            <span className='icon-search-05' onClick={this.onAskForPassword} />
            <p>Enviar</p>
          </div>
          <div className='form-btn' onClick={() => { this.setState({ step: 'login-form' }) }}>
            <span className='icon-search-05' />
            <p>Regresar</p>
          </div>
        </div>
        {
          this.state.warningFields.map((warning, i) => <p key={i}>{warning.name}-{warning.message}</p>)
        }
      </div>
      <div className=''>
      </div>
    </div>




*/