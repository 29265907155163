import React from 'react'
import SliderHistory from './SliderHistory'
import getTexts from '../../../services/langTexts'

export default class History extends React.Component {

  state = {
    currentYear: (new Date()).getFullYear(),
    yearList: Object.keys(this.props.years)
  }

  onSetYear = currentYear => this.setState({ currentYear })

  render() {
    let { currentYear, yearList } = this.state
      , { appState, years } = this.props
      , texts = getTexts('about-screen', appState.lang)
      , nextYears = [], pastYears = []
      , yearInfo = {}

    let validYears = {}

    for (let i = 0; i < years.length; i++) {
      if (appState.lang === years[i].lang) validYears[years[i].year] = years[i]
    }
    years = validYears
    yearList = Object.keys(years)

    if (!years[currentYear]) currentYear = yearList[yearList.length - 1]
    pastYears = yearList.filter(year => Number(year) < Number(currentYear)).reverse()
    nextYears = yearList.filter(year => Number(year) > Number(currentYear))
    yearInfo = years[currentYear]

    if (pastYears.length === nextYears.length) {
      pastYears = pastYears.filter((_, i) => i < 2)
      nextYears = nextYears.filter((_, i) => i < 2)
    } else if (nextYears.length === 2) {
      pastYears = pastYears.filter((_, i) => i < 2)
    } else if (pastYears.length === 0) {
      nextYears = nextYears.filter((_, i) => i < 4)
    } else if (nextYears.length === 0) {
      pastYears = pastYears.filter((_, i) => i < 4)
    } else if (pastYears.length === 1) {
      nextYears = nextYears.filter((_, i) => i < 3)
    } else if (nextYears.length === 1) {
      pastYears = pastYears.filter((_, i) => i < 3)
    } else {
      pastYears = pastYears.filter((_, i) => i < 2)
      nextYears = nextYears.filter((_, i) => i < 2)
    }

    //  if (nextYears.length < 3 && pastYears.length > 0) pastYears = pastYears.filter((_, i) => i < pastYears.length - nextYears.length)

    pastYears = pastYears.reverse()

    let pastYearStyle = { color: pastYears.length ? 'black' : '#4e69a2', cursor: pastYears.length ? 'pointer' : 'default' }
      , nextYearStyle = { color: nextYears.length ? 'black' : '#4e69a2', cursor: nextYears.length ? 'pointer' : 'default' }

    if (appState.item === 6) return <div className='container'>
      <div className='column history-box-container' style={{ width: '50%', maxWidth: '50%' }}>
        <div className='history-box-content'>
          <h2 className='header-title'>{texts.history}</h2>
          <h2 className='year-item'>{currentYear}</h2>
          <div className='history-text' dangerouslySetInnerHTML={{ __html: yearInfo.body }} />
        </div>
        <div className='row no-bottom history-box-years-control' style={{ maxHeight: 100, height: 150 }}>
          <div className='row no-bottom no-top years-control'>
            <span style={pastYearStyle} className='icon-search-05 left' onClick={() => { if (pastYears.length > 0) this.onSetYear(currentYear - 1) }} />
            {
              pastYears.map(year => <p key={year} onClick={() => this.onSetYear(year)}>{year}</p>)
            }

            <p style={{ color: appState.colorTheme.secondary }}>{currentYear}</p>
            {
              nextYears.map(year => <p key={year} onClick={() => this.onSetYear(year)}>{year}</p>)
            }
            <span style={nextYearStyle} onClick={() => { if (nextYears.length > 0) this.onSetYear(Number(currentYear) + 1) }} className='icon-search-05 right' />
          </div>
        </div>
      </div>
      <div className='image-background image-cover-bg row no-top no-bottom column' style={{ background: `url("${yearInfo.banner}")`, width: '50%', maxWidth: '50%' }}>
        <SliderHistory slides={yearInfo.banners} {...this.props} />
      </div>
    </div>


    if (appState.item === 4) return <div className='container'>
      <div className='column history-box-container' style={{ width: '50%', maxWidth: '50%' }}>
        <div className='history-box-content'>
          <h2 className='header-title-middle'>Historia</h2>
          <h2 className='year-item'>{currentYear}</h2>
          <div className='history-text' dangerouslySetInnerHTML={{ __html: yearInfo.body }} />
        </div>
        <div className='row no-bottom history-box-years-control' style={{ maxHeight: 100, height: 150 }}>
          <div className='row no-bottom no-top years-control'>
            <span style={pastYearStyle} className='icon-search-05 left' onClick={() => { if (pastYears.length > 0) this.onSetYear(currentYear - 1) }} />
            {
              pastYears.map(year => <p key={year} onClick={() => this.onSetYear(year)}>{year}</p>)
            }

            <p style={{ color: appState.colorTheme.secondary }}>{currentYear}</p>
            {
              nextYears.map(year => <p key={year} onClick={() => this.onSetYear(year)}>{year}</p>)
            }
            <span style={nextYearStyle} onClick={() => this.onSetYear(Number(currentYear) + 1)} className='icon-search-05 right' />
          </div>
        </div>
      </div>
      <div className='image-background image-cover-bg row no-top no-bottom column' style={{ background: `url("${yearInfo.banner}")`, width: '50%', maxWidth: '50%' }}>
        <SliderHistory slides={yearInfo.banners} {...this.props} />
      </div>
    </div>

    return <div className='container column'>
      <div className='image-background row no-top' style={{ background: `url("${yearInfo.banner}")`, maxHeight: (appState.itemSize) }}>
        <SliderHistory slides={yearInfo.banners} {...this.props} />
      </div>
      <div className='column history-box-container' >
        <div className='history-box-content'>
          <h2 className='header-title'>Historia</h2>
          <h2 className='year-item'>{currentYear}</h2>
          <div className='history-text' dangerouslySetInnerHTML={{ __html: yearInfo.body }} />
        </div>
        <div className='row no-bottom history-box-years-control' style={{ maxHeight: 100, height: 150 }}>
          <div className='row no-bottom no-top years-control'>
            <span style={pastYearStyle} className='icon-search-05 left' onClick={() => { if (pastYears.length > 0) this.onSetYear(currentYear - 1) }} />
            <p style={{ color: appState.colorTheme.secondary }}>{currentYear}</p>
            <span style={nextYearStyle} onClick={() => this.onSetYear(Number(currentYear) + 1)} className='icon-search-05 right' />
          </div>
        </div>
      </div>
    </div>
  }
}
