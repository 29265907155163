import React from 'react'

export const getImage = () => {
  let type = [
    'water', 'fire', 'mexico', 'art', 'artist', 'artisan', 'world', 'colors', 'paint',
    'draw', 'creative', 'studio', 'industry', 'fancy', 'flow', 'interest', 'wall', 'waves', 'ocean',
    'harmony', 'photo',
  ]
    , random = Math.floor(Math.random() * (type.length + 0) + 0)
  return type[random]
}

const image = props => {
  if (props.w === 2) return <div style={{
    background: props.cover ? `url(${props.cover})` : `url(https://source.unsplash.com/1600x900/?${props.value ? props.value : getImage()})`
  }} className='image-cover'>
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
      <div style={{ height: '100%', width: '50%', borderRight: '1px solid black' }} />
      <div style={{ height: '100%', width: '50%', borderLeft: '1px solid black' }} />
    </div>
  </div>
  return <div style={{
    background: props.cover ? `url(${props.cover})` : `url(https://source.unsplash.com/1600x900/?${props.value ? props.value : getImage()})`
  }} className='image-cover'></div>
}


export default image