import React from 'react'
import SearchLayout from '../../baseComponents/SearchLayout'
import Loading, { maxAnimationTime } from './components/Loader'
import Loader from '../../baseComponents/Loading'
import Header from './components/Header'
import { connect } from 'react-redux'
import Announcement from './components/Announcement'
import Activity from './components/Activity'
import DailyObject from './components/DailyObject'
import Event from './components/Event'
import Qoute from './components/Quote'
import Image from '../../baseComponents/Image'
import Video from './components/Video'
import WednesdayBtn from './components/WednesdayBtn'
import News from './components/News'
import Shape from './components/Shape'
import SearchResult from './components/SearchResult'
import AddressCard from '../../baseComponents/AddressCard'
import ContactInfo from '../../baseComponents/ContactInfo'
import Newsletter from '../../baseComponents/Newsletter'
import ContactMin from '../../baseComponents/ContactMin'
import Grid from '../../baseComponents/Grid'
import { AnimatedBox } from './components/Loader'

import getTemplate from './templates'
import { homeResourses } from '../../services/pageResources'

import './HomeScreen.css'

class HomeScreen extends React.Component {
  state = {
    settings: null,
    isFetching: true,
    hasError: null,
    layoutBreak: false,
    items: [],
    template: 'template-1',
    animation: false,
    isAnimationLoaderActive: true,
  }

  getComponent = (component, props) => {
    let { searchResult } = this.state
    // return component
    if (component === null) return component
    switch (component) {
      case 'Announcement': return <Announcement {...props} />
      case 'Activity': return <Activity {...props} />
      case 'DailyObject': return <DailyObject {...props} />
      case 'Event': return <Event {...props} />
      case 'WednesdayBtn': return <WednesdayBtn {...props} />
      case 'News': return <News {...props} />
      case 'Qoute': return <Qoute {...props} />
      case 'Image': {
        return <AnimatedBox {...props}>
          <div style={{ height: props.appState.itemSize, width: '100%' }} >
            {
              props.w === 2
                ? <React.Fragment>
                  <div style={{ height: '100%', width: '50%', borderRight: '2px solid black' }} />
                  <div style={{ height: '100%', width: '50%', borderLeft: '2px solid black' }} />
                </React.Fragment>
                : null
            }
          </div>
          <Image {...props} cover={props.backgroundImages[props.value]} />
        </AnimatedBox>
      }
      case 'Video': return <Video {...props} />
      case 'Shape': return <Shape {...props} />
      case 'SearchResult': return <SearchResult {...props} resultItems={searchResult} />
      case 'Contact': return <AddressCard {...this.props.appState} />
      case 'Info': return <ContactInfo />
      case 'Newsletter': return <Newsletter />
      case 'ContactMin': return <ContactMin />
      default: return null
    }
  }

  renderButton = () => {
    let { lang } = this.props.appState
    if (lang === 'en') {
      const script = document.createElement('script')
      script.id = 'button-en'
      script.src = (function (w, d, s, n, a) {
        if (!w[n]) {
          var l = 'call,catch,on,once,set,then,track'
            .split(','), i, o = function (n) {
              return 'function' == typeof n ? o.l.push([arguments]) && o
                : function () { return o.l.push([n, arguments]) && o }
            }, t = d.getElementsByTagName(s)[0],
            j = d.createElement(s); j.async = !0; j.src = 'https://cdn.fundraiseup.com/widget/' + a;
          t.parentNode.insertBefore(j, t); o.s = Date.now(); o.v = 4; o.h = w.location.href; o.l = [];
          for (i = 0; i < 7; i++)o[l[i]] = o(l[i]); w[n] = o
        }
      })(window, document, 'script', 'FundraiseUp', 'ADQMPSVG');
      this.showFunButton()
      console.log(this.props.appState.lang)
    } else {
      this.removeFunButton()
    }
  }

  showFunButton = () => {
    let buttonEn = document.getElementsByClassName('fun-widget')
    buttonEn = Array.from(buttonEn)
    for (let index = 0; index < buttonEn.length; index++) {
      let iframe = buttonEn[index]
      iframe.style.display = 'block'
    }
  }

  removeFunButton = () => {
    let buttonEn = document.getElementsByClassName('fun-widget')
    buttonEn = Array.from(buttonEn)
    for (let index = 0; index < buttonEn.length; index++) {
      let iframe = buttonEn[index]
      iframe.style.display = 'none'
    }
  }

  async componentDidMount() {
    try {
      let resources = await homeResourses(this.props.location.search)
      setTimeout(() => {
        let items = getTemplate(resources.data.template)[this.props.appState.item]
        document.getElementsByTagName('BODY')[0].className = resources.data.template
        this.setState({ isFetching: false, settings: resources.data, items, layoutBreak: true, template: resources.data.template }, () => {
          this.setState({ layoutBreak: false })
          this.setState({ animation: true })
          setTimeout(() => {
            this.setState({ isAnimationLoaderActive: false })
          }, maxAnimationTime[resources.data.template]);
        })
      }, 500)
    } catch (error) {
      this.setState({ isFetching: false, hasError: error })
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.appState.item !== nextProps.appState.item && this.state.settings) {
      let items = getTemplate(this.state.settings.template)[nextProps.appState.item]
      this.setState({ layoutBreak: true, isFetching: true }, () => {
        this.setState({ items, layoutBreak: false, isFetching: false })
      })
    }
    if (this.props.appState.lang !== nextProps.appState.lang) {
      try {
        let resources = await homeResourses()
        this.setState({
          isFetching: true,
          hasError: null,
          layoutBreak: false,
          items: [],
          isSearchActive: false,
          suggestions: [],
          suggestionsBase: [],
          hasSuggestions: false,
          searchText: '',
          searchResult: [],
          showResult: false,
          template: resources.data.template,
          animation: false,
          isAnimationLoaderActive: true,
        })
        setTimeout(() => {
          let items = getTemplate(resources.data.template)[this.props.appState.item]
          this.setState({ isFetching: false, settings: resources.data, items })
          this.setState({ animation: true })
          setTimeout(() => {
            this.setState({ isAnimationLoaderActive: false })
          }, maxAnimationTime[resources.data.template]);
        }, 500)
      } catch (error) {
        this.setState({ isFetching: false, hasError: error })
      }
    }
  }

  componentWillUnmount() {
    this.removeFunButton()
  }

  render() {
    const { isFetching, hasError, animation, isAnimationLoaderActive, template } = this.state

    if (hasError) return <div>{hasError.toString()}</div>

    let shouldNotAnimate = navigator.userAgent.search('/13.0.3') !== -1

    if (shouldNotAnimate) {
      if (isFetching) return <Loader />

      return <div id='home'>
        {
          /*
          <h1 style={{ textAlign: 'center', color: 'white' }}>
            Plantilla: {settings.template.replace('template-', '')} |
            Grid: {this.props.appState.item} |
            tema: {settings.colors.replace('theme-', '')} |
            quote: {settings.quote.body.length}
          </h1>
          */
        }
        <SearchLayout home={true} header={<Header notAnimate={true} template={this.state.template} banner={this.state.settings.backgroundImages} />}>
          {this.renderButton()}
          <Grid>
            {
              this.state.items.map((item, index) => {
                return <div
                  className={item.className ? item.className : ''}
                  style={{ background: this.props.appState.colorTheme.base }}
                  key={index + 7} values={{ x: item.x, y: item.y, w: item.w, h: item.h, isResizable: false, static: true }}>
                  {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item, notAnimate: true })}
                </div>
              })
            }
          </Grid>
        </SearchLayout>
      </div>
    }

    return <div id='home'>
      {
        isAnimationLoaderActive
          ? <div style={{ position: 'fixed', top: this.props.appState.item === 2 ? 0 : 4, left: 0, right: 0, bottom: 0, zIndex: 100000000000 }}>
            <Loading isVisible={animation} loading={!isFetching} template={template} />
          </div>
          : null
      }
      {
        !isFetching
          ? <SearchLayout home={true} header={<Header isVisible={animation} template={this.state.template} banner={this.state.settings.backgroundImages} />}>
            {this.renderButton()}
            <Grid>
              {
                this.state.items.map((item, index) => {
                  return <div
                    className={item.className ? item.className : ''}
                    style={{ background: this.props.appState.colorTheme.base }}
                    key={index + 7} values={{ x: item.x, y: item.y, w: item.w, h: item.h, isResizable: false, static: true }}>
                    {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item, isVisible: animation })}
                  </div>
                })
              }
            </Grid>
          </SearchLayout>
          : null
      }
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState
})


export default connect(mapStateToProps)(HomeScreen)