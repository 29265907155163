import React from 'react'
import { getJSON } from '../../../utils/convertParams2JSON'

const YT = window.YT

export default class Video extends React.Component {

  state = {
    isVideoAvailable: false,
    isPlaying: false,
  }

  video = null

  onResizeListener = () => {
  }

  onPlayerReady = () => { this.setState({ isVideoAvailable: true }) }

  onPlayVideo = () => {
    this.setState({ isPlaying: true }, () => this.video.playVideo())
  }

  onPlayerStateChange = status => {
    if (status.data === 0) this.setState({ isPlaying: false })
  }

  componentDidMount() {
    let { url } = this.props
    if (url.search('youtu.be') !== -1) {
      let shortId = url.split('/')
      shortId = shortId[shortId.length - 1]
      url = `${url}?v=${shortId}`
    }
    url = url.split('?').length > 1 ? url.split('?')[1] : 'v=qVIMj-kL4kY'
    url = getJSON(url)
    url = url.v ? url.v : 'qVIMj-kL4kY'
    this.video = new YT.Player('video-placeholder', {
      width: '100%',
      height: '100%',
      videoId: url,
      fitToBackground: true,
      pauseOnScroll: false,
      repeat: false,
      mute: true,
      playerVars: {
        modestbranding: 0,
        controls: 1,
        showinfo: 0,
        wmode: 'transparent',
        branding: 0,
        rel: 0,
        autohide: 0,
        origin: window.location.origin
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange
      }
    })
    window.addEventListener('resize', this.onResizeListener)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeListener)
  }

  render() {
    return <div className='video-container'>
      <div id="video-placeholder"></div>
    </div>
  }
}

/*
state = {
    isVideoAvailable: false,
    isPlaying: false,
  }

  video = null

  onPlayerReady = () => { this.setState({ isVideoAvailable: true }) }

  onPlayVideo = () => {
    this.setState({ isPlaying: true }, () => this.video.playVideo())
  }

  onPlayerStateChange = status => {
    if (status.data === 0) this.setState({ isPlaying: false })
  }

  componentDidMount() {
    let { h, w, appState } = this.props
    this.video = new YT.Player('video-placeholder', {
      width: appState.itemSize * w - 4,
      height: appState.itemSize * h - 4,
      videoId: 'qVIMj-kL4kY',
      fitToBackground: true,
      pauseOnScroll: false,
      repeat: false,
      mute: true,
      playerVars: {
        modestbranding: 0,
        controls: 0,
        showinfo: 0,
        wmode: 'transparent',
        branding: 0,
        rel: 0,
        autohide: 0,
        origin: window.location.origin
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange
      }
    })
  }

  render() {
    let { colorTheme, item } = this.props.appState
      , { isPlaying, isVideoAvailable } = this.state
    return <div className='video-container'>
      <div id="video-placeholder"></div>
      {
        item === 6
          ? <div className='video-grid'>
            <div className='row'>
              <div className='item item-grid' />
              <div className='item item-grid' />
            </div>
            <div className='row'>
              <div className='item item-grid' />
              <div className='item item-grid' />
            </div>
            <div className='row'>
              <div className='item item-grid' />
              {
                !isPlaying && isVideoAvailable
                  ? <div className='item item-grid video-button' onClick={this.onPlayVideo} style={{ background: colorTheme.secondary }}>
                    <span className="icon-search-05"></span>
                  </div>
                  : <div className='item item-grid' />
              }
            </div>
          </div>
          : null
      }
    </div>
  }

*/