import React from 'react'
import FiltersScreen from './components/FiltersScreen'
import Loading from '../../baseComponents/Loading'
import Header from './components/Header'
import Grid from './components/Grid'
import Content from './components/Content'
import Filters from './components/Filters'
import NotFound from '../NotFoundScreen'
import getTemplate from './template'
import { connect } from 'react-redux'
import { getFilterList, searchObjects } from '../../services/pageResources'
import { applyFilters, setInitialized } from '../../redux/actions/objects'

import './ObjectsScreen.css'

class ObjectsScreen extends React.Component {

  state = {
    isFetching: true,

    items: [],
    error: null,
    mode: 'image',
    sort: 'cal',
  }

  setMode = mode => this.setState({ mode })

  setSortMode = sort => {
    let mode = sort.split('-')
    sort = mode[0]
    mode = mode[1]
    this.setState({ sort, }, () => {
      this.props.dispatch(applyFilters({ ...this.props.objects.filtersApplied, sortMode: sort === 'name' ? 'title' : 'created_at', sort: mode }))
      this.onSearch()
    })
  }

  onSearch = async () => {
    this.setState({ suggestionItems: [], isFetching: true }, async () => {
      try {
        let data = await searchObjects()
          , { filtersApplied } = this.props.objects

        this.props.history.replace(`${this.props.location.pathname}?search=${btoa(JSON.stringify(filtersApplied))}`)
        this.setState({ isFetchingEnd: true, items: data.results }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false })
          }, 800)
        })
      } catch (error) {
        this.setState({ isFetchingEnd: true, error }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false })
          }, 800)
        })
      }
    })
  }

  getComponent = (component, props) => {
    let { items, mode, sort } = this.state
    if (component === null) return component
    switch (component) {
      case 'Content': return <Content {...props} items={items} onSearch={this.onSearch} sort={sort} mode={mode} setSortMode={this.setSortMode} setMode={this.setMode} />
      case 'Filters': return <Filters {...props} onSearch={this.onSearch} />
      default: return null
    }
  }

  getData = async () => {
    try {
      if (!this.props.objects.initialized) {
        await getFilterList()
        this.setState({ isFetchingEnd: true }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false })
          }, 800)
        })
      }
      else {
        await getFilterList()
        this.onSearch()
      }
    } catch (error) {
      this.setState({ isFetchingEnd: true, error, }, () => {
        setTimeout(() => {
          this.setState({ isFetching: false })
        }, 800)
      })
    }
  }

  async componentDidMount() {
    if (this.props.location.search.search('search') !== -1) {
      let search = this.props.location.search.split('search=')[1]
      try {
        search = atob(search)
        search = JSON.parse(search)
        this.props.dispatch(applyFilters(search))
        this.props.dispatch(setInitialized(true))
        await getFilterList()
        this.onSearch()
      } catch (error) {
        console.log('error', error)
        this.getData()
      }
    } else {
      this.getData()
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.appState.lang !== this.props.appState.lang) {
      this.setState({
        isFetching: true,
        items: [],
        error: null,
      }, this.getData)
    }
  }

  render() {
    let { objects } = this.props
    if (this.state.isFetching) return <Loading />
    if (this.state.error) return <NotFound />
    if (!objects.initialized) return <FiltersScreen onSearch={this.onSearch} />
    return <div>
      <Header />
      <Grid half>
        {
          getTemplate()[this.props.appState.item].map((item, index) => <div
            key={index}
            values={{ x: item.x, y: item.y, w: item.w, h: item.h, searchMode: item.searchMode }}
          >
            {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
          </div>)
        }
      </Grid>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects
})
export default connect(mapStateToProps)(ObjectsScreen)
