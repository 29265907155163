const template = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 0, w: 2, h: 2, component: 'Banner' },
    { x: 3, y: 0, w: 2, h: 2, component: 'Content' },
    { x: 5, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape' },
    { x: 5, y: 1, w: 1, h: 1, component: 'Block' },
    { x: 0, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 2, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 3, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 4, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 5, y: 2, w: 1, h: 1, component: 'Block' },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 0, w: 2, h: 2, component: 'Content' },
    { x: 3, y: 0, w: 1, h: 1, component: 'Shape' },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape' },
    { x: 3, y: 1, w: 1, h: 1, component: 'Block' },
    { x: 0, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 2, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 3, y: 2, w: 1, h: 1, component: 'Block' },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 3, component: 'Content', fullMode: true },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
  ]
}



const getTemplate = () => {
  return template
}

export default getTemplate
