import React from 'react'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'

const infoBox = props => {
  let { fullDescription, appState } = props
    , columnSize = appState.itemSize

  if (appState.item === 2) return <div className='info-container no-top no-bottom'>
    <div className='column no-left item background' style={{ width: columnSize, background: `url("${fullDescription.banner}")` }} />
    <Link className='column no-right item item-button' style={{ width: columnSize, maxHeight: columnSize, background: appState.colorTheme.secondary }} to={getUrl('summer-about')}>
      <div>
        <h2>DETAILS</h2>
      </div>
      <div className='item-action-button'>
        <p>LEARN MORE</p>
        <span className="icon-search-03"></span>
      </div>
    </Link>
  </div>

  if (appState.item === 4) return <div className='info-container background' style={{ background: `url("${fullDescription.banner}")` }}>
    <div className='column no-right' style={{ width: columnSize - 2 }}>
      <div className='item item-top' />
      <a href={`${fullDescription.button[0].file}`} target='blank' className='item item-center item-button' style={{ maxHeight: columnSize, background: appState.colorTheme.base }}>
        <div>
          <h2>{fullDescription.button[0].title}</h2>
        </div>
        <div className='item-action-button'>
          <p>{fullDescription.button[0].text}</p>
          <span className="icon-search-03"></span>
        </div>
      </a>
      <Link className='item item-bottom item-button' style={{ maxHeight: columnSize, background: appState.colorTheme.secondary }} to={getUrl('summer-about')}>
        <div>
          <h2>DETAILS</h2>
        </div>
        <div className='item-action-button'>
          <p>LEARN MORE</p>
          <span className="icon-search-03"></span>
        </div>
      </Link>
    </div>
  </div>

  return <div className='info-container background no-bottom' style={{ background: `url("${fullDescription.banner}")` }}>
    <div className='column no-left' style={{ width: columnSize - 2 }}>
      <div className='item item-top' />
      <div className='item item-center' />
      <div className='item item-bottom' style={{ maxHeight: columnSize, height: columnSize }} />
    </div>
    <div className='column' style={{ width: columnSize - 2 }}>
      <div className='item item-top' />
      <div className='item item-center' />
      <a href={`${fullDescription.button[0].file}`} target='blank' className='item item-bottom item-button' data-title={`${fullDescription.button[0].title}`} style={{ maxHeight: columnSize, background: appState.colorTheme.base }}>
        <div>
          <h2>{fullDescription.button[0].title}</h2>
        </div>
        <div className='item-action-button'>
          <p>{fullDescription.button[0].text}</p>
          <span className="icon-search-03"></span>
        </div>
      </a>
    </div>
    <div className='column no-right' style={{ width: columnSize - 2 }}>
      <div className='item item-top' />
      <div className='item item-center' />
      <Link className='item item-bottom item-button' data-title='DETAILS' style={{ maxHeight: columnSize, background: appState.colorTheme.secondary }} to={getUrl('summer-about')} >
        <div>
          <h2>DETAILS</h2>
        </div>
        <div className='item-action-button'>
          <p>LEARN MORE</p>
          <span className="icon-search-03"></span>
        </div>
      </Link>
    </div>
  </div>

}
export default infoBox