import React from 'react'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'

const wednesdayBtn = props => {
  let banner = props.wednesday_btn.banner
    , itemSize = props.appState.itemSize
    , data = props.wednesday_btn

  if (props.appState.item === 2) {
    return <div className='activity'>
      <div className='row'>
        <div className='item item-grid image-cover-bg' style={{ borderBottom: '2px black solid', background: `url(${banner})` }} />
        <Link to={getUrl('summer')} className='item item-button item-container item-grid' data-title={data.title} style={{ borderTop: '2px black solid', background: props.appState.colorTheme.secondary }}>
          <div>
            <h2>{data.title}</h2>
            <h4>{data.subtitle}</h4>
          </div>
          <div className='item-action-button'>
            <p>{data.text}</p>
            <span className="icon-search-03"></span>
          </div>
        </Link>
      </div>
    </div>
  }


  if (props.h === 1) return <div className='dailyObject'>
    <Link to={getUrl('summer')} className='item-container item item-button item-left-single' data-title={data.title} style={{ minWidth: itemSize - 2, width: itemSize - 2, height: '100%', background: props.appState.colorTheme.secondary }}>
      <div>
        <h2>{data.title}</h2>
        <h4>{data.subtitle}</h4>
      </div>
      <div className='item-action-button'>
        <p>{data.text}</p>
        <span className="icon-search-03"></span>
      </div>
    </Link>
    <div className='item-right-single image-cover-bg' style={{ background: `url(${banner})`, width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
    </div>
  </div>

  return <div className='dailyObject image-cover-bg' style={{ background: `url(${banner})` }}>
    <div className='row'>
      <div className='item item-grid item-left-top' />
      <Link to={getUrl('summer')} className='item item-button item-container item-grid item-left-bottom' data-title={data.title} style={{ background: props.appState.colorTheme.secondary }}>
        <div>
          <h2>{data.title}</h2>
          <h4>{data.subtitle}</h4>
        </div>
        <div className='item-action-button'>
          <p>{data.text}</p>
          <span className="icon-search-03"></span>
        </div>
      </Link>
    </div>
    <div className='row'>
      <div className='item item-grid item-center-top' />
      <div className='item item-grid item-center-bottom' />
    </div>
    <div className='row'>
      <div className='item item-grid item-right-top-fixed' />
      <div className='item item-grid item-right-bottom-fixed' />
    </div>
  </div>
}

export default wednesdayBtn
