import { filtersBase as filtersApplied } from '../../utils/filters'

const initialState = {
  filters: {
    tag_program: [],
    tag_person: [],
    tag_object: [],
    tag_topic: [],
  },
  filtersApplied: { ...filtersApplied },
  data: [],
  page: 0,
  numItems: 10,
  numPages: 0,
  initialized: false,
}


export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTERS': return action.filters === null ? state : { ...state, filters: action.filters }
    case 'APPLY_FILTERS': return action.filters === null ? state : { ...state, filtersApplied: action.filtersApplied }
    case 'RESET_APPLIED_FILTERS': {
      let currentState = { ...state }
      currentState.filtersApplied = {
        tag_program: [],
        tag_person: [],
        tag_object: [],
        tag_topic: [],
        year: [],
        text: '',
        sortMode: 'title',
        sort: 'DESC',
      }
      return currentState
    }
    case 'SET_AMOUNT_PAGES': return { ...state, numPages: action.numPages }
    case 'SET_PAGE': return { ...state, page: action.page }
    case 'SET_INITIALIZED': return { ...state, initialized: action.initialized }
    default: return state
  }
}