import React from 'react'

const backgrund6X4 = props => {
  let { appState, onClick } = props
    , columnSize = appState.itemSize

  return <div className='background' style={{ background: `url("${props.banner}")` }}>
    <div className='container'>
      <div className='column no-left' style={{ width: columnSize - 2 }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
      <div className='column' style={{ width: (columnSize * 4) }}>

      </div>
      <div className='column no-right' style={{ width: columnSize - 2 }}>
        <div onClick={onClick} className='item item-top download-btn' style={{ background: appState.colorTheme.secondary, maxHeight: appState.itemSize, maxWidth: appState.itemSize }}>
          <div>
            <h2>See</h2>
            <h2>previous</h2>
            <h2>years</h2>
          </div>
          <span className='icon-search-05 arrow-down' />
        </div>
        <div className='item item-center' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
    </div>
  </div>
}

export default backgrund6X4