import axios from 'axios'

// http://somamexico.org/api
// stage: https://stage.somamexico.org/api
// prod: https://manager-cms.somamexico.org/api

const instance = axios.create({
  baseURL: 'https://manager-cms.somamexico.org/api'
})

export default instance