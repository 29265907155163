import React from 'react'
import { connect } from 'react-redux'
import Header from './components/Header'
import Grid from '../../baseComponents/Grid'
import Hero from './components/Hero'
import Text from './components/Text'
import getTemplate from './template'
import InfoDesc from './components/InfoDesc'
import InfoBox from './components/InfoBox'
import AnnouncementBlock from './components/AnnouncementBlock'
import Collaborators from './components/Collaborators'
import Participants from './components/Participants'
import ImageGrid from './components/ImageGrid'
import AskBox from './components/AskBox'
import Loading from '../../baseComponents/Loading'
import Newsletter from '../../baseComponents/Newsletter'
import ContactProgram from '../../baseComponents/ContactProgram'
import SearchLayout from '../../baseComponents/SearchLayout'
import HeaderWithBack from '../../baseComponents/HeaderWithBack'
import ItemInfo from '../PESScreen/components/ItemInfo'

import { getUrlByType } from '../../utils/routesDefinition'
import { Link } from 'react-router-dom'

import { summerProgramResources } from '../../services/pageResources'

import './SUMMERScreen.css'

class SUMMERScreen extends React.Component {

  state = {
    template: [],
    resources: null,
    isFetching: true,
    isFetchingEnd: false,
    error: null,
    showItemList: false,
    itemList: [],
    itemListTItle: '',
    participants: [],
    participantsTitle: '',
    years: [],
    year: new Date().getFullYear(),
    activeParticipants: false,
  }

  onActiveSearch = () => {
  }

  getComponent = (component, props) => {
    let { resources } = this.state
    if (component === null) return component
    switch (component) {
      case 'ItemInfo': return <ItemInfo {...props} />
      case 'Hero': return <Hero {...props} {...resources} />
      case 'Text': return <Text {...props} />
      case 'InfoBox': return <InfoBox {...props} {...resources} />
      case 'InfoDesc': return <InfoDesc {...props} {...resources} />
      case 'AnnouncementBlock': return <AnnouncementBlock {...props} {...resources} />
      case 'ImageGrid': return <ImageGrid {...props} />
      case 'AskBox': return <AskBox {...props} {...resources} />
      case 'Collaborators': return <Collaborators {...props} {...resources} onShowList={this.onShowList} />
      case 'Participants': return <Participants {...props} {...resources}
        onShowParticipants={this.onShowParticipants}
        onShowList={this.onShowList} />
      case 'Newsletter': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <Newsletter color={color} />
      }
      case 'ContactProgram': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <ContactProgram color={color} />
      }
      default: return null
    }
  }

  onShowParticipants = (items, title, years) => {
    this.setState({ participants: items, showParticipants: true, participantsTitle: title, years })
  }

  onGoBack = () => {
    this.setState({ showParticipants: false, showItemList: false, itemList: [], itemListTItle: '' })
  }

  onShowList = (items, title) => {
    let x = 0, y = 0, h = 1
      , itemsFormatted = []
      , { appState } = this.props

    for (let index = 0; index < items.length; index++) {
      let width = Math.floor(Math.random() * 2 + 1)
        , values = { x, y, h, w: width, component: 'ItemInfo' }
      if (index !== 0) {
        x += itemsFormatted[index - 1].w
        y += (x > appState.item - 1) ? 1 : 0
        width = (x + width > appState.item - 1) ? 1 : width
        x = (x > appState.item - 1) ? 0 : x
        values = { x, y, h, w: width, component: 'ItemInfo' }
      }
      itemsFormatted.push({ ...values, ...items[index] })
    }

    let complement = itemsFormatted[itemsFormatted.length - 1]
    complement = complement.x + complement.w
    if (appState.item > complement) itemsFormatted.push({ x: complement, y, h, w: appState.item - complement, component: 'Box' })

    this.setState({ itemList: itemsFormatted, showItemList: true, itemListTItle: title })
  }

  getData = () => {
    summerProgramResources()
      .then(res => {
        this.setState({ isFetchingEnd: true, resources: res.data }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false })
          }, 800)
        })
      })
      .catch(err => console.log('error', err))
    this.setState({ template: getTemplate()[this.props.appState.item] })
  }

  componentDidMount() {
    this.getData()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.appState.item !== nextProps.appState.item) {
      this.setState({ template: getTemplate()[nextProps.appState.item] })
    }
    if (nextProps.appState.lang !== this.props.appState.lang) {
      this.setState({
        template: [],
        resources: null,
        isFetching: true,
        isFetchingEnd: false,
        error: null,
        showItemList: false,
        itemList: [],
        itemListTItle: ''
      }, this.getData)
    }
  }


  render() {
    let { isFetching, isFetchingEnd, template, showItemList, itemList, itemListTItle, showParticipants, participants, participantsTitle, year, years, activeParticipants } = this.state
      , { appState } = this.props
    if (isFetching) return <Loading isOut={isFetchingEnd} />

    if (showParticipants) return <div id='pes' className='header-participants'>
      <HeaderWithBack title={participantsTitle} onGoBack={this.onGoBack} />
      <div id="main" style={{ marginTop: `${0}px` }} >
        <Grid fullmode>
          <div values={{ x: 0, y: 0, w: this.props.appState.item, h: 2, fullMode: true }} />
          <div className='content-block' values={{ x: 0, y: 0, w: this.props.appState.item, h: 2, fullMode: true }}>
            <div className='item-button-width-end item-button-border centered' style={{ height: this.props.appState.itemSize }}>
              <div className='year-selector'>
                <div className='year-selector-data' onClick={() => { this.setState(prevState => ({ activeParticipants: !prevState.activeParticipants })) }}>
                  <p>{year}</p>
                  <span class={`icon-search-05 ${activeParticipants ? 'active' : ''}`} />
                </div>
                {
                  activeParticipants
                    ? <div className='year-options' style={{ background: this.props.appState.colorTheme.base, height: this.props.appState.itemSize * 1.25 }}>
                      {years.map(y => <p onClick={e => { e.stopPropagation(); this.setState({ year: Number(y), activeParticipants: false, }) }} key={y}>{y}</p>)}
                    </div>
                    : null
                }
              </div>
            </div>
            {
              participants
                .filter(p => Number(p.year) === Number(year))
                .map(p => <div className='item-button-width-end item-button-border' style={{ height: this.props.appState.itemSize }}>
                  <Link to={`${getUrlByType(p.objectType)}?id=${p.id}`} data-title={p.title} className='item item-button item-info'>
                    <div>
                      <h2>{p.title}</h2>
                      <h4>{p.person}</h4>
                    </div>
                    <div className='item-action-button'>
                      <p>{p.date}</p>
                      <span className="icon-search-05"></span>
                    </div>
                  </Link>
                </div>)
            }
          </div>
        </Grid>
      </div>
    </div>


    if (showItemList) return <div id='pes'>
      <HeaderWithBack title={itemListTItle} onGoBack={this.onGoBack} />
      <div id="main" className='objects-gird-container' style={{ marginTop: `${0}px` }} >
        {
          itemList.map((item, index) => <div key={index} className={item.w === 2 ? 'double' : 'single'} style={{ height: appState.itemSize }}>
            {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
          </div>)
        }
      </div>
    </div>

    return <div id='summer'>
      <SearchLayout header={<Header />}>
        <div id="main" style={{ marginTop: `${0}px` }} >
          <Grid>
            {
              template.map((item, index) => <div
                key={index}
                values={{ x: item.x, y: item.y, w: item.w, h: item.h, }}
              >
                {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
              </div>)
            }
          </Grid>
        </div>
      </SearchLayout>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(SUMMERScreen)