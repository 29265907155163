import React, { useState, useEffect } from 'react'
import SVGShape from '../../../baseComponents/Shape'

const Shape = props => {
  let isVisible = props.isVisible

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  if (props.notAnimate) return <SVGShape {...props} />

  return <div className='home-shape' style={{ marginTop: animate ? 0 : props.appState.itemSize }}>
    <SVGShape {...props} />
  </div>
}

export default Shape