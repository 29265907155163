import React from 'react'
import ImageGrid from './ImageGrid'
import InfoDesc from './InfoDesc'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'

const collaborator = props => {

  let { appState, collaborators } = props
    , columnSize = appState.itemSize
    , items = collaborators.items
      .map(item => ({ ...item, rand: Math.random() }))
      .sort((a, b) => a.value - b.value)
    , year = (new Date()).getFullYear()

  if (appState.item === 6) items = items.filter((_, i) => i < 5)
  if (appState.item === 4) items = items.filter((_, i) => i < 3)
  if (appState.item === 2) items = items.filter((_, i) => i < 3)

  if (appState.item === 2) return <div>
    <div className='column no-top no-left no-right' style={{ height: (columnSize * 4) }}>
      <div className='row no-top' style={{ minHeight: (columnSize * 2) }}>
        <InfoDesc {...props} fullDescription={{ title: collaborators.title, paragraph: collaborators.description }} />
      </div>
      <div className='row' style={{ minHeight: (columnSize * 2) }}>
        <ImageGrid {...props} position='left' banner={collaborators.banner} />
      </div>
    </div>
    <div className='row no-top' style={{ flexWrap: 'wrap' }}>
      {
        items
          .map((item, index) => <Link to={`${getUrl('objectsPerson')}?id=${item.id}`} className={`item item-button column goal-border ${index % 2 === 0 ? 'no-left' : 'no-right'}`} key={index}
            style={{ maxWidth: '50%', width: columnSize, height: columnSize, }}
          >
            <div>
              <h2>{item.title}</h2>
              <h4>{item.person}</h4>
            </div>
            <div className='item-action-button'>
              <p className='font-button'>{item.date}</p>
              <span className="icon-search-05"></span>
            </div>
          </Link>)
      }
      <div className='item item-button goal-border no-left no-right' onClick={() => props.onShowList(collaborators.items, collaborators.title)} style={{ maxWidth: '50%', height: columnSize - 2, background: appState.colorTheme.secondary, width: columnSize, borderBottom: '2px solid black', borderLeft: '2px solid black' }} >
        <div>
          <h2>SHOW ALL</h2>
          <h4>{year}</h4>
        </div>
        <div className='item-action-button'>
          <p> </p>
          <span className="icon-search-03"></span>
        </div>
      </div>
    </div>
  </div>


  return <div>
    <div className='row no-top' style={{ height: (columnSize * 2) }}>
      <ImageGrid {...props} position='left' banner={collaborators.banner} />
      <div style={{ height: columnSize * 2, width: 2, background: 'black' }} />
      <InfoDesc {...props} fullDescription={{ title: collaborators.title, paragraph: collaborators.description }} />
    </div>
    <div className='row' style={{ height: (columnSize) }}>
      {
        items.map((item, index) => <Link to={`${getUrl('objectsPerson')}?id=${item.id}`} data-title={item.title} className={`item item-button column ${index === 0 ? 'no-left' : ''}`} key={index}
          style={{ width: columnSize, height: columnSize, }}
        >
          <div>
            <h2>{item.title}</h2>
            <h4>{item.person}</h4>
          </div>
          <div className='item-action-button'>
            <p className='font-button'>{item.date}</p>
            <span className="icon-search-05"></span>
          </div>
        </Link>)
      }
      <div onClick={() => props.onShowList(collaborators.items, collaborators.title)} data-title={'SHOW ALL'} className='item item-button' style={{ background: appState.colorTheme.secondary, width: columnSize, borderBottom: '2px solid black', borderLeft: '1px solid black' }} >
        <div>
          <h2>SHOW ALL</h2>
          <h4>{year}</h4>
        </div>
        <div className='item-action-button'>
          <p> </p>
          <span className="icon-search-03"></span>
        </div>
      </div>
    </div>
  </div>
}

export default collaborator

// style={{ width: columnSize, height: columnSize - 2, borderRight: '2px solid black' }} 