import React from 'react'

export default class AskBox extends React.Component {

  state = {
    active: null
  }

  setActive = active => {
    let index = active < 0 ? this.props.FAQS.items.length - 1 : active
    index = index > this.props.FAQS.items.length - 1 ? 0 : index
    this.setState(prevState => ({ active: prevState.active !== index ? index : null }))
  }

  render() {
    let { appState, FAQS } = this.props
      , { active } = this.state
      , columnSize = appState.itemSize

    if (appState.item === 2) return <div className='container image-cover-bg column no-left no-right' style={{ background: appState.colorTheme.base }}>
      <div className='item item-top centered' style={{ height: columnSize - 1 }}>
        <h2 className='header-title' style={{ textAlign: 'center', lineHeight: '6vh' }}>FAQs</h2>
      </div>
      <div className='FAQ-container item item-bottom' style={{ height: (columnSize * 2) }}>
        <div className='FAQ-container-box'>
          {
            FAQS.items.map((faq, index) => {
              let styleClass = index === active
                ? 'active'
                : active !== null ? 'inactive' : 'static'
              return <div className={`ask-container ${styleClass}`} key={index}>
                <div className='ask-box' onClick={() => this.setActive(index)}>
                  <p>{faq.question}</p>
                  <div className='action-container'>
                    {
                      active === index
                        ? <div className='question-arrow-container'>
                          <span onClick={(e) => { e.stopPropagation(); this.setActive(index - 1) }} className={`icon-search-05 arrow-left`} />
                          <span onClick={(e) => { e.stopPropagation(); this.setActive(index + 1) }} className={`icon-search-05`} />
                        </div>
                        : null
                    }
                    <span style={{ color: appState.colorTheme.secondary }} className={`${index === active ? 'icon-search-04' : 'icon-search-03'}`}></span>
                  </div>
                </div>
                {
                  active === index
                    ? <div>
                      <div className='answer' dangerouslySetInnerHTML={{ __html: faq.answer }} />
                    </div>
                    : null
                }
              </div>
            })
          }
        </div>
      </div>
    </div>


    if (appState.item === 4) return <div className='container image-cover-bg' style={{ background: `url("${FAQS.banner}")` }}>
      <div className='column' style={{ width: (columnSize * 3), background: appState.colorTheme.base }}>
        <div className='item item-top centered' style={{ height: columnSize - 1 }}>
          <h2 className='header-title'>FAQs</h2>
        </div>
        <div className='FAQ-container item item-bottom' style={{ height: (columnSize * 2) }}>
          <div className='FAQ-container-box'>
            {
              FAQS.items.map((faq, index) => {
                let styleClass = index === active
                  ? 'active'
                  : active !== null ? 'inactive' : 'static'
                return <div className={`ask-container ${styleClass}`} key={index}>
                  <div className='ask-box' onClick={() => this.setActive(index)}>
                    <p className='question-title'>{faq.question}</p>
                    <div className='action-container'>
                      {
                        active === index
                          ? <div className='question-arrow-container'>
                            <span onClick={(e) => { e.stopPropagation(); this.setActive(index - 1) }} className={`icon-search-05 arrow-left`} />
                            <span onClick={(e) => { e.stopPropagation(); this.setActive(index + 1) }} className={`icon-search-05`} />
                          </div>
                          : null
                      }
                      <span style={{ color: appState.colorTheme.secondary }} className={`${index === active ? 'icon-search-04' : 'icon-search-03'}`}></span>
                    </div>
                  </div>
                  {
                    active === index
                      ? <div>
                        <div className='answer' dangerouslySetInnerHTML={{ __html: faq.answer }} />
                      </div>
                      : null
                  }
                </div>
              })
            }
          </div>
        </div>
      </div>
      <div className='column no-right' style={{ height: (columnSize * 3), width: (columnSize) - 2 }}>
        <div className='item item-top centered announcement-title' style={{ height: columnSize - 1 }} />
        <div className='item item-center' style={{ height: columnSize }} />
        <div className='item item-bottom' style={{ height: columnSize }} />
      </div>
    </div>


    return <div className='container column-display image-cover-bg' style={{ background: `url("${FAQS.banner}")` }}>
      <div className='row no-top' style={{ height: columnSize }}>
        <div className='item-center-row no-left centered' style={{ background: appState.colorTheme.base, width: (columnSize * 4) + 8 }}>
          <h2 className='header-title'>FAQs</h2>
        </div>
        <div className='item-center-row' style={{ width: (columnSize) + 2 }} />
        <div className='item-center-row no-right' style={{ width: (columnSize) }} />
      </div>
      <div className='row no-bottom' style={{ height: (columnSize * 2) - 2 }}>
        <div className='column no-left' style={{ width: columnSize + 2 }}>
          <div className='item item-top' />
          <div className='item item-bottom' />
        </div>
        <div className='FAQ-container column' style={{ background: appState.colorTheme.base, width: (columnSize * 4) + 4 }}>
          <div className='FAQ-container-box'>
            {
              FAQS.items.map((faq, index) => {
                let styleClass = index === active
                  ? 'active'
                  : active !== null ? 'inactive' : 'static'
                return <div className={`ask-container ${styleClass}`} key={index}>
                  <div className='ask-box' onClick={() => this.setActive(index)}>
                    <p className='question-title'>{faq.question}</p>
                    <div className='action-container'>
                      {
                        active === index
                          ? <div className='question-arrow-container'>
                            <span onClick={(e) => { e.stopPropagation(); this.setActive(index - 1) }} className={`icon-search-05 arrow-left`} />
                            <span onClick={(e) => { e.stopPropagation(); this.setActive(index + 1) }} className={`icon-search-05`} />
                          </div>
                          : null
                      }
                      <span style={{ color: appState.colorTheme.secondary }} className={`${index === active ? 'icon-search-04' : 'icon-search-03'}`}></span>
                    </div>
                  </div>
                  {
                    active === index
                      ? <div>
                        <div className='answer' dangerouslySetInnerHTML={{ __html: faq.answer }} />
                      </div>
                      : null
                  }
                </div>
              })
            }
          </div>
        </div>
        <div className='column no-right' style={{ width: columnSize }}>
          <div className='item item-top' />
          <div className='item item-bottom' />
        </div>
      </div>
    </div>
  }
}