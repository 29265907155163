
import { createStore, combineReducers } from 'redux'

import appStateReducer from './reducers/appState'
import userAuthReducer from './reducers/userAuth'
import objectsReducer from './reducers/objects'
import calendarReducer from './reducers/calendar'

const reducers = combineReducers({
  appState: appStateReducer,
  calendar: calendarReducer,
  userAuth: userAuthReducer,
  objects: objectsReducer,
})

// devToolsExtension es un setup de configuración para poder examinar
// el flujo de estados desde devtools de Redux
const store = (window.devToolsExtension
  ? window.devToolsExtension()(createStore)
  : createStore)(reducers);

export default store