import React from 'react'
import Slider from 'react-slick'

import '../../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../../node_modules/slick-carousel/slick/slick-theme.css'

const ImageContainer = props => {
  let { h, w, image, size } = props

  if (w === 2) return <div className='image-background' style={{ background: `url("${image}")`, height: size, minWidth: (size * 2) - 2 }}>
    <div className='column' />
    <div className='column' />
  </div>

  if (w === 2) return <div className='image-background' style={{ background: `url("${image}")`, height: size * h, minWidth: (size * 3) - 2 }}>
    <div className='column'>
      <div className='item-box-2' />
      <div className='item-box-2' />
      <div className='item-box-2' />
    </div>
    <div className='column'>
      <div className='item-box-2' />
      <div className='item-box-2' />
      <div className='item-box-2' />
    </div>
  </div>

  return <div className='image-background' style={{ background: `url("${image}")`, height: size * h, minWidth: (size * 3) - 2 }}>
    <div className='column'>
      <div className='item-box-2' />
      <div className='item-box-2' />
      <div className='item-box-2' />
    </div>
    <div className='column'>
      <div className='item-box-2' />
      <div className='item-box-2' />
      <div className='item-box-2' />
    </div>
    <div className='column'>
      <div className='item-box-2' />
      <div className='item-box-2' />
      <div className='item-box-2' />
    </div>
  </div>

}

export default class Carousel extends React.Component {

  next = () => { this.slider.slickNext() }
  previous = () => { this.slider.slickPrev() }


  render() {
    let { appState, slides, h, w } = this.props
    slides = slides.length ? slides : []

    const settings = {
      autoplay: true,
      arrows: false,
      className: "slider variable-width",
      infinite: true,
      speed: 200,
      draggable: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      variableWidth: true
    }

    return <div className='slider-container'>
      <Slider ref={c => (this.slider = c)} {...settings}>
        {
          slides.map((slide, index) => <ImageContainer
            key={index} h={h} w={w} size={appState.itemSize} image={slide}
          />)
        }
      </Slider>
      {
        appState.item !== 2
          ? <div className='slider-button-container' style={{ background: appState.colorTheme.base, right: 0, top: appState.itemSize, width: appState.itemSize, height: appState.itemSize - 2 }}>
            <div>
              <span className='icon-search-05 left' onClick={this.previous} />
              <span className='icon-search-05' onClick={this.next} />
            </div>
          </div>
          : <div className='slider-button-container-mobile' style={{ top: appState.itemSize, height: appState.itemSize / 2 }}>
            <div>
              <span className='icon-search-05 left' onClick={this.previous} />
              <span className='icon-search-05' onClick={this.next} />
            </div>
          </div>
      }
    </div>
  }
}
