import React, { useState, useEffect } from 'react'

const Quote = props => {
  let data = props.quote
    , isVisible = props.isVisible
    , animationEnable = false

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  const smallTextClass = (text) => {
    return text.length > 85 ? 'quote-text-small-fix' : 'quote-text-small'
  }

  animationEnable = animate

  if (props.notAnimate) animationEnable = true

  return <div className='block-box' style={{ marginTop: animationEnable ? -props.appState.itemSize * props.h : 0 }}>
    <div className='' style={{ height: props.appState.itemSize * props.h }}>
      <div className='block-box-container'>
        {
          props.h === 2
            ? <React.Fragment>
              <div className='block-box-item no-top no-left' />
              <div className='block-box-item no-top no-right' />
              <div className='block-box-item no-bottom no-left' />
              <div className='block-box-item no-bottom no-right' />
            </React.Fragment>
            : <React.Fragment>
              <div className='block-box-item-announcement no-top no-left' />
              <div className='block-box-item-announcement no-top no-right' />
            </React.Fragment>
        }
      </div>
    </div>

    <div className='quote' style={{ height: props.appState.itemSize * props.h }}>
      <p className={`${props.h === 1 ? smallTextClass(data.body) : 'quote-text'}`}>"{data.body}"</p>
      <div>
        <h4>{data.author}</h4>
        <p className='quote-place'>{data.footer}</p>
      </div>
    </div>

  </div>
}

export default Quote