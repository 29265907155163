import React from 'react'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'
import getTexts from '../../../services/langTexts'


const sixButtons = (texts) => {

  return <React.Fragment>
    <Link to={getUrl('residence')} className='item-button item-container no-left' data-title={texts.artist_residence} style={{ width: '100%', height: '100%', }}>
      <div>
        <h2>{texts.artist_residence}</h2>
        <h4>{' '}</h4>
      </div>
      <div className='item-action-button'>
        <p>{texts.see_more}</p>
        <span className="icon-search-05"></span>
      </div>
    </Link>

    <Link to={getUrl('calendar')} className='item-button item-container' data-title={texts.news} style={{ width: '100%', height: '100%', }}>
      <div>
        <h2>{texts.news}</h2>
        <h4>{' '}</h4>
      </div>
      <div className='item-action-button'>
        <p>{texts.see_more}</p>
        <span className="icon-search-05"></span>
      </div>
    </Link>


    <Link to={getUrl('objects')} className='item-button item-container' data-title={texts.explore_archive} style={{ width: '100%', height: '100%', }}>
      <div>
        <h2>{texts.explore_archive}</h2>
        <h4>{' '}</h4>
      </div>
      <div className='item-action-button'>
        <p>{texts.see_more}</p>
        <span className="icon-search-05"></span>
      </div>
    </Link>

    <Link to={getUrl('calendar')} className='item-button item-container' data-title={texts.explore_events} style={{ width: '100%', height: '100%', }}>
      <div>
        <h2>{texts.explore_events}</h2>
        <h4>{' '}</h4>
      </div>
      <div className='item-action-button'>
        <p>{texts.see_more}</p>
        <span className="icon-search-05"></span>
      </div>
    </Link>

    <Link to={getUrl('wednesday')} className='item-button item-container no-right' data-title={texts.wednesday} style={{ width: '100%', height: '100%', }}>
      <div>
        <h2>{texts.wednesday}</h2>
        <h4>{' '}</h4>
      </div>
      <div className='item-action-button'>
        <p>{texts.see_more}</p>
        <span className="icon-search-05"></span>
      </div>
    </Link>
  </React.Fragment>
}

const fourButtons = (texts) => {
  return <React.Fragment>
    <Link to={getUrl('objects')} className='item-button item-container no-left' data-title={texts.explore_archive} style={{ width: '100%', height: '100%', }}>
      <div>
        <h2>{texts.explore_archive}</h2>
        <h4>{' '}</h4>
      </div>
      <div className='item-action-button'>
        <p>{texts.see_more}</p>
        <span className="icon-search-05"></span>
      </div>
    </Link>

    <Link to={getUrl('calendar')} className='item-button item-container' data-title={texts.explore_events} style={{ width: '100%', height: '100%', }}>
      <div>
        <h2>{texts.explore_events}</h2>
        <h4>{' '}</h4>
      </div>
      <div className='item-action-button'>
        <p>{texts.see_more}</p>
        <span className="icon-search-05"></span>
      </div>
    </Link>

    <Link to={getUrl('wednesday')} className='item-button item-container no-right' data-title={texts.wednesday} style={{ width: '100%', height: '100%', }}>
      <div>
        <h2>{texts.wednesday}</h2>
        <h4>{' '}</h4>
      </div>
      <div className='item-action-button'>
        <p>{texts.see_more}</p>
        <span className="icon-search-05"></span>
      </div>
    </Link>
  </React.Fragment>
}

const empty = props => {

  let { lang } = props.appState
    , texts = getTexts('my-account', lang)

  if (props.appState.item === 2) return <div>
    {
      props.appState.item === 2
        ? props.menu
        : null
    }
    <div className='info-block' style={{ height: props.appState.itemSize * 2 }}>
      <div>
        <span className='icon-04' />
        <h3>{texts.emtyDataTitle}</h3>
        <p>{texts.emptyDataDesc}</p>
      </div>
    </div>
    <div className='buttons-container' style={{ border: '2px solid black', height: props.appState.itemSize }}>
      <Link to={getUrl('objects')} className='item-button item-container no-left' data-title={texts.explore_archive} style={{ width: '100%', height: '100%', }}>
        <div>
          <h2>{texts.explore_archive}</h2>
          <h4>{' '}</h4>
        </div>
        <div className='item-action-button'>
          <p>{texts.see_more}</p>
          <span className="icon-search-05"></span>
        </div>
      </Link>

      <Link to={getUrl('calendar')} className='item-button item-container' data-title={texts.explore_events} style={{ width: '100%', height: '100%', }}>
        <div>
          <h2>{texts.explore_events}</h2>
          <h4>{' '}</h4>
        </div>
        <div className='item-action-button'>
          <p>{texts.see_more}</p>
          <span className="icon-search-05"></span>
        </div>
      </Link>
    </div>
  </div>

  return <div>
    {
      props.appState.item === 2
        ? props.menu
        : null
    }
    <div className='info-block' style={{ height: props.appState.itemSize * 2 }}>
      <div>
        <span className='icon-04' />
        <h3>{texts.emtyDataTitle}</h3>
        <p>{texts.emptyDataDesc}</p>
      </div>
    </div>
    <div className='buttons-container' style={{ height: props.appState.itemSize }}>
      {
        props.appState.item === 6
          ? sixButtons(texts)
          : fourButtons(texts)
      }
    </div>
  </div>

}

export default empty

/*

<Link to={getUrl('residence')} className='item-button item-container no-left' data-title='{texts.artist_residence}' style={{ width: '100%', height: '100%', }}>
        <div>
          <h2>{texts.artist_residence}</h2>
          <h4>{' '}</h4>
        </div>
        <div className='item-action-button'>
          <p>{texts.see_more}</p>
          <span className="icon-search-05"></span>
        </div>
      </Link>

      <Link to={getUrl('calendar')} className='item-button item-container' data-title='{texts.news}' style={{ width: '100%', height: '100%', }}>
        <div>
          <h2>{texts.news}</h2>
          <h4>{' '}</h4>
        </div>
        <div className='item-action-button'>
          <p>{texts.see_more}</p>
          <span className="icon-search-05"></span>
        </div>
      </Link>


      <Link to={getUrl('objects')} className='item-button item-container' data-title='{texts.explore_archive}' style={{ width: '100%', height: '100%', }}>
        <div>
          <h2>{texts.explore_archive}</h2>
          <h4>{' '}</h4>
        </div>
        <div className='item-action-button'>
          <p>{texts.see_more}</p>
          <span className="icon-search-05"></span>
        </div>
      </Link>

      <Link to={getUrl('calendar')} className='item-button item-container' data-title='{texts.explore_events}' style={{ width: '100%', height: '100%', }}>
        <div>
          <h2>{texts.explore_events}</h2>
          <h4>{' '}</h4>
        </div>
        <div className='item-action-button'>
          <p>{texts.see_more}</p>
          <span className="icon-search-05"></span>
        </div>
      </Link>

      <Link to={getUrl('wednesday')} className='item-button item-container no-right' data-title='{texts.wednesday}' style={{ width: '100%', height: '100%', }}>
        <div>
          <h2>{texts.wednesday}</h2>
          <h4>{' '}</h4>
        </div>
        <div className='item-action-button'>
          <p>{texts.see_more}</p>
          <span className="icon-search-05"></span>
        </div>
      </Link>

*/