const resultList = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Image', value: 0 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 0 },
    { x: 0, y: 2, w: 1, h: 1, component: 'Image', value: 1 },
    { x: 0, y: 3, w: 1, h: 1, component: 'Shape', value: 1 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Image', value: 2 },
    { x: 0, y: 5, w: 1, h: 1, component: 'Shape', value: 2 },

    { x: 1, y: 0, w: 4, h: 6, component: 'SearchResult' },

    { x: 5, y: 0, w: 1, h: 1, component: 'Shape', value: 3 },
    { x: 5, y: 1, w: 1, h: 1, component: 'Image', value: 3 },
    { x: 5, y: 2, w: 1, h: 1, component: 'Shape', value: 4 },
    { x: 5, y: 3, w: 1, h: 1, component: 'Image', value: 4 },
    { x: 5, y: 4, w: 1, h: 1, component: 'Shape', value: 5 },
    { x: 5, y: 5, w: 1, h: 1, component: 'Image', value: 5 },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Image', value: 6 },
    { x: 0, y: 1, w: 1, h: 1, component: 'Shape', value: 0 },
    { x: 0, y: 2, w: 1, h: 1, component: 'Image', value: 7 },
    { x: 0, y: 3, w: 1, h: 1, component: 'Shape', value: 0 },
    { x: 0, y: 4, w: 1, h: 1, component: 'Image', value: 8 },
    { x: 0, y: 5, w: 1, h: 1, component: 'Shape', value: 0 },

    { x: 1, y: 0, w: 2, h: 6, component: 'SearchResult' },

    { x: 3, y: 0, w: 1, h: 1, component: 'Shape', value: 0 },
    { x: 3, y: 1, w: 1, h: 1, component: 'Image', value: 9 },
    { x: 3, y: 2, w: 1, h: 1, component: 'Shape', value: 0 },
    { x: 3, y: 3, w: 1, h: 1, component: 'Image', value: 10 },
    { x: 3, y: 4, w: 1, h: 1, component: 'Shape', value: 0 },
    { x: 3, y: 5, w: 1, h: 1, component: 'Image', value: 11 },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 6, component: 'SearchResult' },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Image' },
  ]
}


const getTemplate = template => {
  switch (template) {
    case 'result': return resultList
    default: return resultList
  }
}

export default getTemplate