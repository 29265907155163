import React from 'react'
import SearchLayout from '../../baseComponents/SearchLayout'
import Header from './components/Header'
import Grid from '../../baseComponents/Grid'
import Loading from '../../baseComponents/Loading'
import Container from './components/Container'
import InfoBox from './components/InfoBox'
import SideButton from './components/SideButton'
import Newsletter from '../../baseComponents/Newsletter'
import ContactProgram from '../../baseComponents/ContactProgram'
import { filtersBase } from '../../utils/filters'
import getTemplate from './template'
import { connect } from 'react-redux'
import { getUrl } from '../../utils/routesDefinition'

import { wednesdayProgramResources } from '../../services/pageResources'
import { applyFilters, setInitialized } from '../../redux/actions/objects'

import './WednesdayScreen.css'

class WednesdayScreen extends React.Component {

  state = {
    template: [],
    resources: null,
    isFetching: true,
    isFetchingEnd: false,
    error: null,
    showItemList: false,
    itemList: [],
    itemListTItle: ''
  }

  onApplyFilter = filter => {
    let filters = { ...filtersBase, ...this.state.resources.filters }
    this.props.dispatch(applyFilters(filters))
    this.props.dispatch(setInitialized(true))
    this.props.history.push(getUrl('objects'))
  }

  getComponent = (component, props) => {
    let { resources } = this.state
    if (component === null) return component
    switch (component) {
      case 'Container': return <Container {...props} nextWednesdays={resources.nextWednesdays} />
      case 'InfoBox': return <InfoBox {...props} resources={resources} onApplyFilter={this.onApplyFilter} />
      case 'SideButton': return <SideButton {...props} banner={resources.banner} onApplyFilter={this.onApplyFilter} />
      case 'Newsletter': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <Newsletter color={color} />
      }
      case 'ContactProgram': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <ContactProgram color={color} />
      }
      default: return null
    }
  }

  getData = () => {
    wednesdayProgramResources()
      .then(res => {
        this.setState({ isFetchingEnd: true, resources: res.data }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false })
          }, 800)
        })
      })
      .catch(err => console.log('error', err))
    this.setState({ template: getTemplate()[this.props.appState.item] })
  }

  componentDidMount() {
    this.getData()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.appState.item !== nextProps.appState.item) {
      this.setState({ template: getTemplate()[nextProps.appState.item] })
    }
    if (nextProps.appState.lang !== this.props.appState.lang) {
      this.setState({
        template: [],
        resources: null,
        isFetching: true,
        isFetchingEnd: false,
        error: null,
        showItemList: false,
        itemList: [],
        itemListTItle: ''
      }, this.getData)
    }
  }

  render() {
    let { isFetching, isFetchingEnd, template, } = this.state
    if (isFetching) return <Loading isOut={isFetchingEnd} />
    return <div id='residence'>
      <SearchLayout header={<Header />}>
        <div id="main" style={{ marginTop: `${0}px` }} >
          <Grid with>
            {
              template.map((item, index) => <div
                key={index}
                values={{ x: item.x, y: item.y, w: item.w, h: item.h, withExtra: item.withExtra }}
              >
                {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
              </div>)
            }
          </Grid>
          <Grid with>
            {
              getTemplate('footer')[this.props.appState.item].map((item, index) => <div
                key={index}
                values={{ x: item.x, y: item.y, w: item.w, h: item.h }}
              >
                {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
              </div>)
            }
          </Grid>
        </div>
      </SearchLayout>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(WednesdayScreen)