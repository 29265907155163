const initialState = {
  page: 0,
  showAll: false,
  text: '',
  numPage: 0,
  currentPage: 0,
  activeFilterKey: 'noticias'
}


export default (state = initialState, action) => {
  switch (action.type) {
    case 'APPLY_FILTERS_CALENDAR': return { ...state, ...action.actions }
    default: return state
  }
}