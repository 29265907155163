import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Announcement = props => {
  let banner = props.announcement.banner[props.appState.item]
    , imgWidth = props.w === 3 ? (props.appState.itemSize * 2) : props.appState.itemSize
    , data = props.announcement
    , isVisible = props.isVisible
    , animationEnable = false

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  animationEnable = animate

  let classTextFix = ''
  if (typeof data.title === 'string') {
    let words = data.title.split(' ')
      .find(word => word.length >= 12)
    if (words) classTextFix = 'text-fix-title'
  }

  if (props.notAnimate) animationEnable = true

  if (props.template === 'template-4') return <Link to={props.announcement.link} className='announcement' style={{ marginTop: animationEnable ? 0 : props.appState.itemSize }}>
    <img src={banner} style={{ width: '100%', height: '100%', borderRight: '0' }} alt='banner' />
  </Link>

  return <div className='block-box' style={{ marginTop: animationEnable ? -props.appState.itemSize : 0 }}>
    <div className='announcement' style={{ height: props.appState.itemSize, }}>
      <div className='block-box-container'>
        {
          props.w === 3
            ? <React.Fragment>
              <div className='block-box-item-announcement no-top no-left' />
              <div className='block-box-item-announcement no-top' />
              <div className='block-box-item-announcement no-top no-right' />
            </React.Fragment>
            : <React.Fragment>
              <div className='block-box-item-announcement no-top no-left' />
              <div className='block-box-item-announcement no-top no-right' />
            </React.Fragment>
        }
      </div>
    </div>
    <Link to={props.announcement.link} className='announcement' style={{ height: props.appState.itemSize, }}>
      <img src={banner} style={{ minWidth: imgWidth, height: '100%' }} alt='banner' />
      <div className='item-button item-container' data-title={data.title} style={{ width: '100%', height: '100%', background: props.appState.colorTheme.secondary }}>
        <div>
          <h2 className={classTextFix}>{data.title}</h2>
          <h4>{data.subtitle}</h4>
        </div>
        <div className='item-action-button'>
          <p>{data.text}</p>
          <span className="icon-search-03"></span>
        </div>
      </div>
    </Link>
  </div>
}

export default Announcement