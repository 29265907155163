import React from 'react'

export default class SearchResult extends React.Component {
  render() {
    return <div className='result-container'>
      {
        this.props.resultItems.map(item => <div key={item.id} className='result-item'>
          <h2>{item.maintag.toUpperCase()}</h2>
          <h3>{item.title}</h3>
        </div>)
      }
    </div>
  }
}