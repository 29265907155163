const template = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Color' },
    { x: 1, y: 0, w: 1, h: 1, component: 'Text' },
    { x: 2, y: 0, w: 2, h: 1, component: 'Error' },
    { x: 4, y: 0, w: 1, h: 1, component: 'Block' },
    { x: 5, y: 0, w: 1, h: 1, component: 'Block' },

    { x: 0, y: 1, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 1, w: 1, h: 1, component: 'Block' },
    { x: 2, y: 1, w: 1, h: 1, component: 'Block' },
    { x: 3, y: 1, w: 1, h: 1, component: 'Block' },
    { x: 4, y: 1, w: 1, h: 1, component: 'Button' },
    { x: 5, y: 1, w: 1, h: 1, component: 'Block' },

    { x: 0, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 2, w: 1, h: 1, component: 'Color' },
    { x: 2, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 3, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 4, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 5, y: 2, w: 1, h: 1, component: 'Block' },

  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Text' },
    { x: 1, y: 0, w: 2, h: 1, component: 'Error' },
    { x: 3, y: 0, w: 1, h: 1, component: 'Block' },

    { x: 0, y: 1, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 1, w: 1, h: 1, component: 'Block' },
    { x: 2, y: 1, w: 1, h: 1, component: 'Button' },
    { x: 3, y: 1, w: 1, h: 1, component: 'Block' },

    { x: 0, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 2, w: 1, h: 1, component: 'Color' },
    { x: 2, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 3, y: 2, w: 1, h: 1, component: 'Block' },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 2, component: 'Error' },

    { x: 0, y: 2, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 2, w: 1, h: 1, component: 'Button' },

    { x: 0, y: 3, w: 1, h: 1, component: 'Block' },
    { x: 1, y: 3, w: 1, h: 1, component: 'Color' },

  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
  ]
}



const getTemplate = () => {
  return template
}

export default getTemplate
