import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './app/redux/store'
import { BrowserRouter } from 'react-router-dom'

//import { BrowserRouter, HashRouter } from 'react-router-dom'

import './index.css'
import App from './app/App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
</Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.unregister()
