const template = {
  6: [
    { x: 0, y: 0, w: 3, h: 2, component: 'Container', withExtra: true },
    { x: 3, y: 0, w: 2, h: 2, component: 'InfoBox', withExtra: true },
    { x: 5, y: 0, w: 1, h: 2, component: 'SideButton', withExtra: true },
  ],
  4: [
    { x: 0, y: 0, w: 2, h: 2, component: 'Container', withExtra: true },
    { x: 2, y: 0, w: 2, h: 2, component: 'InfoBox', withExtra: true },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 2.5, component: 'Container' },
    { x: 0, y: 2.5, w: 2, h: 2.5, component: 'InfoBox' },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
  ]
}

const footer = {
  6: [
    { x: 0, y: 0, w: 4, h: 1, component: 'ContactProgram' },
    { x: 4, y: 0, w: 2, h: 1, component: 'Newsletter' },
  ],
  4: [
    { x: 0, y: 0, w: 2, h: 1, component: 'ContactProgram' },
    { x: 2, y: 0, w: 2, h: 1, component: 'Newsletter' },
  ],
  2: [
    { x: 0, y: 0, w: 1, h: 1, component: 'ContactProgram' },
    { x: 1, y: 0, w: 1, h: 1, component: 'Newsletter' },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
  ]
}



const getTemplate = (templateString = 'main') => {
  switch (templateString) {
    case 'main': return template
    case 'footer': return footer
    default: return template
  }
}

export default getTemplate