import React from 'react'
import { applyFilters, setInitialized } from '../../../redux/actions/objects'
import { filtersBase } from '../../../utils/filters'
import { getUrl } from '../../../utils/routesDefinition'

export default class Content extends React.Component {

  state = {
    activeTestimonial: { ...this.props.testimonials.items[0], id: 0, },
    activeWeek: { ...this.props.program.weeks[0], id: 0, },
    activeDropdown: false,
  }

  getBanner = (banner = 0, top, bottom) => {
    let { appState, banners } = this.props
    if (appState.item === 4) return <div className={`background row row-border image-cover-bg  ${top ? 'no-top' : ''}`} style={{ height: appState.itemSize, background: `url("${banners[banner]}")` }} >
      <div className='item-row no-left' />
      <div className='item-row' />
      <div className='item-row' />
      <div className='item-row no-right' />
    </div>

    if (appState.item === 2) return <div className={`background row row-border image-cover-bg  ${top ? 'no-top' : ''}`} style={{ height: appState.itemSize, background: `url("${banners[banner]}")` }} >
      <div className='item-row no-left' />
      <div className='item-row no-right' />
    </div>

    return null
  }

  setTestimonial = value => {
    let { activeTestimonial } = this.state
      , { testimonials } = this.props
      , index = activeTestimonial.id + value < 0 ? testimonials.items.length - 1 : activeTestimonial.id + value
    index = testimonials.items.length - 1 < index ? 0 : index

    activeTestimonial = testimonials.items.find((_, i) => i === index)
    this.setState({ activeTestimonial: { ...activeTestimonial, id: index, } })
  }

  setWeek = value => {
    let { program } = this.props
    program = program.weeks.find((_, index) => index === value)
    this.setState({ activeWeek: { id: value, ...program }, activeDropdown: false })
  }


  setFilters = () => {
    let years = this.props.sections.about.filters.year ? this.props.sections.about.filters.year : this.props.sections.about.filters.years
    years = years.map(year => Number(year))
    let filters = { ...filtersBase, ...this.props.sections.about.filters, year: years }
    this.props.dispatch(applyFilters(filters))
    this.props.dispatch(setInitialized(true))
    this.props.history.push(getUrl('objects'))
  }

  componentDidMount() {
    if (this.props.location.hash === '#info') document.getElementById('requirements-summer').scrollIntoView()
    if (this.props.location.hash === '#program') document.getElementById('weeks-summer').scrollIntoView()
  }

  render() {
    let { appState, sections, lastItem } = this.props
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , size = appState.itemSize
      , left = size + 4
      , width = (size * 4) - 1
      , imgHeight = appState.item === 2 ? size : size / 2

    padding = Math.round((padding - (size * appState.item)) / 2)

    left += padding

    if (appState.item !== 6) left = 4
    if (appState.item === 2) {
      width = size * 2
      imgHeight = imgHeight / 2
    }

    return <div className='content-container' style={{ height: (lastItem.y) * size, left, width, background: appState.colorTheme.base }}>
      <div id='about-summer' className='content-padding'>
        <p>LA PANADERIA CENTRO CULTURAL DE ARTE A.C.., con domicilio ubicado en: Calle 13, no. 25 Col. San Pedro de los Pinos, Alcaldía Benito Juárez, CP 03800, Ciudad de México, en cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de Particulares (“la Ley”), y su reglamento, hacen de su conocimiento que: “SOMA”, protege y salvaguarda sus datos personales para evitar el daño, pérdida, destrucción, robo, extravío, alteración, así como el tratamiento no autorizado.</p>
      </div>
      <div id='about-summer' className='content-padding'>
        <h2>¿Qué información recopilamos?</h2>
        <p>Los datos personales que usted proporcione a “SOMA” a través de cualquier medio físico o electrónico podrían incluir: (i) datos personales, (ii) datos laborales e (iii) información financiera o contable.</p>
        <p>El tratamiento de datos personales de terceros que usted nos proporcione, se hace de conformidad con los términos y condiciones contenidos en el Aviso de Privacidad.</p>
        <p>Usted manifiesta su consentimiento para el tratamiento de sus datos personales de acuerdo a los términos y condiciones de este Aviso de Privacidad.</p>
      </div>
      <div id='about-summer' className='content-padding'>
        <h2>¿Para qué utilizamos su información?</h2>
        <p>Los datos personales que recopilamos son destinados para: (i) fines de identificación y de verificación, (ii) información de nuestros clientes o aliados, (iii) definir perfiles de usuario y (iv) envío de promociones propias.
En la recolección y tratamiento de datos personales, cumplimos con todos los principios estipulados en la Ley y su Reglamento: licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.</p>
      </div>
      <div id='about-summer' className='content-padding'>
        <h2>¿Con quién compartimos su información?</h2>
        <p>“SOMA” puede transferir sus datos personales a terceros nacionales o extranjeros pertenecientes a nuestros grupo económico, a nuestras empresas subsidiarias y relacionadas; por requerimiento de una autoridad; y para el cumplimiento de obligaciones derivadas de una relación jurídica entre el titular de los datos personales y “SOMA”.</p>
        <p>Los datos personales que usted proporciona a “SOMA”, pueden compilarse y fijarse en una base de datos propiedad exclusiva de “SOMA” para ser utilizada en los términos antes referidos.</p>
      </div>
      <div id='about-summer' className='content-padding'>
        <h2>¿Qué medidas de seguridad y control utilizamos para la protección de sus datos personales?</h2>
        <p>“SOMA”, implementa medidas de seguridad administrativas, técnicas y físicas para proteger sus datos personales, mismas que exigimos sean cumplidas por nuestros proveedores de servicios o clientes, inclusive tratándose de servicios que prestan las empresas subsidiarias o relacionadas.
Toda la información y datos personales que usted nos proporcione mediante una vista, en la utilización de nuestros servicios en línea, por escrito y/o vía telefónica, constituirá una base de datos de “SOMA”. La información se almacena con fines de protección, para evitar pérdida, uso indebido o alteración.</p>
        <p>“SOMA” puede utilizar en su sitio web “cookies” o “direcciones IP”, para confirmar su identificación al tener acceso a nuestros servicios en línea, con el sólo propósito de otorgarle un servicio personalizado debido a que nos permite determinar sus preferencias mediante el registro de su uso en nuestro portal, y de esta manera identificar sus hábitos y preferencias para ofrecerle nuestros productos y servicios. Mediante el uso de “cookies”, no recabamos datos personales de los usuarios en términos de la Ley y su Reglamento.
La seguridad y la confidencialidad de los datos que los usuarios proporcionen al comprar una franquicia con nosotros o adquirir algunos de nuestros productos o servicios estarán protegidas por un servidor seguro, de tal forma que los datos recabados si se llegasen a transmitir, se transmitirán encriptados para asegurar su resguardo.</p>
      </div>
      <div id='about-summer' className='content-padding'>
        <h2>¿Cuál es el área responsable del manejo y administración de los datos personales?</h2>
        <p>El área responsable del manejo y la administración de los datos personales puede contactarse mediante el correo electrónico: info@somamexico.org, o directamente en nuestras oficinas ubicadas en: Calle 13, no. 25, Col. San Pedro de los Pinos, Alcaldía Benito Juárez, CP 03800, Ciudad de México.
        ¿Cómo pude usted limitar el uso o divulgación de sus datos personales?
Puede dirigirse a “SOMA” para el caso de que desee limitar el uso o divulgación de sus datos personales para dejar de recibir información de ofertas, promociones y publicidad de productos y servicios adicionales enviando su solicitud a través de la cuenta de correo electrónico info@somamexico.org como al teléfono 55 52774947</p>
        <p>En el caso de empleo de cookies, el botón “ayuda” que se encuentra en la barra de herramientas de la mayoría de los navegadores, le dirá cómo bloquear o permitir las cookies.</p>
      </div>
      <div id='about-summer' className='content-padding'>
        <h2>¿Cómo puede usted ejercer sus derechos ARCO y revocar el consentimiento otorgado?</h2>
        <p>Como titular de datos personales, usted podrá́ ejercer los Derechos ARCO (acceso, cancelación, rectificación y oposición al tratamiento de datos personales), o bien, revocar el consentimiento que usted haya otorgado a “SOMA” para el tratamiento de sus datos personales, enviando su solicitud, directamente a la cuenta de correo electrónico info@somamexico.org.; o acudiendo directamente a nuestras oficinas; o bien, solicitándolo por escrito, enviando al domicilio Calle 13, no. 25, Col. San Pedro de los Pinos, Alcaldía Benito Juárez, Ciudad de México. Dicha solicitud deberá́ contener por lo menos su nombre, domicilio completo, documentos que acrediten su identidad, señalando claramente el vínculo de relación con la Compañía y especificando en forma clara y precisa los datos personales de los que solicita su acceso, rectificación, actualización o cancelación, los elementos o documentos en donde pueden obrar los datos personales e indicando las razones por las cuales desea acceder a sus datos personales, o las razones por las que considera que sus datos deben ser actualizados, rectificados o cancelados. o bien, solicitándolo por escrito, enviando al domicilio Calle 13, no. 25, Col. San Pedro de los Pinos, Alcaldía Benito Juárez, Ciudad de México. Dicha solicitud deberá́ contener por lo menos su nombre, domicilio completo, documentos que acrediten su identidad, señalando claramente el vínculo de relación con la Compañía y especificando en forma clara y precisa los datos personales de los que solicita su acceso, rectificación, actualización o cancelación, los elementos o documentos en donde pueden obrar los datos personales e indicando las razones por las cuales desea acceder a sus datos personales, o las razones por las que considera que sus datos deben ser actualizados, rectificados o cancelados.</p>
        <p>A partir de la recepción, el área de Privacidad de Datos, tendrá un plazo de 20 (veinte) días hábiles para responder a su solicitud, sin embargo, dicho plazo podrá ser ampliado por un periodo de tiempo igual, lo que le será notificado, por el medio que usted nos haya indicado para tales efectos, señalando las razones de dicha ampliación, de conformidad con el artículo 32 de la Ley.
        Modificaciones al Aviso de Privacidad
Nos reservamos el derecho de cambiar este Aviso de Privacidad en cualquier momento. En caso de que exista algún cambio en este Aviso de Privacidad, usted tendrá acceso al mismo a través de nuestro Portal http://rataattack.somamexico.org</p>
      </div>
      <div id='about-summer' className='content-padding'>
        <h2>Consentimiento de tratamiento de datos personales</h2>
        <p>El titular de datos personales manifiesta haber leído y estar de acuerdo con los términos y condiciones del Aviso de Privacidad puesto a su disposición, conociendo la finalidad de la recolección y tratamiento de sus datos personales, así como el procedimiento para el ejercicio de sus Derechos ARCO.
        Al proporcionar información vía internet, telefónica o personalmente, usted otorga su consentimiento al presente Aviso de Privacidad.
Ante quién puede presentar sus quejas y denuncias por el tratamiento indebido de sus datos personales</p>
        <p>Si usted considera que su derecho de protección de datos personales ha sido transgredido por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI), para mayor información, le sugerimos visitar su página oficial de internet www.inai.org.mx. Fecha de última actualización de este Aviso de Privacidad: 10/10/2018</p>
      </div>
    </div>
  }
}