const initialState = {
  user_token: null,
  objects: {},
  person: {},
  hasFavToUpdate: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_AUTH': return { ...state, ...action.auth }
    case 'RESET_USER_AUTH': return initialState
    default: return state
  }
}