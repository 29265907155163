import React from 'react'
import { connect } from 'react-redux'
import Header from './components/Header'
import Grid from '../../baseComponents/Grid'
import Content from './components/Content'
import Loading from '../../baseComponents/Loading'
import Banner from './components/Banner'
import getTemplate from './template'
import { getBanner, validateToken } from '../../services/auth'

import './RegisterScreen.css'

class RegisterScreen extends React.Component {

  state = {
    isFetching: true,
    banner: null,
    isTokenValid: false,
  }

  getComponent = (component, props) => {
    if (component === null) return component
    switch (component) {
      case 'Content': return <Content {...props} />
      case 'Banner': return <Banner {...props} img={this.state.banner} />
      default: return null
    }
  }

  async componentDidMount() {
    try {
      const banner = await getBanner()
        , token = await validateToken(this.props.match.params.id)
      if (token.status !== 200) this.props.history.push('/')
      this.setState({ banner, isFetching: false })
    } catch (error) {
      this.setState({ isFetching: false })
    }
  }

  render() {
    let { appState } = this.props

    if (this.state.isFetching) return <Loading />

    return <div id='recovery'>
      <Header title='' />
      <Grid>
        {
          getTemplate()[appState.item].map((item, index) => {
            return <div
              className={item.className ? item.className : ''}
              style={{ background: this.props.appState.colorTheme.base }}
              key={index} values={{ x: item.x, y: item.y, w: item.w, h: item.h, isResizable: false, static: true, fullMode: item.fullMode }}>
              {this.getComponent(item.component, { ...this.props, ...item })}
            </div>
          })
        }
      </Grid>
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(RegisterScreen)