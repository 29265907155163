import React, { useState, useEffect } from 'react'
import Image, { getImage } from '../../../baseComponents/Image'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'
import { connect } from 'react-redux'
import Menu from '../../../baseComponents/MenuButton'
import Logo from '../../../baseComponents/Logo'
import Shape from '../../../baseComponents/Shape'
import getTexts from '../../../services/langTexts'

import Grid from '../../../baseComponents/HeaderGrid'


const items = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo', className: 'animated-home', time: 1100 },
    { x: 1, y: 0, w: 1, h: 1, component: 'Search', className: 'animated-home', time: 1200 },
    { x: 2, y: 0, w: 1, h: 1, component: 'Wildcard', value: getImage(), item: 1, className: 'animated-home', time: 1250 },
    { x: 3, y: 0, w: 1, h: 1, component: 'Wildcard', value: getImage(), item: 2, className: 'animated-home', time: 1300 },
    { x: 4, y: 0, w: 1, h: 1, component: 'Register', className: 'animated-home', time: 1400 },
    { x: 5, y: 0, w: 1, h: 1, component: 'Menu', className: 'animated-home', time: 1000 },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo', className: 'animated-home', time: 1000 },
    { x: 1, y: 0, w: 1, h: 1, component: 'Search', className: 'animated-home', time: 1050 },
    { x: 2, y: 0, w: 1, h: 1, component: 'Register', className: 'animated-home', time: 1400 },
    { x: 3, y: 0, w: 1, h: 1, component: 'Menu', className: 'animated-home', time: 1000 }
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: null },
  ]
}


const Search = props => {

  let isVisible = props.isVisible
    , animationEnable = false

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  animationEnable = animate

  if (props.notAnimate) animationEnable = true

  return <div onClick={props.onActiveSearch} className='centered search-btn header-item' style={{ marginTop: animationEnable ? 0 : props.appState.itemSize }}>
    <span className="icon-search-02"></span>
  </div>
}

const Register = props => {
  let { lang } = props.appState
    , registerText = getTexts('register-btn', lang)
    , accountText = getTexts('my-account-btn', lang)
    , isVisible = props.isVisible
    , animationEnable = false

  const [animate, setAnimate] = useState(false)


  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  animationEnable = animate

  if (props.notAnimate) animationEnable = true

  return !props.userAuth.id
    ? <Link to={getUrl('register')} className='centered register-button hover-button' data-title={registerText.title} style={{ background: props.appState.colorTheme.secondary, marginTop: animationEnable ? 0 : props.appState.itemSize }} onClick={props.onClick}>
      <h2>{registerText.title}</h2>
      <p>{registerText.subtitle}</p>
    </Link>
    : <Link to={getUrl('account')} className='centered register-button hover-button' data-title={accountText.title} style={{ background: props.appState.colorTheme.secondary, marginTop: animationEnable ? 0 : props.appState.itemSize }} onClick={props.onClick}>
      <h2>{accountText.title}</h2>
      <p>{accountText.subtitle}</p>
    </Link>
}




/*


<div className='centered register-button hover-button'
  style={{ background: props.appState.colorTheme.secondary }}
  onClick={() => { document.getElementById('newsletter').scrollIntoView() }}
  data-title="Suscríbete">
  <h2 style={{ alignSelf: 'center', textAlign: 'center' }}>Suscríbete</h2>
</div>


*/

const AnimatedBox = props => {
  let isVisible = props.isVisible
    , animationEnable = false

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  animationEnable = animate

  if (props.notAnimate) animationEnable = true

  return <div className='animated-box' style={{ marginTop: animationEnable ? 0 : props.appState.itemSize }}>
    {props.children}
  </div>
}


const getComponent = (component, props) => {
  if (component === null) return component

  switch (component) {
    case 'Logo': return <AnimatedBox {...props}><Logo /></AnimatedBox>
    case 'Search': return <AnimatedBox {...props}><Search {...props} /></AnimatedBox>
    case 'Wildcard': {
      if (props.template !== 'template-4' && props.item !== 1)
        return <AnimatedBox {...props}><Image {...props} cover={props.item === 1 ? props.banner[0] : props.banner[1]} /></AnimatedBox>
      if (props.template === 'template-3' && props.item === 1)
        return <AnimatedBox {...props}><Image {...props} cover={props.item === 1 ? props.banner[0] : props.banner[1]} /></AnimatedBox>
      let values = { value: 7 }
      if (props.item === 1) {
        values.value = 3
        values.axisX = 270
      }
      return <AnimatedBox {...props}><Shape {...props} {...values} /></AnimatedBox>
    }
    case 'Register': return <Register {...props} />
    case 'Menu': return <AnimatedBox {...props}><Menu /></AnimatedBox>
    default: return null
  }
}


const header = props => <Grid isScrollActive={props.isScrollActive}>
  {
    items[props.appState.headerItem].map((item, index) => <div
      className={`header-item ${item.className}`}
      style={{ background: props.appState.colorTheme.base }}
      key={index} values={{ x: item.x, y: item.y, w: item.w, h: item.h }}>
      {getComponent(item.component, { ...props, ...item })}
    </div>)
  }
</Grid>

const mapStateToProps = state => ({
  appState: state.appState,
  userAuth: state.userAuth,
})

export default connect(mapStateToProps)(header)

/*

<ResponsiveGridLayout
  className='layout'
  breakpoints={{ lg: 1200, md: 1024, sm: 800, xs: 768, xxs: 0 }}
  cols={{ lg: 6, md: 6, sm: 4, xs: 4, xxs: 4 }}
  margin={[4, 0]}
  rowHeight={props.appState.headerItemSize}
>
  {
    items[props.appState.headerItem].map((item, index) => <div
      className='header-item'
      style={{ background: props.appState.colorTheme.base }}
      key={index} data-grid={{ x: item.x, y: item.y, w: item.w, h: item.h, isResizable: false, static: true }}>
      {getComponent(item.component, props)}
    </div>)
  }
</ResponsiveGridLayout>

*/