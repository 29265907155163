import React from 'react'

const logo = props => {
  let { appState, fullDescription } = props

  if (appState.item === 6) return <div className='container' >
    <div className='column image-cover-bg' style={{ background: `url("${fullDescription.banner}")`, minWidth: appState.itemSize * 2 }}>
    </div>
    <div className='column full-description centered' style={{ minWidth: appState.itemSize * 4 }}>
      <div className='logo-text' dangerouslySetInnerHTML={{ __html: fullDescription.description }} />
    </div>
  </div>


  if (appState.item === 4) return <div className='container'>
    <div className='column full-description centered' style={{ minWidth: appState.itemSize * 3 }}>
      <div className='logo-text' dangerouslySetInnerHTML={{ __html: fullDescription.description }} />
    </div>
    <div className='column' >
      <div className='item-box image-cover-bg' style={{ background: `url("${fullDescription.banner}")` }} />
      <div className='item-box border-bottom' />
      <div className='item-box border-bottom' />
    </div>
  </div>


  return <div className='container column'>
    <div className='row image-cover-bg' style={{ background: `url("${fullDescription.banner}")` }}>
      <div className='item-box-mobile' />
      <div className='item-box-mobile' />
    </div>
    <div className='full-description centered' style={{ minHeight: appState.itemSize * 2 }}>
      <div className='logo-text' dangerouslySetInnerHTML={{ __html: fullDescription.description }} />
    </div>
  </div>
}

export default logo

/*

<div className='column'>
      <div className='item-box image-background' style={{ background: `url("${fullDescription.banner}")` }} />
      <div className='item-box' />
    </div>
    <div className='column'>
      <div className='item-box' />
      <div className='item-box' />
    </div>





*/