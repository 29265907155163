import React from 'react'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'

export default class Container extends React.Component {

  state = {
    activeElement: { ...this.props.nextWednesdays[0], index: 0, }
  }

  onSetElement = value => {
    let { activeElement } = this.state
      , { nextWednesdays } = this.props
      , index = activeElement.index + value < 0 ? nextWednesdays.length - 1 : activeElement.index + value
    index = nextWednesdays.length - 1 < index ? 0 : index

    activeElement = nextWednesdays.find((_, i) => i === index)
    this.setState({ activeElement: { ...activeElement, index, } })
  }

  render() {
    let { appState } = this.props
      , { activeElement } = this.state
      , columnSize = appState.itemSize

    if (this.props.w === 2) return <div className='container-block'>
      <Link to={`${getUrl('objectsObject')}?id=${activeElement.id}`} className='background' style={{ background: `url("${activeElement.banner}")`, height: (columnSize * 2) }}>
        <div className='container'>
          <div className='column no-left' style={{ width: columnSize - 2 }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
          <div className='column no-right' style={{ width: columnSize - 2 }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
        </div>
      </Link>
      <div className='container-info'>
        <div className='container-data'>
          <div style={{ minWidth: '30%' }}>
            {
              this.props.nextWednesdays.length > 1
                ? <React.Fragment>
                  <span onClick={() => this.onSetElement(-1)} className="icon-search-05 icon-left"></span>
                  <span onClick={() => this.onSetElement(1)} className="icon-search-05"></span>
                </React.Fragment>
                : null
            }
          </div>
          <div style={{ flex: 1, }}>
            <h3>{activeElement.title.length > 25 ? `${activeElement.title.substring(0, 22)}...` : activeElement.title}</h3>
            <p>{activeElement.startDate}</p>
          </div>
        </div>
      </div>
    </div>



    if (this.props.w === 3) return <div className='container-block'>
      <Link to={`${getUrl('objectsObject')}?id=${activeElement.id}`} className='background' style={{ background: `url("${activeElement.banner}")`, height: (columnSize * 2) }}>
        <div className='container'>
          <div className='column no-left' style={{ width: columnSize - 2 }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
          <div className='column' style={{ width: (columnSize) }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
          <div className='column no-right' style={{ width: columnSize - 2 }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
        </div>
      </Link>
      <div className='container-info'>
        <div className='container-data'>
          {
            this.props.nextWednesdays.length > 1
              ? <div>
                <span onClick={() => this.onSetElement(-1)} className="icon-search-05 icon-left"></span>
                <span onClick={() => this.onSetElement(1)} className="icon-search-05"></span>
              </div>
              : <div style={{ width: '10%' }} />
          }
          <div>
            <h3>{activeElement.title.length > 25 ? `${activeElement.title.substring(0, 22)}...` : activeElement.title}</h3>
            <p>{activeElement.startDate}</p>
          </div>
        </div>
      </div>
    </div>

    return null
  }
}