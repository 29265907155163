const routes = {
  'privacy': {
    url: '/aviso-de-privacidad'
  },
  'home': {
    url: '/',
  },
  'register': {
    url: '/registro'
  },
  'about': {
    url: '/nosotros'
  },
  'calendar': {
    url: '/noticias'
  },
  'objects': {
    url: '/archivo',
  },
  'objectsPerson': {
    url: '/archivo/persona',
    type: 'person'
  },
  'objectsObject': {
    url: '/archivo/objeto',
    type: 'object'
  },
  'pes': {
    url: '/programa-educativo-soma'
  },
  'pes-about': {
    url: '/acerca-programa-educativo-soma'
  },
  'wednesday': {
    url: '/miercoles-de-soma'
  },
  'summer': {
    url: '/soma-summer'
  },
  'summer-about': {
    url: '/acerca-soma-summer'
  },
  'residence': {
    url: '/residencias'
  },
  'account': {
    url: '/mi-cuenta'
  },
  'pwd_recovery': {
    url: '/nueva-contrasena'
  }
}

export const getUrl = routeName => {
  let route = routes[routeName]
  return route ? route.url : '/'
}

export const getType = url => {
  let routeKeys = Object.keys(routes)
  routeKeys = routeKeys
    .map(routeKey => url.search(routes[routeKey].url) !== -1 ? routes[routeKey].type : undefined)
    .filter(routeKey => routeKey)
  return routeKeys[0]
}

export const getUrlByType = type => {
  let url = Object.keys(routes).find(route => routes[route].type === type)
  url = routes[url].url
  return url
}