const template = {
  6: [
    { x: 0, y: 0, w: 6, h: 4, component: 'Background6X4' },
    { x: 0, y: 4, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 4, w: 1, h: 1, component: 'Button' },
    { x: 0, y: 5, w: 1, h: 1, component: 'BackgroundOne' },
    { x: 0, y: 6, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 5, w: 3, h: 4, component: 'Background3X4' },
    { x: 0, y: 7, w: 3, h: 4, component: 'Background3X4' },
    { x: 5, y: 9, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 10, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 11, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 12, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 11, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 12, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 13, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 13, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 14, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 14, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 15, w: 4, h: 1, component: 'ContactProgram' },
    { x: 4, y: 15, w: 2, h: 1, component: 'Newsletter' },
  ],
  4: [
    { x: 0, y: 18, w: 2, h: 1, component: 'ContactProgram' },
    { x: 2, y: 18, w: 2, h: 1, component: 'Newsletter' },
  ],
  2: [
    { x: 0, y: 17, w: 1, h: 1, component: 'ContactProgram' },
    { x: 1, y: 17, w: 1, h: 1, component: 'Newsletter' },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
  ]
}



const getTemplate = () => {
  return template
}

export default getTemplate


/*

    { x: 0, y: 0, w: 6, h: 3, component: 'Hero' },
    { x: 0, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 3, w: 3, h: 1, component: 'Text' },
    { x: 4, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 4, w: 3, h: 3, component: 'InfoBox' },
    { x: 3, y: 4, w: 3, h: 3, component: 'InfoDesc' },
    { x: 0, y: 7, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 7, w: 5, h: 3, component: 'AnnouncementBlock' },
    { x: 0, y: 8, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 9, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 10, w: 3, h: 2, component: 'ImageGrid' },
    { x: 3, y: 10, w: 3, h: 2, component: 'InfoDesc' },
    { x: 0, y: 12, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 12, w: 1, h: 1, component: 'Box' },
    { x: 2, y: 12, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 12, w: 1, h: 1, component: 'Box' },
    { x: 4, y: 12, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 12, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 13, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 13, w: 1, h: 1, component: 'Box' },
    { x: 2, y: 13, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 13, w: 1, h: 1, component: 'Box' },
    { x: 4, y: 13, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 13, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 14, w: 3, h: 2, component: 'InfoDesc' },
    { x: 3, y: 14, w: 3, h: 2, component: 'ImageGrid' },
    { x: 0, y: 16, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 16, w: 1, h: 1, component: 'Box' },
    { x: 2, y: 16, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 16, w: 1, h: 1, component: 'Box' },
    { x: 4, y: 16, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 16, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 17, w: 6, h: 3, component: 'AskBox' },



    { x: 0, y: 0, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 0, w: 1, h: 1, component: 'Box' },
    { x: 2, y: 0, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 0, w: 1, h: 1, component: 'Box' },
    { x: 4, y: 0, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 0, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 1, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 1, w: 1, h: 1, component: 'Box' },
    { x: 2, y: 1, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 1, w: 1, h: 1, component: 'Box' },
    { x: 4, y: 1, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 1, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 2, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 2, w: 1, h: 1, component: 'Box' },
    { x: 2, y: 2, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 2, w: 1, h: 1, component: 'Box' },
    { x: 4, y: 2, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 2, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 2, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 4, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 3, w: 1, h: 1, component: 'Box' },
    { x: 0, y: 4, w: 1, h: 1, component: 'Box' },
    { x: 1, y: 4, w: 1, h: 1, component: 'Box' },
    { x: 2, y: 4, w: 1, h: 1, component: 'Box' },
    { x: 3, y: 4, w: 1, h: 1, component: 'Box' },
    { x: 4, y: 4, w: 1, h: 1, component: 'Box' },
    { x: 5, y: 4, w: 1, h: 1, component: 'Box' },
*/