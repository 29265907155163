import React from 'react'
import backgroundLogo from '../../../assets/img/patron_noimage.png'
import { getUrlByType } from '../../../utils/routesDefinition'
import { Link } from 'react-router-dom'

const itemInfo = props => {
  let objectType = props.objectType || 'object'
  if (props.w === 2) return <Link to={`${getUrlByType(objectType)}?id=${props.id}`} className='row no-top no-bottom' style={{ height: '100%' }}>
    <div className='background image-cover-bg' style={{ background: `url(${backgroundLogo})`, width: props.appState.itemSize, }} >
      <div className='background image-cover-bg' style={{ background: `url(${props.banner})`, width: props.appState.itemSize, }} />
    </div>
    <div className='item item-button item-info column no-right' data-title={props.title} style={{ width: props.appState.itemSize }}>
      <div>
        <h2>{props.title}</h2>
        <h4>{props.person}</h4>
      </div>
      <div className='item-action-button'>
        <p>{props.date}</p>
        <span className="icon-search-05"></span>
      </div>
    </div>
  </Link>



  return <Link to={`${getUrlByType(objectType)}?id=${props.id}`} data-title={props.title} className='item item-button item-info'>
    <div>
      <h2>{props.title}</h2>
      <h4>{props.person}</h4>
    </div>
    <div className='item-action-button'>
      <p>{props.date}</p>
      <span className="icon-search-05"></span>
    </div>
  </Link>
}

export default itemInfo