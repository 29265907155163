import React from 'react'

const description = props => {
  let { appState, shortDescription, fullDescription } = props
  if (appState.item === 6) return <div className='container'>
    <div className='column'>
      <div className='item-box' />
      <div className='item-box' />
    </div>
    <div className='column text-description' style={{ minWidth: appState.itemSize * 4 }}>
      <div>
        <p>{shortDescription}</p>
      </div>
    </div>
    <div className='column'>
      <div className='item-box' />
      <div className='item-box image-background' style={{ background: `url("${fullDescription.banner}")` }} />
    </div>
  </div>

  return <div className='column text-description'>
    <div>
      <p>{shortDescription}</p>
    </div>
  </div>
}

export default description