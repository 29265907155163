import React from 'react'

const infoDesc = props => {
  let { fullDescription } = props
  return <div className='info-text' >
    <div>
      <h3>{fullDescription.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: fullDescription.paragraph }} />
    </div>
  </div>
}

export default infoDesc