import React from 'react'
import { connect } from 'react-redux'
import { setMenuStatus } from '../redux/actions/appState'

const menu = props => <div className='centered btn-menu' onClick={() => { props.dispatch(setMenuStatus(!props.appState.isMenuOpen)) }}>
  <span className="icon-search-01"></span>
</div>

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(menu)