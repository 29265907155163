import React from 'react'

const announcementBlock = props => {
  let { appState, announcement } = props
    , columnSize = appState.itemSize
  if (appState.item === 2) return <div className='announcement column'>
    <div className='row centered goal-border no-left no-right no-top' style={{ minHeight: columnSize, width: '100%', height: columnSize, background: appState.colorTheme.base }}>
      <h2 className='header-title' style={{ textAlign: 'center' }}>{announcement.title}</h2>
    </div>
    <div className='item item-center info-text'
      style={{ width: '100%', minHeight: (columnSize * 4), flexDirection: 'column' }}>
      <div dangerouslySetInnerHTML={{ __html: announcement.paragraph }} />
      <div style={{ width: '100%', marginTop: '2vh' }}>
        <h3>{announcement.button[3].title}</h3>
        <p>{announcement.start_date}</p>
        <h3>{announcement.button[1].title}</h3>
        <p>{announcement.end_date}</p>
        <h3>{announcement.button[2].title}</h3>
        <p>{announcement.result_date}</p>
      </div>
    </div>
    <div className='info-container'>
      <div className='column no-left item image-cover-bg' style={{ width: columnSize, background: `url("${announcement.banner}")` }} />
      <a href={announcement.button[0].file} target='blank' className='column no-right item item-button' data-title={announcement.button[0].title} style={{ width: columnSize, maxHeight: columnSize, background: appState.colorTheme.secondary }} >
        <div>
          <h2>{announcement.button[0].title}</h2>
          <h4>{announcement.button[0].subtitle}</h4>
        </div>
        <div className='item-action-button'>
          <p>{announcement.button[0].text}</p>
          <span className="icon-search-03"></span>
        </div>
      </a>
    </div>
  </div>

  if (appState.item === 4) return <div className='announcement'>
    <div className='container container-announcement' style={{ width: (columnSize) }}>
      <div className='item item-top centered announcement-title' style={{ height: columnSize - 1 }} />
      <div className='grid-announcement-image image-cover-bg' style={{ background: `url("${announcement.banner}")`, height: columnSize * 2 }}>
        <div className='column no-left' style={{ width: columnSize - 2 }}>
          <div className='item item-top' />
          <div className='item item-bottom' />
        </div>
      </div>
      <div className='grid-announcement-block' style={{ height: columnSize - 2 }}>
        <a href={announcement.button[0].file} target='blank' className='column item item-bottom item-button no-top no-left' data-title={announcement.button[0].title} style={{ width: columnSize, background: appState.colorTheme.secondary }}>
          <div>
            <h2>{announcement.button[0].title}</h2>
            <h4>{announcement.button[0].subtitle}</h4>
          </div>
          <div className='item-action-button'>
            <p>{announcement.button[0].text}</p>
            <span className="icon-search-03"></span>
          </div>
        </a>
      </div>
    </div>

    <div className='column' style={{ maxWidth: (columnSize * 4) }}>
      <div className='item item-top centered announcement-title' style={{ width: columnSize * 3, height: columnSize - 1, position: 'absolute', left: -6, background: appState.colorTheme.base }}>
        <h2 className='header-title' style={{ textAlign: 'center' }}>{announcement.title}</h2>
      </div>
      <div className='item item-center' style={{ height: columnSize }} />
      <div className='item item-bottom' style={{ height: columnSize }} />
    </div>

    <div className='column no-right' style={{ maxWidth: columnSize }}>
      <div className='item item-top centered announcement-title' style={{ height: columnSize - 1 }} />
      <div className='item item-top centered announcement-date' style={{ height: columnSize - 1, maxWidth: columnSize }}>
        <h3>{announcement.button[1].title}</h3>
        <p>{announcement.end_date}</p>
      </div>
      <div className='item item-center centered announcement-date' style={{ height: columnSize, width: columnSize }} >
        <h3>{announcement.button[2].title}</h3>
        <p>{announcement.result_date}</p>
      </div>
      <div className='item item-bottom centered announcement-date' style={{ height: columnSize, width: columnSize }} >
        <h3>{announcement.button[3].title}</h3>
        <p>{announcement.start_date}</p>
      </div>
    </div>

    <div className='announcement-info-container no-left'
      dangerouslySetInnerHTML={{ __html: announcement.paragraph }}
      style={{ left: columnSize + 2, width: (columnSize * 2) - 9, height: (columnSize * 3) - 2, background: appState.colorTheme.base }}>
    </div>
  </div>


  return <div className='announcement'>
    <div className='container container-announcement' style={{ width: (columnSize * 2) }}>
      <div className='grid-announcement-image image-cover-bg' style={{ background: `url("${announcement.banner}")`, height: columnSize * 2 }}>
        <div className='column no-left' style={{ width: columnSize, }}>
          <div className='item item-top' />
          <div className='item item-bottom' />
        </div>
        <div className='column' style={{ width: columnSize }}>
          <div className='item item-top' />
          <div className='item item-bottom' />
        </div>
      </div>
      <div className='grid-announcement-block' style={{ height: columnSize }}>
        <div className='column no-left' style={{ width: columnSize }}></div>
        <a href={announcement.button[0].file} target='blank' className='column  item item-bottom item-button no-top' data-title={announcement.button[0].title} style={{ width: columnSize, background: appState.colorTheme.secondary }}>
          <div>
            <h2>{announcement.button[0].title}</h2>
            <h4>{announcement.button[0].subtitle}</h4>
          </div>
          <div className='item-action-button'>
            <p>{announcement.button[0].text}</p>
            <span className="icon-search-03"></span>
          </div>
        </a>
      </div>
    </div>

    <div className='column' style={{ width: (columnSize * 3) }}>
      <div className='item item-top centered announcement-title' style={{ height: columnSize - 1 }}>
        <h2 className='header-title'>{announcement.title}</h2>
      </div>
      <div className='item item-center' style={{ height: columnSize }} />
      <div className='item item-bottom' style={{ height: columnSize }} />
    </div>
    <div className='column no-right' style={{ width: columnSize }}>
      <div className='item item-top centered announcement-date' style={{ height: columnSize - 1 }}>
        <h3>{announcement.button[1].title}</h3>
        <p>{announcement.end_date}</p>
      </div>
      <div className='item item-center centered announcement-date' style={{ height: columnSize }} >
        <h3>{announcement.button[2].title}</h3>
        <p>{announcement.result_date}</p>
      </div>
      <div className='item item-bottom centered announcement-date' style={{ height: columnSize }} >
        <h3>{announcement.button[3].title}</h3>
        <p>{announcement.start_date}</p>
      </div>
    </div>

    <div className='announcement-info-container no-left'
      dangerouslySetInnerHTML={{ __html: announcement.paragraph }}
      style={{ left: (columnSize * 2), width: (columnSize * 3) - 3, height: (columnSize * 2) - 2, background: appState.colorTheme.base }}>
    </div>
  </div>
}

export default announcementBlock
