import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Event = props => {
  let banner = props.event.banner[props.appState.item]
    , itemSize = props.appState.itemSize
    , data = props.event
    , isVisible = props.isVisible
    , animationEnable = false

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  animationEnable = animate

  if (props.notAnimate) animationEnable = true

  if (props.appState.item === 2) {
    return <div className='activity' style={{ marginTop: animationEnable ? 0 : props.appState.itemSize * 2 }}>
      <div className='row'>
        <div className='item item-grid image-cover-bg' style={{ borderBottom: '2px black solid', background: `url(${banner})` }} />
        <Link to={props.event.link} className='item item-button item-container item-grid' data-title={data.title} style={{ borderTop: '2px black solid', background: props.appState.colorTheme.secondary }}>
          <div>
            <h2>{data.title}</h2>
            <h4>{data.subtitle}</h4>
          </div>
          <div className='item-action-button'>
            <p>{data.text}</p>
            <span className="icon-search-03"></span>
          </div>
        </Link>
      </div>
    </div>
  }


  if (props.h === 1) return <div className='block-box' style={{ marginTop: animationEnable ? -props.appState.itemSize : 0 }}>
    <div className='announcement' style={{ height: props.appState.itemSize, }}>
      <div className='block-box-container'>
        <div className='block-box-item-announcement no-top no-left' />
        <div className='block-box-item-announcement no-top' />
        <div className='block-box-item-announcement no-top no-right' />
      </div>
    </div>
    <div className='dailyObject' style={{ height: props.appState.itemSize, width: '100%', }}>
      <Link to={props.event.link} className='item-container item item-button item-left-single' data-title={data.title} style={{ minWidth: itemSize - 2, width: itemSize - 2, height: '100%', background: props.appState.colorTheme.secondary }}>
        <div>
          <h2>{data.title}</h2>
          <h4>{data.subtitle}</h4>
        </div>
        <div className='item-action-button'>
          <p>{data.text}</p>
          <span className="icon-search-03"></span>
        </div>
      </Link>
      <div className='item-right-single image-cover-bg' style={{ background: `url(${banner})`, width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
      </div>
    </div>
  </div>

  return <React.Fragment>
    <div className='dailyObject image-cover-bg'>
      <div className='row'>
        <div className='item item-grid item-left-top' />
        <Link to={props.event.link} className='item item-button item-container item-grid item-left-bottom' data-title={data.title} style={{ background: props.appState.colorTheme.secondary, marginTop: animationEnable ? 0 : props.appState.itemSize, }}>
          <div>
            <h2>{data.title}</h2>
            <h4>{data.subtitle}</h4>
          </div>
          <div className='item-action-button'>
            <p>{data.text}</p>
            <span className="icon-search-03"></span>
          </div>
        </Link>
      </div>
      <div className='row'>
        <div className='item item-grid item-center-top' />
        <div className='item item-grid item-center-bottom' />
      </div>
      <div className='row'>
        <div className='item item-grid item-right-top-fixed' />
        <div className='item item-grid item-right-bottom-fixed' />
      </div>
    </div>
    <div className='block-box image-cover-bg' style={{ marginTop: animationEnable ? -props.appState.itemSize * 2 : 0, background: `url(${banner})`, height: props.appState.itemSize * 2 }} />
  </React.Fragment>
}

export default Event

/*
<div>
      <div className='item' style={{ width: itemSize, height: itemSize }} />

      <div className='item item-button item-container' style={{ width: itemSize, height: itemSize, background: props.appState.colorTheme.secondary }}>
        <div>
          <h2>{data.title}</h2>
          <h4>{data.subtitle}</h4>
        </div>
        <div className='item-action-button'>
          <p>{data.text}</p>
          <span className="icon-search-03"></span>
        </div>
      </div>

    </div>


    <div>
      <div className='item' style={{ width: itemSize, height: itemSize, }} />
      <div className='item' style={{ width: itemSize, height: itemSize }} />
    </div>


    <div>
      <div className='item' style={{ width: itemSize, height: itemSize, }} />
      <div className='item' style={{ width: itemSize, height: itemSize }} />
    </div>

*/