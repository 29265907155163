import React from 'react'
import {
  ShapeOne, ShapeTwo, ShapeTree, ShapeFour, ShapeFive, ShapeSix, ShapeSeven, ShapeEight, ShapeNine, ShapeTen,
  ShapeEleven, ShapeTwelve, ShapeThirteen, ShapeFourteen, ShapeFifteen, ShapeSixteen, ShapeSeventeen, ShapeEighteen, ShapeNineteen,
  ShapeTwenty, ShapeTwentyOne, ShapeTwentyTwo, ShapeTwentyThree, ShapeTwentyFour, ShapeTwentyFive, ShapeTwentySix, ShapeTwentySeven,
  ShapeTwentyEight, ShapeTwentyNine, ShapeThirty, ShapeThirtyOne, ShapeThirtyTwo
} from './ShapeSvg'

const shapes = [
  'ShapeOne', 'ShapeTwo', 'ShapeTree', 'ShapeFour', 'ShapeFive', 'ShapeSix', 'ShapeSeven', 'ShapeEight', 'ShapeNine', 'ShapeTen',
  'ShapeEleven', 'ShapeTwelve', 'ShapeThirteen', 'ShapeFourteen', 'ShapeFifteen', 'ShapeSixteen', 'ShapeSeventeen', 'ShapeEighteen', 'ShapeNineteen',
  'ShapeTwenty', 'ShapeTwentyOne', 'ShapeTwentyTwo', 'ShapeTwentyThree', 'ShapeTwentyFour', 'ShapeTwentyFive', 'ShapeTwentySix', 'ShapeTwentySeven',
  'ShapeTwentyEight', 'ShapeTwentyNine', 'ShapeThirty', 'ShapeThirtyOne', 'ShapeThirtyTwo'
]
  , shapesWithoutLogo = [
    'ShapeOne', 'ShapeTwo', 'ShapeTree', 'ShapeFour', 'ShapeFive', 'ShapeSix', 'ShapeSeven', 'ShapeEight', 'ShapeNine', 'ShapeTen',
    'ShapeEleven', 'ShapeTwelve', 'ShapeThirteen', 'ShapeFourteen', 'ShapeFifteen', 'ShapeSixteen', 'ShapeSeventeen', 'ShapeEighteen', 'ShapeNineteen',
    'ShapeTwenty', 'ShapeTwentyOne', 'ShapeTwentyTwo'
  ]

const shapeSelector = (props) => {
  let item = props.value !== undefined ? shapes[props.value] : getShape()
    , x = props.axisX !== undefined ? props.axisX : 0
    , y = props.axisY !== undefined ? props.axisY : 0


  switch (item) {
    case 'ShapeOne': return <ShapeOne color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwo': return <ShapeTwo color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTree': return <ShapeTree color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeFour': return <ShapeFour color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeFive': return <ShapeFive color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeSix': return <ShapeSix color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeSeven': return <ShapeSeven color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeEight': return <ShapeEight color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeNine': return <ShapeNine color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTen': return <ShapeTen color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeEleven': return <ShapeEleven color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwelve': return <ShapeTwelve color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeThirteen': return <ShapeThirteen color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeFourteen': return <ShapeFourteen color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeFifteen': return <ShapeFifteen color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeSixteen': return <ShapeSixteen color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeSeventeen': return <ShapeSeventeen color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeEighteen': return <ShapeEighteen color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeNineteen': return <ShapeNineteen color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwenty': return <ShapeTwenty color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwentyOne': return <ShapeTwentyOne color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwentyTwo': return <ShapeTwentyTwo color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />

    case 'ShapeTwentyThree': return <ShapeTwentyThree color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwentyFour': return <ShapeTwentyFour color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwentyFive': return <ShapeTwentyFive color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwentySix': return <ShapeTwentySix color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwentySeven': return <ShapeTwentySeven color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwentyEight': return <ShapeTwentyEight color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeTwentyNine': return <ShapeTwentyNine color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeThirty': return <ShapeThirty color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeThirtyOne': return <ShapeThirtyOne color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
    case 'ShapeThirtyTwo': return <ShapeThirtyTwo color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />

    default: return <ShapeOne color={props.appState.colorTheme.level1} size={props.appState.itemSize} x={x} y={y} />
  }
}



const shape = props => {
  let x = props.axisX !== undefined ? props.axisX : 0
  return <div className='image-cover svg-cover' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', transform: `rotate(${x}deg)` }}>
    {
      shapeSelector(props)
    }
  </div>
}

export const getShape = () => shapes[Math.floor(Math.random() * shapes.length)]

export const getShapeType = () => Math.floor(Math.random() * shapesWithoutLogo.length)

export default shape
