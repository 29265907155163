import React from 'react'

const button = props => {
  let { appState, download_pdf_btn } = props
  return <a href={download_pdf_btn.download_link} target='_blank' rel='noopener noreferrer' className='item item-top download-btn' style={{ background: appState.colorTheme.secondary, maxHeight: appState.itemSize, maxWidth: appState.itemSize }}>
    <div>
      <h2>Descargar</h2>
      <h2>programa</h2>
    </div>
    <span className='icon-search-05 arrow-down' />
  </a>
}

export default button