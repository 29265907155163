import React from 'react'
import { connect } from 'react-redux'
import { isFormValid } from '../../../utils/filedValidation'
import { authUserService, askForPassword } from '../../../services/auth'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'
import { authUser } from '../../../redux/actions/userAuth'
import getTexts from '../../../services/langTexts'


class Content extends React.Component {

  state = {
    email: '',
    password: '',
    step: 'login-form',
    warningFields: []
  }

  onLogIn = () => {
    let { appState } = this.props
      , texts = getTexts('login-screen', appState.lang)
    let fields = { email: { name: texts.emailFieldPlaceholder, value: this.state.email }, password: { name: texts.pwFieldPlaceholder, value: this.state.password } }
    isFormValid(fields, messages => {
      if (messages.length === 0) this.authUserHandler()
      this.setState({ warningFields: messages })
    })
  }

  onAskForPassword = () => {
    let { appState } = this.props
      , texts = getTexts('login-screen', appState.lang)
    let fields = { email: { name: texts.emailFieldPlaceholder, value: this.state.email } }
    isFormValid(fields, messages => {
      if (messages.length === 0) this.passwordRecovry(this.state.email)
      this.setState({ warningFields: messages })
    })
  }

  passwordRecovry = async (email) => {
    let res = await askForPassword(email)
    if (res.status === 200) { this.setState({ step: 'recovery-success' }) }
  }

  authUserHandler = () => {
    let { appState } = this.props
      , texts = getTexts('login-screen', appState.lang)
    const { password, email } = this.state
    authUserService({ password, email })
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem('0mZTFbxo1u', btoa(JSON.stringify(res)))
          this.props.dispatch(authUser(res))
        } else {
          this.setState({ email: '', password: '', warningFields: [{ name: 'Error', message: texts.wrongCredentialsMsg }] })
        }
      })
      .catch(error => console.log('error', error))
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') this.onLogIn()
  }


  render() {
    let { appState } = this.props
      , texts = getTexts('login-screen', appState.lang)

    if (this.state.step === 'recovery-success') return <div className='container' style={{ background: appState.colorTheme.secondary }}>
      <div>
        <h2>{texts.pwRecoveryTitle}</h2>
        <p>{texts.pwRecoverySubtitle}</p>
      </div>
      <div className='form-container'>
        <div className='form-btn-container'>
          <div className='form-btn' onClick={() => { this.setState({ step: 'login-form' }) }}>
            <span className='icon-search-05' />
            <p>{texts.pwRecoveryGoBackBtn}</p>
          </div>
        </div>
        {
          this.state.warningFields.map((warning, i) => <p className='warning-text' key={i}>{warning.name}-{warning.message}</p>)
        }
      </div>
      <div className=''>
      </div>
    </div>

    if (this.state.step === 'recovery-form') return <div className='container' style={{ background: appState.colorTheme.secondary }}>
      <div>
        <h2 className='h2-recovery'>{texts.pwRecoveryRequestTitle}</h2>
        <p>{texts.pwRecoveryRequestSubtitle}</p>
      </div>
      <div className='form-container'>
        <input type='text' placeholder={texts.emailFieldPlaceholder} value={this.state.email} onChange={e => { this.setState({ email: e.target.value }) }} />
        <div className='form-btn-container'>
          <div className='form-btn' onClick={() => { this.setState({ step: 'login-form' }) }}>
            <span className='icon-search-05' />
            <p>{texts.pwRecoveryGoBackBtn}</p>
          </div>
          <div className='form-btn' onClick={this.onAskForPassword}>
            <span className='icon-search-05' />
            <p>{texts.pwRecoveryRequestSendBtn}</p>
          </div>
        </div>
        {
          this.state.warningFields.map((warning, i) => <p className='warning-text' key={i}>{warning.name}-{warning.message}</p>)
        }
      </div>
      <div className=''>
      </div>
    </div>

    return <div className='container' style={{ background: appState.colorTheme.secondary }}>
      <h2>Login</h2>
      <div className='form-container'>
        <input type='text' placeholder={texts.emailFieldPlaceholder} value={this.state.email} onChange={e => { this.setState({ email: e.target.value }) }} />
        <input type='password' placeholder={texts.pwFieldPlaceholder} value={this.state.password} onKeyDown={this._handleKeyDown} onChange={e => { this.setState({ password: e.target.value }) }} />

        <div className='form-btn-container'>
          <Link to={getUrl('register')} className='form-btn'>
            <span className='icon-search-05' />
            <p>{texts.registerBtn}</p>
          </Link>
          <div className='form-btn' onClick={this.onLogIn}>
            <span className='icon-search-05' />
            <p>{texts.logInBtn}</p>
          </div>
        </div>
        {
          this.state.warningFields.map((warning, i) => <p className='warning-text' key={i}>{warning.name}-{warning.message}</p>)
        }
      </div>
      <div className='pwd-recovery'>
        <p onClick={() => { this.setState({ step: 'recovery-form' }) }}>{texts.forgotPwBtn}</p>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Content)