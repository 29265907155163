import React from 'react'
import { connect } from 'react-redux'

class Grid extends React.Component {

  render() {
    if (!this.props.children.length) return null
    let { appState, half } = this.props
      , size = appState.itemSize
      , headerSize = half ? (appState.headerItemSize / 2) : (appState.headerItemSize)
      , height = document.getElementsByTagName('BODY')[0].offsetHeight - headerSize
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4

    padding = Math.round((padding - (size * appState.item)) / 2)

    return <div style={{ position: 'relative', height, background: 'black', paddingLeft: `${padding + 4}px` }}>
      {
        this.props.children.map((item, index) => {
          let { values, className } = item.props
          let heightItem = height
            , sizeCorrection = 0

          if (values.searchMode) heightItem = (appState.itemSize * 2) / 6
          if (index > 0 && this.props.children[index - 1].props.values.searchMode) sizeCorrection = size - ((appState.itemSize * 2) / 6)
          return React.cloneElement(item, {
            className: `grid-item ${className}`,
            style: {
              background: appState.colorTheme.base,
              transitionProperty: 'left, top',
              height: `${heightItem - 4}px`,
              transform: `translate(${(size * values.x)}px, ${(size * values.y) - sizeCorrection}px)`,
              width: `${(values.w * size) - 4}px`,
              overflow: 'hidden'
            }
          })
        })
      }
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(Grid)


/*

let { appState, noScroll } = this.props
      , size = appState.itemSize
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , heightExtra = document.getElementsByTagName('BODY')[0].offsetHeight
      , gridSize = this.props.children[this.props.children.length - 1]
      , addExtra = this.props.children[this.props.children.length - 1].props.values.withExtra
      , paddingTop = this.props.paddingTop ? this.props.paddingTop : 0

    // if (noScroll) padding += this.getScrollbarWidth()

    padding = Math.round((padding - (size * appState.item)) / 2)

    let lastItemSize = gridSize ? (gridSize.props.values.h - 1) : 0

    lastItemSize = lastItemSize > 0 ? (lastItemSize * size) : 0
    gridSize = gridSize ? gridSize.props.values.y + 1 : 0
    gridSize = gridSize * size
    gridSize = gridSize + lastItemSize
    heightExtra = heightExtra - (this.props.appState.headerItemSize + (appState.itemSize * 2)) + (appState.itemSize / 2)
    gridSize = (appState.itemSize * 2) + heightExtra

    return <div style={{ paddingTop: paddingTop, position: 'relative', height: gridSize + paddingTop, background: 'black', paddingLeft: `${padding + 4}px` }}>
      {
        this.props.children.map((item, index) => {
          let { values } = item.props
            , extra = values.withExtra ? heightExtra : 0
          return React.cloneElement(item, {
            className: 'grid-item',
            style: {
              background: appState.colorTheme.base,
              transitionProperty: 'left, top',
              height: `${((appState.itemSize * 2) + heightExtra) - 8}px`,
              transform: `translate(${(size * values.x)}px, ${(size * values.y)}px)`,
              width: `${(values.w * size) - 4}px`,
              overflow: 'hidden'
            }
          })
        })
      }
    </div>

*/