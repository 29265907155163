import { getTheme } from '../../utils/themeColors'

export const setItemSize = (itemSize = 50) => ({
  type: 'SET_ITEM_SIZE',
  itemSize
})

export const setHeaderItemSize = (headerItemSize = 50) => ({
  type: 'SET_HEADER_ITEM_SIZE',
  headerItemSize
})

export const setItems = (item = 1) => ({
  type: 'SET_ITEMS',
  item
})

export const setHeaderItems = (headerItem = 1) => ({
  type: 'SET_HEADER_ITEMS',
  headerItem
})

export const setTheme = (colorTheme = 'theme-1') => ({
  type: 'SET_COLOR_THEME',
  colorTheme: getTheme(colorTheme),
})

export const setThemeString = (colorThemeString = 'theme-1') => ({
  type: 'SET_COLOR_THEME_STRING',
  colorThemeString,
})

export const setMenuStatus = (isMenuOpen = false) => ({
  type: 'SET_MENU_STATUS',
  isMenuOpen,
})

export const setLang = (lang = 'es') => ({
  type: 'SET_LANG',
  lang,
})