import React from 'react'
import Loading from '../../../baseComponents/Loading'
import Modal from '../../../baseComponents/Modal'

export default class PDFVisor extends React.Component {

  state = {
    loading: true,
    isPDFVisible: false
  }

  onLoad = () => {
    this.setState({ loading: false })
  }

  render() {
    let { url, appState, resources } = this.props
      , { isPDFVisible } = this.state

    return <React.Fragment>
      <div className='centered image-cover-bg' style={{ background: `url("${resources.thumbnail_pdf}")` }}>
        <a rel='noopener noreferrer' href={url} target='_blank' style={{ background: appState.colorTheme.secondary }} className='pdf-button'>Ver pdf</a>
      </div>
      {
        isPDFVisible
          ? <Modal>
            <div className='pdf-visor'>
              {
                this.state.loading ? <div style={{ height: '100%' }}> <Loading percent /> </div> : null
              }
              <div style={{ background: appState.colorTheme.base }} className='pdf-button-close'>
                <span className='icon-search-04' onClick={() => { this.setState({ isPDFVisible: false }) }} />
              </div>
              <iframe onLoad={this.onLoad} title='SOMA' className="viewer-embed" src={`https://docs.google.com/gview?url=${url}&embedded=true`} frameBorder="0"></iframe>

            </div>
          </Modal>
          : null
      }
    </React.Fragment>
  }
}
