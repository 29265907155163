import React from 'react'

const backgrund3X4 = props => {
  let { appState } = props
    , columnSize = appState.itemSize

  return <div className='background' style={{ background: `url("${props.banner}")` }}>
    <div className='container'>
      <div className='column no-left' style={{ width: columnSize - 2 }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
      <div className='column' style={{ width: (columnSize) }}>

      </div>
      <div className='column no-right' style={{ width: columnSize - 2 }}>
        <div className='item item-top' />
        <div className='item item-center' />
        <div className='item item-center' />
        <div className='item item-bottom' />
      </div>
    </div>
  </div>
}

export default backgrund3X4