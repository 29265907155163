import axios from './instance'
import store from '../redux/store'
import { setFilters, setAmountPages } from '../redux/actions/objects'
import { filtersBase } from '../utils/filters'

export const getBanners = () => {
  return axios.get('https://soma.alucinaprojects.com/api/banners')
}

export const homeResourses = (template = '?template=1') => {
  let { lang } = store.getState().appState
  // return axios.get(`home?lang=${lang}&${template.replace('?', '')}`)
  // return axios.get('https://soma.alucinaprojects.com/api/index')
  return axios.get(`${lang === 'en' ? '/en' : ''}/index`)
}


export const summerProgramResources = () => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/program/SUMMER`)
}

export const pesProgramResources = () => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/program/PES`)
}

export const pesAboutProgramResources = () => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/program/PES/about`)
    .then(async (res) => {
      let banners = await getBanners()
      res.data.banners = banners.data
      return res
    })
}

export const summerAboutProgramResources = () => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/program/SUMMER/about`)
}

export const residenceProgramResources = () => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/residences`)
}

export const wednesdayProgramResources = () => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/soma_wednesday`)
}

export const searchSuggestions = (textFilter) => {
  let { tag_program, tag_person, tag_object, tag_topic, year } = filtersBase
    , { page } = store.getState().objects
  let { lang } = store.getState().appState
  let formData = new FormData()
  for (let index = 0; index < tag_program.length; index++) formData.append('tag_program[]', tag_program[index].id)
  for (let index = 0; index < tag_person.length; index++) formData.append('tag_person[]', tag_person[index].id)
  for (let index = 0; index < tag_object.length; index++) formData.append('tag_object[]', tag_object[index].id)
  for (let index = 0; index < tag_topic.length; index++) formData.append('tag_topic[]', tag_topic[index].id)

  if (tag_program.length === 0) formData.append('tag_program[]', 0)
  if (tag_person.length === 0) formData.append('tag_person[]', 0)
  if (tag_object.length === 0) formData.append('tag_object[]', 0)
  if (tag_topic.length === 0) formData.append('tag_topic[]', 0)

  formData.append('year', year)
  formData.append('num_page', page)
  formData.append('num_items', 100000)

  return axios({
    url: `${lang === 'en' ? '/en' : ''}/search_all_site`,
    method: 'POST',
    data: formData
  })
    .then(res => {
      return res.data
    })
}

export const getItems = (items) => {
  let { lang } = store.getState().appState
  return axios.get(`items?q=${items}&lang=${lang}`)
}

export const getObject = (id, type) => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/${type}/${id}`)
    .then(res => {
      let data = res.data
      data.objectTitle = type === 'person' ? data.fullname : data.title
      data.contentOne = type === 'person' ? data.borndate : data.startDate
      data.contentTwo = type === 'person' ? data.bornplace : data.endDate
      data.mainContent = type === 'person' ? data.biography : data.description
      data.mainContentEn = type === 'person' ? data.biography_en : data.description_en
      data.arrayContent = type === 'person'
        ? data.objects.filter(c => c.title.length)
        : data.collaborators.filter(c => c.collaborator.length)
      data.type = type
      if (data.nextWednesdays) data.nextWednesdays = data.nextWednesdays.sort((a, b) => a.date - b.date)

      if (data.person_tags) {
        let tags = data.person_tags.sort((a, b) => a.tag.localeCompare(b.tag))
          , pivot = ''
          , tagsJoined = []
          , years = []

        for (let index = 0; index < tags.length; index++) {
          if (pivot !== tags[index].tag) {
            if (pivot !== '') {
              tagsJoined.push({ tag: pivot, year: years.sort().join(' / ') })
              years = []
            }
            pivot = tags[index].tag
          }
          if (tags[index].year) years.push(tags[index].year)
        }

        tagsJoined.push({ tag: pivot, year: years.sort().join(' / ') })
        data.person_tags = tagsJoined
      }

      return data
    })
}

export const getFilterList = () => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/public_tags`)
    .then(res => {
      let filters = { tag_program: [], tag_person: [], tag_object: [], tag_topic: [], }
      filters.tag_program = res.data.tags.programs
      filters.tag_person = res.data.tags.persons
      filters.tag_object = res.data.tags.objects
      filters.tag_topic = res.data.tags.topics
      store.dispatch(setFilters(filters))
      return res
    })
}

export const getSuggestionItems = () => {
  return axios.get('https://soma.alucinaprojects.com/api/random_objects/4')
    .then(res => {
      return res
    })
}

export const searchObjects = (textFilter) => {
  let { lang } = store.getState().appState
  let { tag_program, tag_person, tag_object, tag_topic, year, text, sortMode, sort } = store.getState().objects.filtersApplied
    , { page, numItems } = store.getState().objects
  let formData = new FormData()
  formData.append('text', typeof textFilter === 'string' ? textFilter : text)
  for (let index = 0; index < tag_program.length; index++) formData.append('tag_program[]', tag_program[index].id)
  for (let index = 0; index < tag_person.length; index++) formData.append('tag_person[]', tag_person[index].id)
  for (let index = 0; index < tag_object.length; index++) formData.append('tag_object[]', tag_object[index].id)
  for (let index = 0; index < tag_topic.length; index++) formData.append('tag_topic[]', tag_topic[index].id)
  for (let index = 0; index < year.length; index++) formData.append('year[]', year[index])


  if (tag_program.length === 0) formData.append('tag_program[]', 0)
  if (tag_person.length === 0) formData.append('tag_person[]', 0)
  if (tag_object.length === 0) formData.append('tag_object[]', 0)
  if (tag_topic.length === 0) formData.append('tag_topic[]', 0)
  if (year.length === 0) formData.append('year[]', 0)

  formData.append('num_page', page)
  formData.append('num_items', numItems)
  formData.append('order', sort)
  formData.append('orderBy', sortMode)

  return axios({
    url: `${lang === 'en' ? '/en' : ''}/search`,
    method: 'POST',
    data: formData
  })
    .then(res => {
      if (!textFilter) store.dispatch(setAmountPages(res.data.total_pages))
      let joinValues = res.data.results
        , pivot = ''
        , tagsJoined = []
        , years = []

      for (let index = 0; index < joinValues.length; index++) {
        let tags = joinValues[index].tags.sort((a, b) => a.tag.localeCompare(b.tag))

        for (let indexOfTag = 0; indexOfTag < tags.length; indexOfTag++) {
          if (pivot !== tags[indexOfTag].tag) {
            if (pivot !== '') {
              tagsJoined.push({ tag: pivot, year: years.sort().join(' / ') })
              years = []
            }
            pivot = tags[indexOfTag].tag
          }
          if (tags[indexOfTag].year) years.push(tags[indexOfTag].year)
        }
        tagsJoined.push({ tag: pivot, year: years.sort().join(' / ') })
        joinValues[index].tags = tagsJoined
        tagsJoined = []
        pivot = ''
        years = []
      }
      return res.data
    })
}

export const getCalendarItems = (filter = 'general', text = '', showAll = false, numPage = 0) => {
  let { lang } = store.getState().appState
  const formData = new FormData()
  formData.append('filter', filter)
  formData.append('text', text)
  formData.append('show', showAll)
  formData.append('num_page', numPage)
  return axios({
    url: `${lang === 'en' ? '/en' : ''}/news`,
    method: 'POST',
    data: formData
  })
    .then(res => {
      return res
    })
}

export const getAboutResources = () => {
  let { lang } = store.getState().appState
  return axios.get(`${lang === 'en' ? '/en' : ''}/us?v=${Math.random()}`)
}
