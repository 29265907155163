import React from 'react'
import Header from './components/Header'
import Text from './components/Text'
import Error from './components/Error'
import Color from './components/Color'
import Button from './components/Button'
import Grid from '../../baseComponents/Grid'
import { connect } from 'react-redux'
import getTemplate from './template'

import './NotFound.css'



const getComponent = (component, props) => {
  switch (component) {
    case 'Text': return <Text {...props} />
    case 'Error': return <Error {...props} />
    case 'Color': return <Color {...props} />
    case 'Button': return <Button {...props} />
    default: return null
  }
}


const notFound = props => <div id='not-found' className='container'>
  <Header />
  <Grid with>
    {
      getTemplate()[props.appState.item].map((item, index) => <div
        key={index}
        values={{ x: item.x, y: item.y, w: item.w, h: item.h, withExtra: item.withExtra }}
      >
        {getComponent(item.component, { ...props, ...item })}
      </div>)
    }
  </Grid>
</div>

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(notFound)