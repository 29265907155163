import React from 'react'
import { applyFilters, setInitialized } from '../../../redux/actions/objects'
import { filtersBase } from '../../../utils/filters'
import { getUrl } from '../../../utils/routesDefinition'

export default class Content extends React.Component {

  state = {
    activeTutor: { ...this.props.main_tutors.items[0], id: 0, }
  }

  setTutor = value => {
    let { activeTutor } = this.state
      , { main_tutors } = this.props
      , index = activeTutor.id + value < 0 ? main_tutors.items.length - 1 : activeTutor.id + value
    index = main_tutors.items.length - 1 < index ? 0 : index

    activeTutor = main_tutors.items.find((_, i) => i === index)
    this.setState({ activeTutor: { ...activeTutor, id: index, } })
  }

  getBanner = (banner, top, bottom) => {
    let { appState, banners } = this.props
    if (appState.item === 4) return <div className={`background row row-border image-cover-bg  ${top ? 'no-top' : ''}`} style={{ height: appState.itemSize, background: `url("${banners[banner]}")` }} >
      <div className='item-row no-left' />
      <div className='item-row' />
      <div className='item-row' />
      <div className='item-row no-right' />
    </div>

    if (appState.item === 2) return <div className={`background row row-border image-cover-bg  ${top ? 'no-top' : ''}`} style={{ height: appState.itemSize, background: `url("${banners[banner]}")` }} >
      <div className='item-row no-left' />
      <div className='item-row no-right' />
    </div>

    return null
  }

  setFilters = () => {
    let years = this.props.about.filters.year ? this.props.about.filters.year : this.props.about.filters.years
    years = years.map(year => Number(year))
    let filters = { ...filtersBase, ...this.props.about.filters, year: years }
    this.props.dispatch(applyFilters(filters))
    this.props.dispatch(setInitialized(true))
    this.props.history.push(getUrl('objects'))
  }

  componentDidMount() {
    if (this.props.location.hash === '#info') document.getElementById('info-pes').scrollIntoView()
  }

  render() {
    let { appState, about, academic_training, announcement, goals, profile, lastItem } = this.props
      , size = appState.itemSize
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , left = size + 3
      , width = (size * 4)

    padding = Math.round((padding - (size * appState.item)) / 2)
    left += padding

    if (appState.item !== 6) left = 4
    if (appState.item === 2) {
      width = size * 2
    }

    return <div className='content-container' style={{ height: (lastItem.y) * size, left, width, background: appState.colorTheme.base }}>
      <div>
        {this.getBanner(0, true, false)}
        <div id='about-pes' className='content-padding-first'>
          <h2>{about.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: about.description.replace(/style="[^"]*"/g, '') }} />
          {
            appState.item !== 6
              ? <div onClick={this.setFilters} class="more-button" style={{ color: appState.colorTheme.secondary }}>
                <div>Ver años anteriores</div>
                <span class="icon-search-05"></span>
              </div>
              : null
          }
        </div>
        <div id='goals-pes' className='content-padding'>
          <h2>OBJETIVOS</h2>
          <div>
            {
              goals
                .map((goal, index) => <div key={index}
                  className={`objective-item ${index === 3 ? 'padding-right' : ''}`} style={{ marginTop: index > 2 ? '2vw' : 0 }}>
                  <h3>{index + 1}</h3>
                  <p>{goal.goal}</p>
                </div>)
            }
          </div>
        </div>
        <div className='content-padding'>
          <img src={profile.banner} alt='banner' style={{ width: '80%' }} />
        </div>
        <div id='info-pes' className='content-padding'>
          <div className='content-column'>
            <h2>{announcement.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: announcement.description.replace(/style="[^"]*"/g, '') }} />
          </div>
          <div className='content-column'>
            <h2>{profile.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: profile.description.replace(/style="[^"]*"/g, '') }} />
          </div>
        </div>
        {/* <div id='tutors-pes' className='content-padding'>
          <h2>{main_tutors.title}</h2>
          <div style={{ width: '80%' }} dangerouslySetInnerHTML={{ __html: main_tutors.description.replace(/style="[^"]*"/g, '') }} />
          <div className='active-tutor row no-top no-bottom'>
            <div className='content-column'>
              <div className='image-cover-bg' style={{ width: '100%', height: '25vw', background: `url("${activeTutor.banner}")` }} />
              <div className='tutor-action'>
                <h2 className='tutor-name'>{activeTutor.name}</h2>
                <p>
                  <span className="icon-search-05 arrow-btn" onClick={() => this.setTutor(-1)}></span>
                  {activeTutor.id + 1}/{main_tutors.items.length}
                  <span className="icon-search-05 arrow-btn" onClick={() => this.setTutor(1)}></span>
                </p>
              </div>
            </div>
            <div className='content-column'>
              <div dangerouslySetInnerHTML={{ __html: activeTutor.description }} />
            </div>
          </div>
        </div> */}

        {/* <div id='invited-tutors' className='content-padding'>
          <h2>{invited_tutors.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: invited_tutors.description }} />
        </div> */}
        {this.getBanner(2)}
        <div id='academic_training' className='content-padding'>
          <h2>{academic_training.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: academic_training.description.replace(/style="[^"]*"/g, '') }} />
        </div>

      </div>
    </div>
  }
}