import React from 'react'
import { Link } from 'react-router-dom'
import { getUrlByType } from '../../../utils/routesDefinition'

export default class Container extends React.Component {

  state = {
    activeElement: { ...this.props.residences[0], id: 0, }
  }

  onSetElement = value => {
    let { activeElement } = this.state
      , { residences } = this.props
      , index = activeElement.id + value < 0 ? residences.length - 1 : activeElement.id + value
    index = residences.length - 1 < index ? 0 : index

    activeElement = residences.find((_, i) => i === index)
    this.setState({ activeElement: { ...activeElement, id: index, } })
  }

  render() {
    let { appState } = this.props
      , { activeElement } = this.state
      , columnSize = appState.itemSize

    if (this.props.w === 2) return <div className='container-block'>
      <Link to={`${getUrlByType(activeElement.objectType)}?id=${activeElement.key}`} className='background' style={{ background: `url("${activeElement.banner}")`, height: (columnSize * 2) }}>
        <div className='container'>
          <div className='column no-left' style={{ width: columnSize - 2 }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
          <div className='column no-right' style={{ width: columnSize - 2 }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
        </div>
      </Link>
      <div className='container-info'>
        <div className='container-data'>
          <div style={{ minWidth: '30%' }}>
            <span onClick={() => this.onSetElement(-1)} className="icon-search-05 icon-left"></span>
            <span onClick={() => this.onSetElement(1)} className="icon-search-05"></span>
          </div>
          <div style={{ flex: 1, }}>
            <h3><Link to={`${getUrlByType(activeElement.objectType)}?id=${activeElement.key}`}>{activeElement.person}</Link></h3>
            <p>{activeElement.subtitle}</p>
          </div>
        </div>
      </div>
    </div>



    if (this.props.w === 3) return <div className='container-block'>
      <Link to={`${getUrlByType(activeElement.objectType)}?id=${activeElement.key}`} className='background' style={{ background: `url("${activeElement.banner}")`, height: (columnSize * 2) }}>
        <div className='container'>
          <div className='column no-left' style={{ width: columnSize - 2 }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
          <div className='column' style={{ width: (columnSize) }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
          <div className='column no-right' style={{ width: columnSize - 2 }}>
            <div className='item item-top' />
            <div className='item item-bottom item-top' />
          </div>
        </div>
      </Link>
      <div className='container-info'>
        <div className='container-data'>
          <div>
            <span onClick={() => this.onSetElement(-1)} className="icon-search-05 icon-left"></span>
            <span onClick={() => this.onSetElement(1)} className="icon-search-05"></span>
          </div>
          <div>
            <h3><Link to={`${getUrlByType(activeElement.objectType)}?id=${activeElement.key}`}>{activeElement.person}</Link></h3>
            <p>{activeElement.subtitle}</p>
          </div>
        </div>
      </div>
    </div>




    return null
  }
}