import React from 'react'
import { connect } from 'react-redux'
import Header from './components/Header'
import Grid from '../../baseComponents/Grid'
import Background6X4 from './components/Background6X4'
import Background3X4 from './components/Background3X4'
import Background2X2 from './components/Background2X2'
import BackgroundOne from './components/BackgroundOne'
import Button from './components/Button'
import Content from './components/Content'
import Loading from '../../baseComponents/Loading'
import Newsletter from '../../baseComponents/Newsletter'
import ContactProgram from '../../baseComponents/ContactProgram'
import getTemplate from './template'

import { summerAboutProgramResources } from '../../services/pageResources'

import { applyFilters, setInitialized } from '../../redux/actions/objects'
import { filtersBase } from '../../utils/filters'
import { getUrl } from '../../utils/routesDefinition'


import './SUMMERAboutScreen.css'

class SUMMERAboutScreen extends React.Component {

  state = {
    isFetching: true,
    content: null,
    template: [],
    isScrollActive: false,
  }

  onActiveSearch = () => {
  }

  setFilters = () => {
    let years = this.state.content.sections.about.filters.year ? this.state.content.sections.about.filters.year : this.state.content.sections.about.filters.years
    years = years.map(year => Number(year))
    let filters = { ...filtersBase, ...this.state.content.sections.about.filters, year: years }
    this.props.dispatch(applyFilters(filters))
    this.props.dispatch(setInitialized(true))
    this.props.history.push(getUrl('objects'))
  }

  getComponent = (component, props) => {
    let { content } = this.state
    if (component === null) return component
    switch (component) {
      case 'Background6X4': return <Background6X4 onClick={this.setFilters} {...props} banner={content.banners[0]}  {...content} />
      case 'Background3X4': return <Background3X4 {...props} banner={content.banners[1]} />
      case 'Background2X2': return <Background2X2 {...props} banner={content.banners[2]} />
      case 'BackgroundOne': return <BackgroundOne {...props} banner={content.banners[3]} />
      case 'Button': return <Button {...props} {...content} />
      case 'Newsletter': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <Newsletter color={color} />
      }
      case 'ContactProgram': {
        let color = props.appState.colorThemeString
        color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
        return <ContactProgram banners={content.banners} color={color} />
      }
      default: return null
    }
  }

  onScroll = e => {
    let { isScrollActive } = this.state
    if (document.getElementById('search-content').scrollTop > 10 && !isScrollActive) {
      this.setState({ isScrollActive: true })
      document.getElementById('search-content').scrollTo(1000, 100)
    } else if (isScrollActive && document.getElementById('search-content').scrollTop < 1) {
      this.setState({ isScrollActive: false })
    }
  }

  getData = () => {
    this.setState({ template: getTemplate()[this.props.appState.item] })
    summerAboutProgramResources()
      .then(res => {
        this.setState({ content: res.data, isFetching: false })
      })
      .catch(err => console.log('error', err))
  }

  componentDidMount() {
    this.getData()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.appState.item !== nextProps.appState.item) {
      this.setState({ template: getTemplate()[nextProps.appState.item] })
    }
    if (nextProps.appState.lang !== this.props.appState.lang) {
      this.setState({
        isFetching: true,
        content: null,
        template: []
      }, this.getData)
    }
  }


  render() {
    let { isFetching, template, content, isScrollActive } = this.state

    if (isFetching) return <Loading />

    let marginTop = isScrollActive
      ? this.props.appState.headerItemSize / 2
      : this.props.appState.headerItemSize

    return <div id='summer-about' className='search-main-container' onScroll={this.onScroll}>
      <div id='search-content' className='search-content' >
        <div className={`search-header ${isScrollActive ? 'fixed' : ''}`}>
          <Header isScrollActive={isScrollActive} onActiveSearch={this.onActiveSearch} />
        </div>
        <div id="main" className='search-animation-container' style={{ marginTop }}>
          <Grid>
            {
              template.map((item, index) => <div
                key={index}
                values={{ x: item.x, y: item.y, w: item.w, h: item.h, }}
              >
                {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
              </div>)
            }
          </Grid>
          <Content {...this.props} {...content} lastItem={template[template.length - 1]} />
        </div>
      </div>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(SUMMERAboutScreen)