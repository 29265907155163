import React from 'react'
import Header from './HeaderFilters'
import Grid from './Grid'
import template from '../template'
import { connect } from 'react-redux'
import { css } from 'glamor'
import { applyFilters, setInitialized } from '../../../redux/actions/objects'
import { searchSuggestions, } from '../../../services/pageResources'
import getTexts from '../../../services/langTexts'

const suggestions = [
  {
    filter: { id: 3, tag: 'Miércoles de SOMA' },
    type: 'tag_program',
    banner: 'https://somamexico.org/storage/objects/October2019/ZyeRQe5uEQpNXKq3RdAZ.png',
    title: 'Miércoles de SOMA'
  },
  {
    filter: { id: 1, tag: 'PES' },
    type: 'tag_program',
    banner: 'https://somamexico.org/storage/objects/October2019/ZEE2U5u9B2FsB4aX6nlM.png',
    title: 'Programa PES'
  },
  {
    filter: { id: 4, tag: 'Residencias' },
    type: 'tag_program',
    banner: 'https://somamexico.org/storage/objects/October2019/tqUiaSVdgpyn5oGHvad3.png',
    title: 'Residencias'
  },
  {
    filter: { id: 2, tag: 'Cuarto de Proyectos' },
    type: 'tag_object',
    banner: 'https://somamexico.org/storage/objects/October2019/Ksl9xIGrGlgkzxqnXxzL.png',
    title: 'Cuarto de proyectos'
  }
]
let years = []
  , limit = (new Date()).getFullYear() + 2
for (let year = 2009; year <= limit; year++) years.push(year)

years = years.reverse()

class FiltersScreen extends React.Component {

  state = {
    active: '',
    searchText: '',
    initialized: false,
    suggestionItems: [],
    suggestionsBase: [],
    hasSuggestions: false,
  }

  setActive = active => {
    this.setState(prevState => ({ active: prevState.active === active ? '' : active }))
  }
  onSetSuggestion = searchText => { this.setState({ searchText, suggestionItems: [] }) }


  setFilter = (type, filter) => {
    let { filtersApplied } = this.props.objects
    if (type === 'tag_year') filtersApplied.year = [filter]
    else filtersApplied[type] = [filter]
    this.setState({ active: '', initialized: true })
    this.props.dispatch(applyFilters(filtersApplied))
  }

  setSuggestionSearch = (type, filter) => {
    let { filtersApplied } = this.props.objects
    if (type === 'tag_year') filtersApplied.year = [filter]
    else filtersApplied[type] = [filter]
    this.props.dispatch(applyFilters(filtersApplied))
    this.props.dispatch(setInitialized(true))
    this.props.onSearch()
  }

  prevent = e => e.stopPropagation()

  onSearchChange = event => {
    let { searchText, suggestionsBase } = this.state
    if (searchText.length === 0 && event.target.value.length === 1) {
      searchSuggestions(event.target.value)
        .then(res => { this.setState({ suggestionItems: res, suggestionsBase: res }) })
        .catch(err => console.log(err))
    }
    if (suggestionsBase.length && event.target.value.length !== 0) {
      this.setState({ suggestionItems: suggestionsBase.filter(suggestion => suggestion.title.toUpperCase().search(escape(event.target.value.toUpperCase())) !== -1) })
    }
    if (event.target.value.length === 0) this.setState({ suggestionItems: [], })
    this.setState({ searchText: event.target.value })
  }

  onSearch = (event) => {
    if (event) event.preventDefault()
    if (this.state.initialized || this.state.searchText.length) {
      let { filtersApplied } = this.props.objects
      filtersApplied.text = this.state.searchText
      this.props.dispatch(applyFilters(filtersApplied))
      this.props.dispatch(setInitialized(true))
      this.props.onSearch()
    }
  }


  onLuckySearch = e => {
    e.preventDefault()
    this.setState({ initialized: true }, this.onSearch)
  }

  componentDidMount() {
    setTimeout(() => {
      this.searchInput.focus()
    }, 500)
  }

  render() {
    let { appState, objects } = this.props
      , { searchText, active, suggestionItems } = this.state
      , paddingContainer = appState.item < 6 ? appState.headerItemSize / 2 : appState.headerItemSize
      , height = appState.item === 2 ? (appState.headerItemSize) / 2 : (appState.itemSize) / 3
      , itemHover = css({
        ':hover': { background: appState.colorTheme.secondary },
      })
      , texts = getTexts('objects-screen', appState.lang)

    let searchBarStyles = { background: this.props.appState.colorTheme.base, height: (this.props.appState.itemSize / 3) - 4, width: '100%' }

    let suggestionStyles = {
      borderLeft: '2px solid black',
      borderRight: '2px solid black',
      borderBottom: '2px solid black',
      marginLeft: '-2px',
      width: this.props.appState.item === 6
        ? (this.props.appState.itemSize * 4) - 2
        : this.props.appState.item === 4
          ? this.props.appState.itemSize * this.props.appState.item
          : '100%'
    }
    if (this.props.appState.item === 6) {
      searchBarStyles.width = (this.props.appState.itemSize * 4) + 4
      searchBarStyles.marginLeft = this.props.appState.itemSize + 2
      searchBarStyles.borderLeft = '4px solid black'
      searchBarStyles.borderRight = '4px solid black'
    }

    if (this.props.appState.item === 2) suggestionStyles.marginRight = '-8px'

    let styleActive = appState.colorTheme.secondary
      , styleInactive = appState.colorTheme.base

    return <div id='filter-screen'>
      <Header />
      <Grid>
        {
          template('initialTemplate')[this.props.appState.item]
            .map((item, index) => <div className='container' key={index} values={{ x: item.x, y: item.y, w: item.w, h: item.h, }} >
              <div className='filters-block' style={{ margin: `0 ${paddingContainer}px` }}>
                <div className='filters-container'>
                  <div className='filter-input-container' style={{ height }}>
                    <form onSubmit={this.onSearch} >
                      <span className="icon-search-02"></span>
                      <input ref={ref => this.searchInput = ref} value={searchText} onChange={this.onSearchChange} type='text' className='search-input' placeholder={texts.searchInputPlaceholder} />
                    </form>
                    {
                      suggestionItems.length
                        ? <div className='search-suggestions'
                          style={{ ...suggestionStyles, top: height, minHeight: `${this.props.appState.itemSize}px`, maxHeight: `${this.props.appState.itemSize * 2}px`, background: this.props.appState.colorTheme.base }}>
                          {
                            suggestionItems.map((suggestion, index) => <div key={index} className='suggestion-item' onClick={() => this.onSetSuggestion(suggestion.title)}>{suggestion.title}</div>)
                          }
                        </div>
                        : null
                    }
                  </div>
                  <div className='filter-select-container' style={{ flexDirection: appState.item === 2 ? 'column' : 'row' }}>
                    <div className='filter-item-container'>
                      <div onClick={() => this.setActive('tag_program')} className='filter-item-tag' style={{ height, background: active === 'tag_program' ? styleActive : styleInactive }}>
                        <p>{objects.filtersApplied.tag_program[0] ? objects.filtersApplied.tag_program[0].tag : texts.programTitleBtn}</p>
                        <span className={`icon-search-05 ${active === 'tag_program' ? 'active' : ''}`} />
                      </div>
                      {
                        active === 'tag_program' &&
                        <div className='filter-item-tag-container' style={{ height: (height * 3) + 8, background: appState.colorTheme.base }}>
                          {objects.filters.tag_program.map(program => <p {...itemHover} key={program.tag} onClick={() => this.setFilter('tag_program', program)}>{program.tag}</p>)}
                        </div>
                      }
                    </div>

                    <div className='filter-item-container'>
                      <div onClick={() => this.setActive('tag_object')} className='filter-item-tag' style={{ height, background: active === 'tag_object' ? styleActive : styleInactive }}>
                        <p>{objects.filtersApplied.tag_object[0] ? objects.filtersApplied.tag_object[0].tag : texts.activityTitleBtn}</p>
                        <span className={`icon-search-05 ${active === 'tag_object' ? 'active' : ''}`} />
                      </div>
                      {
                        active === 'tag_object' &&
                        <div className='filter-item-tag-container' style={{ height: (height * 3) + 8, background: appState.colorTheme.base }}>
                          {objects.filters.tag_object.map(program => <p {...itemHover} key={program.tag} onClick={() => this.setFilter('tag_object', program)}>{program.tag}</p>)}
                        </div>
                      }
                    </div>

                    <div className='filter-item-container'>
                      <div onClick={() => this.setActive('tag_person')} className='filter-item-tag' style={{ height, background: active === 'tag_person' ? styleActive : styleInactive }}>
                        <p>{objects.filtersApplied.tag_person[0] ? objects.filtersApplied.tag_person[0].tag : texts.personTitleBtn}</p>
                        <span className={`icon-search-05 ${active === 'tag_person' ? 'active' : ''}`} />
                      </div>
                      {
                        active === 'tag_person' &&
                        <div className='filter-item-tag-container' style={{ height: (height * 3) + 8, background: appState.colorTheme.base }}>
                          {objects.filters.tag_person.map(program => <p {...itemHover} key={program.tag} onClick={() => this.setFilter('tag_person', program)}>{program.tag}</p>)}
                        </div>
                      }
                    </div>

                    <div className='filter-item-container'>
                      <div onClick={() => this.setActive('tag_year')} className='filter-item-tag' style={{ height, background: active === 'tag_year' ? styleActive : styleInactive }}>
                        <p>{objects.filtersApplied.year[0] ? objects.filtersApplied.year[0] : texts.yearTitleBtn}</p>
                        <span className={`icon-search-05 ${active === 'tag_year' ? 'active' : ''}`} />
                      </div>
                      {
                        active === 'tag_year' &&
                        <div className='filter-item-tag-container' style={{ height: (height * 3) + 8, background: appState.colorTheme.base }}>
                          {years.map(year => <p {...itemHover} key={year} onClick={() => this.setFilter('tag_year', year)}>{year}</p>)}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className='filter-options'>
                  <div className='filter-button-container' style={{ flexDirection: appState.item === 2 ? 'column-reverse' : 'row' }}>
                    <div className='filter-text' style={{ height }}>
                      <p>{texts.infoTextDesc}</p>
                    </div>
                    <div className='filter-buttons' style={{ height }}>
                      <p onClick={this.onLuckySearch}>{texts.quickSearchBtn}</p>
                      <p onClick={this.onSearch} style={{ background: appState.colorTheme.secondary }}>{texts.searchFilterBtn}</p>
                    </div>
                  </div>
                  <div className='filter-suggestion-container'>
                    {
                      appState.item !== 4
                        ? suggestions
                          .map((suggestion, index) => <div onClick={() => this.setSuggestionSearch(suggestion.type, suggestion.filter)} key={index} className='suggestion-box' style={{ width: appState.item === 2 ? '47%' : appState.itemSize - 20 }}>
                            <div className='suggestion-banner' style={{ background: `url("${suggestion.banner}")`, height }} />
                            <div  {...itemHover} className='suggestion-content' style={{ height }} >
                              <p>{suggestion.title}</p>
                              <span className="icon-search-03"></span>
                            </div>
                          </div>)
                        : suggestions
                          .filter((_, index) => index < 3)
                          .map((suggestion, index) => <div onClick={() => this.setSuggestionSearch(suggestion.type, suggestion.filter)} key={index} className='suggestion-box' style={{ width: appState.item === 2 ? '47%' : appState.itemSize - 20 }}>
                            <div className='suggestion-banner' style={{ background: `url("${suggestion.banner}")`, height }} />
                            <div className='suggestion-content' style={{ height: height - 20 }} >
                              <p>{suggestion.title}</p>
                              <span className="icon-search-03"></span>
                            </div>
                          </div>)
                    }
                  </div>
                </div>
              </div>

            </div>)
        }
      </Grid>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects
})
export default connect(mapStateToProps)(FiltersScreen)