import React from 'react'
import { Link } from 'react-router-dom'

const button = props => {
  return <Link to='/' className='block item item-button' style={{ background: props.appState.colorTheme.secondary }}>
    <div>
      <h2>UPS, algo salió mal</h2>
      <h4>{'  '}</h4>
    </div>
    <div className='item-action-button'>
      <p>Regresa a inicio</p>
      <span className="icon-search-03"></span>
    </div>
  </Link>
}

export default button