import { getTheme } from '../../utils/themeColors'

let headerHeight = document.getElementsByTagName('BODY')[0].offsetWidth - 4
  , headerSize = headerHeight > 1024 ? 6 : 4
  , rowHeight = document.getElementsByTagName('BODY')[0].offsetWidth - 4
  , rowSize = rowHeight > 1024 ? 6 : rowHeight > 768 ? 4 : 2

headerHeight = Math.floor(headerHeight / headerSize)
rowHeight = Math.floor(rowHeight / rowSize)

const initialState = {
  itemSize: rowHeight,
  headerItemSize: headerHeight,
  item: rowSize,
  headerItem: headerSize,
  colorTheme: getTheme('theme-1'),
  colorThemeString: 'theme-1',
  isMenuOpen: false,
  lang: 'es',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ITEM_SIZE': return { ...state, itemSize: action.itemSize }
    case 'SET_HEADER_ITEM_SIZE': return { ...state, headerItemSize: action.headerItemSize }
    case 'SET_ITEMS': return { ...state, item: action.item }
    case 'SET_HEADER_ITEMS': return { ...state, headerItem: action.headerItem }
    case 'SET_COLOR_THEME': return { ...state, colorTheme: action.colorTheme }
    case 'SET_COLOR_THEME_STRING': return { ...state, colorThemeString: action.colorThemeString }
    case 'SET_MENU_STATUS': return { ...state, isMenuOpen: action.isMenuOpen }
    case 'SET_LANG': return { ...state, lang: action.lang }
    default: return state
  }
}