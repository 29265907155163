import React from 'react'

export const ShapeOne = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M60.5,206.4h141.2C211.5,67.7,128.8,1.5,0-0.2v45C40.1,78.8,61.2,131.7,60.5,206.4z"/>
		</svg>
  ` }} />
}

export const ShapeTwo = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M141.4,129.9c5.4-33.3,36.6-47.8,64.4-54.4C175.5,40.9,116.1,4.2,62.1,34.3C10.6,62.9,1,128.6,0,207h141
			C140.1,179.3,137.9,151.7,141.4,129.9z"/>
		</svg>		
  ` }} />
}

export const ShapeTree = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M64,144.8c2.4,19.1,3,39.9,2.8,61.6h139.1c0-28.7-1.2-55.4-5.6-76.8C185.8,59.9,119.5,13,47.2,7
			C32.7,5.8,16.3,5.5,0,5.8v37.9C32.7,67.5,58.6,100.8,64,144.8z"/>
		</svg>
  ` }} />
}

export const ShapeFour = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M183.8,187.6c6.9,5.7,14.7,11,22.6,16.3V35.4c-1.1-0.8-2.3-1.7-3.4-2.5c-16.1-12-32.4-21.8-46.2-33.1H0
			c8.6,26,26.2,47.5,44.8,68.2C87,114.9,133.7,146.4,183.8,187.6z"/>
		</svg>
  ` }} />
}

export const ShapeFive = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M127.2,102.5C86,62.3,44.7,32.8,0.5,0v189.3c8.1,5.5,16.2,11.1,23.7,17.1h181.5c-0.4-1.8-0.8-3.6-1.3-5.3
			C193,156.6,156.8,131.5,127.2,102.5z"/>
		</svg>
  ` }} />
}

export const ShapeSix = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M167.4,15.9c0-5.3-0.1-10.7-0.3-16.1H38.8c-0.4,32.7,0.9,67.8,0.9,103.2c0,35.2-1.4,71.2-1.4,103.5h126.5
			c0.6-31.7,2.6-66.5,2.6-103.5V15.9z"/>
		</svg>
  ` }} />
}

export const ShapeSeven = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M206.4,186.2V62.3c-64.1,2-136-0.7-206.3-2.4v124.8h149.7C168.7,184.7,187.7,185.7,206.4,186.2z"/>
		</svg>
  ` }} />
}

export const ShapeEight = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M150.1,126c4.4-50.8,24.4-81.6,55.7-98.3v-4.5c-30.4-1.4-77.5-4.7-106.2-1.4C25.7,30.4,1.2,116.1,0,205.9h150.1
			C150,178.7,148.1,149.3,150.1,126z"/>
		</svg>			
  ` }} />
}

export const ShapeNine = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M68.2,103c0,34.8,1.7,70.7,1.7,103.5h124.2c-0.2-33.2-2.7-69-2.7-103.5c0-34.6,1.2-69.9,1.2-103.1H69.8
			C69.8,32.7,68.2,68.2,68.2,103z"/>
		</svg>
  ` }} />
}

export const ShapeTen = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M169.3,149.8c12.3,0,24.8-0.6,37.1-1.3v-128c-28.2-0.5-58.4,0.2-89.6,0.2c-39.6,0-78.9-0.2-116.9-0.2v129.4
			C57.1,150,115.3,149.8,169.3,149.8z"/>
		</svg>
  ` }} />
}

export const ShapeEleven = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M-0.1,60.1v141.2c138.7,9.9,204.9-72.9,206.6-201.7h-45C127.5,39.7,74.6,60.8-0.1,60.1z"/>
		</svg>
  ` }} />
}


export const ShapeTwelve = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M129.4,63.4C96.1,57.9,81.5,26.8,74.9-1.1C40.3,29.3,3.7,88.7,33.7,142.7c28.6,51.5,94.4,61.1,172.7,62.1v-141
			C178.7,64.7,151.1,66.9,129.4,63.4z"/>
		</svg>	
  ` }} />
}

export const ShapeThirteen = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M144.8,142c19.1-2.4,39.9-3,61.6-2.8V0.1c-28.7,0-55.4,1.2-76.8,5.6C59.9,20.2,13,86.5,7,158.9
			c-1.2,14.5-1.5,30.9-1.2,47.2h37.9C67.5,173.3,100.8,147.5,144.8,142z"/>
		</svg>
  ` }} />
}

export const ShapeFourteen = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M64.7,77.2c-5.4,33.3-36.6,47.8-64.4,54.4C30.7,166.2,90,202.9,144,172.9c51.5-28.6,61.1-94.4,62.1-172.7h-141
			C66,27.9,68.3,55.5,64.7,77.2z"/>
		</svg>
  ` }} />
}

export const ShapeFifteen = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M77,141.7c33.3,5.4,47.8,36.6,54.4,64.4c34.6-30.4,71.3-89.7,41.2-143.7C144.1,10.9,78.4,1.2,0,0.3v141
			C27.7,140.4,55.3,138.1,77,141.7z"/>
		</svg>
  ` }} />
}

export const ShapeSixteen = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M62.6,204.1h141.2C213.6,65.4,130.8-0.8,2.1-2.4v45C42.1,76.6,63.3,129.4,62.6,204.1z"/>
		</svg>
  ` }} />
}

export const ShapeSeventeen = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M169.3,149.8c12.3,0,24.8-0.6,37.1-1.3v-128c-28.2-0.5-58.4,0.2-89.6,0.2c-39.6,0-78.9-0.2-116.9-0.2v129.4
			C57.1,150,115.3,149.8,169.3,149.8z"/>
		</svg>
  ` }} />
}

export const ShapeEighteen = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M145.9-0.1H4.7c-9.9,138.7,72.9,204.9,201.7,206.6v-45C166.3,127.5,145.2,74.6,145.9-0.1z"/>
		</svg>		
  ` }} />
}

export const ShapeNineteen = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M169.3,149.8c12.3,0,24.8-0.6,37.1-1.3v-128c-28.2-0.5-58.4,0.2-89.6,0.2c-39.6,0-78.9-0.2-116.9-0.2v129.4
			C57.1,150,115.3,149.8,169.3,149.8z"/>
		</svg>
			
  ` }} />
}

export const ShapeTwenty = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M22.6,18.5C15.7,12.8,7.9,7.5,0,2.2v168.6c1.1,0.8,2.3,1.7,3.4,2.5c16.1,12,32.4,21.8,46.2,33.1h156.8
			c-8.6-26-26.2-47.5-44.8-68.2C119.4,91.2,72.7,59.8,22.6,18.5z"/>
		</svg>
  ` }} />
}

export const ShapeTwentyOne = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M18.5,184.9c-5.7,6.9-11,14.7-16.3,22.6h168.6c0.8-1.1,1.7-2.3,2.5-3.4c12-16.1,21.8-32.4,33.1-46.2V1.1
			c-26,8.6-47.5,26.2-68.2,44.8C91.2,88.1,59.8,134.8,18.5,184.9z"/>
		</svg>			
  ` }} />
}

export const ShapeTwentyTwo = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:${props.color};}
		</style>
		<path class="st0" d="M206.8,146.1V4.5C67.6-5.4,1.2,77.6-0.4,206.7h45.2C78.8,166.5,131.8,145.3,206.8,146.1z"/>
		</svg>	
  ` }} />
}

export const ShapeTwentyThree = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.4 206.4"><defs><style>.cls-1{fill:${props.color};}</style></defs><title>01</title><path class="cls-1" d="M137.24,127.78c6.11.41,12.89.65,19.8.74,10.4.12,21.11-.12,30.29-.74,4.85-37.18-7.2-61.26-30.29-73.27-17.3-9-40.79-11.24-68-7.11-.38,0-.73.1-.62.63C122.26,56.88,140,82,137.24,127.78Z"/><path class="cls-1" d="M34.57,198.8c-.32,2.51-.57,5-.76,7.6H86.16c0-2.3-.12-4.63-.25-7C70.35,198,49.14,199.16,34.57,198.8Z"/><path class="cls-1" d="M181.84,194.16a51.6,51.6,0,0,0-5.3-7.35h0l-.51-.59h0c-5.07-5.78-11.1-10.74-16.51-16.08L157,167.76c-13.83-13.23-27.76-23.18-42.68-34.38-12-9-24.25-14.83-29.69-28C76.5,85.53,93.26,68.62,104.46,63.6,98,55.73,85.28,51.69,72.3,53c-13.47,1.37-23.65,9.35-29.68,16.82-4.95,9.94-8.77,20.26-9.25,31.66v4.93a58.6,58.6,0,0,0,1.2,9.52c2.57,12.22,10.32,21.9,18.56,31.15C66.93,162.59,82,173.75,98,186.57c2.94,2.35,5.92,4.75,8.92,7.25l.42.34c5.14,4.18,11.44,7.83,16.8,12.24h62.41c-.15-.66-.3-1.32-.47-2A38.48,38.48,0,0,0,181.84,194.16Z"/><path class="cls-1" d="M206.4,175.36c-.06,3.84-.13,7.67-.21,11.45h0c-.05,2.47-.09,4.92-.14,7.35-.07,4.18-.12,8.27-.13,12.24h.48Z"/><path class="cls-1" d="M206.4,150.48V110.6C205.87,123.05,206.21,136.6,206.4,150.48Z"/></svg>
  ` }} />
}


export const ShapeTwentyFour = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<svg id="Capa_8" data-name="Capa 8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.4 206.4"><defs><style>.cls-1{fill:${props.color};}</style></defs><title>03</title><path class="cls-1" d="M154.57,194.16c0-2.63-.08-5.28-.13-7.94h0c-.14-7.6-.32-15.3-.32-22.93,0-24.16,2.53-53.29-1.24-71.65-5.32-25.88-29.69-43.28-56.28-45.48C83.24,45.05,65.39,46,55.16,47.4c21.48,7.44,44.38,23.43,47.62,49.85,2.36,19.25,0,43.23,0,66,0,7.71.29,15.39.57,22.93h0l0,.57v0h0q.14,3.7.27,7.35c.14,4.14.26,8.23.29,12.24h50.63C154.63,202.39,154.62,198.3,154.57,194.16Z"/><path class="cls-1" d="M206.32,51.25l-.17,0-.1,0h0c-37.71,11.17-33.38,56-33.38,106.35,0,9.7,0,19.37,0,28.89,0,2.54,0,5.07,0,7.59q0,6.17,0,12.24h33.75Z"/><path class="cls-1" d="M52.21,186.81h0a2.72,2.72,0,0,1,0-.28c0-.1,0-.21,0-.31h0c.24-7.37.46-15,.46-22.93V127.15c0-12.7-1.62-26.09,0-36.13,2-12.56,13.68-18.07,24.12-20.56C65.44,57.38,43.22,43.54,23,54.88a34.65,34.65,0,0,0-6.78,4.95C4.53,70.71,1,89,0,110.6v39.88q.09,6.38.1,12.81c0,4,0,8.05-.1,12.07v31H51.6c.08-4,.21-8.05.35-12.24C52,191.74,52.12,189.3,52.21,186.81Z"/></svg>

  ` }} />
}

export const ShapeTwentyFive = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<svg id="Capa_7" data-name="Capa 7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.4 206.4"><defs><style>.cls-1{fill:${props.color};}</style></defs><title>03</title><path class="cls-1" d="M103.62,186.81V141.48c0-33.74-5.49-68.65,24.12-70.4-8-13.32-33.85-27.46-53.18-16.2-30.85,18-23.22,79.58-22.9,131.39,0,1.06,0,2.13,0,3.19V206.4h52V186.81Z"/><path class="cls-1" d="M19.57,186.81h0v-.59h0c0-17.13-.06-34.19-.06-50.35,0-16.48-2.62-34,0-46.1C21.7,79.64,32.79,71.93,43,71.08c-8.66-9.42-24.64-25-42.67-19.94l0,0h0L0,51.25V206.4H19.56c0-4.07,0-8.16,0-12.24Z"/><path class="cls-1" d="M206.28,94.46a57,57,0,0,0-16.15-30.24v0c-.18-.18-.38-.35-.57-.53l0,0h0c-10.36-9.9-24.55-16.3-40.77-17.47-11.87-.86-29.31.15-40.82.62,5,4.69,11.54,4.63,16.7,7.48a60.51,60.51,0,0,1,30.92,49.22c1.78,25,1.13,54.07.37,83.31-.07,2.46-.13,4.91-.2,7.37-.1,4.08-.21,8.17-.3,12.24h50.84Z"/></svg>

  ` }} />
}

export const ShapeTwentySix = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<svg id="Capa_6" data-name="Capa 6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.4 206.4"><defs><style>.cls-1{fill:${props.color};}</style></defs><title>04</title><path class="cls-1" d="M49,124.9c7.35-.17,14.77-.37,21.48-.24,2.33-11.53-1-22.87.62-33C73.18,78.52,83.38,73.8,95.21,70.46,84.67,61.51,73.71,48.12,54.4,50.52A35.74,35.74,0,0,0,49,51.62C24.54,58.69,15.7,91.39,19.14,124,27.24,125.28,38,125.14,49,124.9Z"/><path class="cls-1" d="M.63,186.81V144c0-15.47,1.63-31.39,0-45.49-.16-1.36-.38-2.7-.63-4V206.4H.63V186.81Z"/><path class="cls-1" d="M173.11,186.81h0c-.29-7.83-.59-15.76-.59-23.52,0-21.87,1.14-44.5,0-63.55-1.27-21.13-16-37.2-29.07-44.86C127.57,45.62,101.63,45,76,46.16c-.57.66-2.28.19-1.85,1.87,23.48,7.85,45.15,22.76,47.62,54.2,1.42,18.14,0,39.89,0,61.06,0,7.6.2,15.32.38,22.92h0q.11,4,.19,7.95c.08,4.16.14,8.25.13,12.24h51.13c0-4-.13-8.1-.27-12.24Q173.25,190.5,173.11,186.81Z"/><path class="cls-1" d="M70.77,186.71c0-3.2.08-6.27.32-9.09,1.72-19.85,10-31.18,22.89-36.76-10.43-.35-29.61-1.94-40.82-.62A34.18,34.18,0,0,0,49,141c-17.87,4.42-26.57,22.92-29.78,45.21h0c0,.09,0,.19,0,.29s0,.21-.05.31h0c-.34,2.41-.59,4.87-.8,7.35-.34,4-.53,8.12-.59,12.24H71.09c0-4-.13-8.17-.22-12.24C70.81,191.63,70.77,189.13,70.77,186.71Z"/></svg>

  ` }} />
}

export const ShapeTwentySeven = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<svg id="Capa_5" data-name="Capa 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.4 206.4"><defs><style>.cls-1{fill:${props.color};}</style></defs><title>05</title><path class="cls-1" d="M77.93,70.28c9-.71,15.74-5,21.64-9.34,4.28-3.14,9.34-7.14,11.13-11.84-3-1.08-7.13-.5-10.51-1.87C84.43,40.84,86.65,21.78,86.22,0H33.87C31.35,34.94,42.14,73.1,77.93,70.28Z"/><path class="cls-1" d="M135.44,26.05c-2.52,17.21-18.32,35.6-34.63,42.37-3.92,1.62-8.17,1.12-11.13,4.36,18.7,1.09,34.34,3.09,49.47.62a60.12,60.12,0,0,0,17.95-6C179.18,56,193.57,31,186.61,0h-62.4C131.63,6.11,137.26,13.65,135.44,26.05Z"/><path class="cls-1" d="M205.92,0c0,6.53.11,12.7.48,18.32V0Z"/></svg>

  ` }} />
}

export const ShapeTwentyEight = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<svg id="Capa_4" data-name="Capa 4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.4 206.4"><defs><style>.cls-1{fill:${props.color};}</style></defs><title>06</title><path class="cls-1" d="M102.77,23.56c-2.93,21.72-19.2,37.05-35.26,44.23-3.91,1.75-8.39,2.39-11.75,5,26.2,3.3,57.11,2.37,73.6-9.35a77.81,77.81,0,0,0,14.84-15c8.05-11.59,10.06-29,10.35-48.48H103.93A167,167,0,0,1,102.77,23.56Z"/><path class="cls-1" d="M172.56,0c-.05,27,0,52.26.71,73.4,9.86-1.06,21.52-1,32.76-1h.27V0Z"/><path class="cls-1" d="M0,18.32c0,.5.07,1,.1,1.5C1.36,36.46,7,51.7,16.2,61v0A30.94,30.94,0,0,0,31,69.66c15.82,3.77,28.47-3.93,36.48-10,3.66-2.76,8.11-5.76,9.28-10-11.57-2-22.45-8.8-24.12-21.18C51.52,19.94,51.38,10.37,51.59,0H0Z"/></svg>

  ` }} />
}

export const ShapeTwentyNine = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<svg id="Capa_3" data-name="Capa 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.4 206.4"><defs><style>.cls-1{fill:${props.color};}</style></defs><title>07</title><path class="cls-1" d="M51.69,73.4c15.21,2.53,36.06,1,52,0V0h-52Z"/><path class="cls-1" d="M0,72.4c6.56,0,13-.1,18.8-.47.57-21.61.75-46.5.79-71.93H0Z"/><path class="cls-1" d="M155.46,0c-.62,25.92-.91,51.29.75,73.4,10.1.68,22,.9,33.35.7h.59c5.63-.11,11.11-.32,16.15-.64V0Z"/></svg>

  ` }} />
}

export const ShapeThirty = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.4 206.4"><defs><style>.cls-1{fill:${props.color};}</style></defs><title>08</title><path class="cls-1" d="M0,73.44l.62,0V0H0Z"/><path class="cls-1" d="M121.84,18.57C119.57,44,102.39,60.65,87.21,67.79c-4.44,2.09-9.54,1.84-13,5,18.09,1.16,34.71,3,49.47.62a58.92,58.92,0,0,0,48.24-47.35c1.43-7.84,1.8-16.7,1.74-26H122.54C122.54,6.55,122.36,12.81,121.84,18.57Z"/><path class="cls-1" d="M18.56,21.06C20.39,42.26,31,63.83,49,69.38A34.26,34.26,0,0,0,69.27,69c10.27-3.09,17.4-9.33,25.36-18.7C91.4,49.09,87,49.1,83.5,47.23,69.82,40,71.13,23.91,71.13.5c0-.16,0-.33,0-.5H17.77A210.31,210.31,0,0,0,18.56,21.06Z"/></svg>

	` }} />
}


export const ShapeThirtyOne = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:#${props.color};}
		</style>
		<path class="st0" d="M54.9,50.9c-0.2,0-0.3,0-0.3,0.3c15.4,4,23.5,15.3,22.3,36c7,0.5,15.8,0.5,22.8,0
			C103.6,57.9,83.9,46.6,54.9,50.9z"/>
		<path class="st0" d="M202.5,52.6L202.5,52.6L202.5,52.6c-0.1,0-0.2,0.1-0.3,0.1l0,0c-17.2,5-15.2,25.3-15.2,48c0,4.4,0,8.7,0,13
			c0,15.5-0.1,30.2,0.3,42.1c4.5-0.5,9.8-0.4,14.9-0.5l0,0c1.3,0,2.7,0,4,0V52.2C205,52.1,203.7,52.3,202.5,52.6z"/>
		<path class="st0" d="M94.8,113.9L94.8,113.9L94.8,113.9c-0.1-0.1-0.2-0.2-0.2-0.3l0,0c-2.3-2.6-5.1-4.8-7.5-7.3
			c-6.7-6.5-13.4-11.2-20.6-16.6C61,85.6,55.5,83,53,77c-3.7-8.9,3.9-16.6,9-18.8c-3-3.6-8.7-5.4-14.6-4.8C41.3,54,36.6,57.6,33.9,61
			c-2.3,4.5-4,9.1-4.2,14.3v2.2c0.1,1.4,0.2,2.8,0.6,4.3c1.2,5.5,4.7,9.9,8.5,14.1c6.3,7,13.1,12,20.4,17.8c1.3,1.1,2.7,2.1,4.1,3.3
			c5.6,4.6,14.4,7.7,13,17.4c-1.1,7.8-8.3,16.1-15.8,19.1c-1.8,0.7-3.7,0.5-5.1,2c8.5,0.5,15.6,1.4,22.5,0.3c14.5-2.4,26-16.1,21.4-34
			C98.3,118.6,96.7,116.1,94.8,113.9z"/>
		<path class="st0" d="M64.8,144.9c-1.4-0.5-3.3-0.2-4.8-0.8c-7.9-3.2-5.9-13.4-6.5-24.5c-7.1-0.6-16.7-0.1-23.4-0.3
			c-2.2,16.6,2.2,36.5,19.7,35.2c4.1-0.3,7.2-2.3,9.9-4.2C61.6,148.8,63.9,147,64.8,144.9z"/>
		<path class="st0" d="M143.3,145.1c-5.3-0.9-10.2-4-11-9.6c-0.8-6.2-0.5-13.6-0.2-21.7l0,0v-0.1v-0.1l0,0c0.1-3.3,0.2-6.8,0.2-10.3
			V86.9c0-5.7-0.7-11.8,0-16.3c0.9-5.7,6.2-8.2,11-9.3c-5.2-5.9-15.3-12.1-24.5-7c-1.1,0.6-2.2,1.4-3.1,2.2
			c-9.3,8.6-7.3,27.5-7.3,46.7c0,3.5-0.1,7.1-0.1,10.6l0,0c-0.1,6.5-0.2,12.7,0.1,17.8c0.6,7.5,3.1,14.4,7.3,18.6l0,0
			c1.9,1.9,4.2,3.3,6.8,3.9c7.2,1.7,13-1.8,16.6-4.5C140.8,148.4,142.8,147,143.3,145.1z"/>
		<path class="st0" d="M178,70.9c-2.4-11.7-13.5-19.5-25.6-20.5c-6.1-0.5-14.2-0.1-18.9,0.6c9.8,3.4,20.2,10.6,21.7,22.5
			c1.1,8.7,0,19.5,0,29.8c0,3.5,0.1,6.9,0.3,10.3l0,0c0,0.1,0,0.2,0,0.3l0,0l0,0c0.3,6.9,0.5,13.6-0.3,19.5c-1.3,9.8-8.7,16.7-16.1,20
			c-1.8,0.8-3.8,1.1-5.4,2.3c11.9,1.5,26,1.1,33.5-4.2c1.7-1.2,5.1-4.4,6.8-6.8c4.9-7,4.9-18.7,4.7-31l0,0c-0.1-3.4-0.1-6.9-0.1-10.3
			C178.6,92.3,179.7,79.2,178,70.9z"/>
		</svg>		
  ` }} />
}


export const ShapeThirtyTwo = props => {
	return <span style={{ width: '100%', height: '100%', transform: `rotateY(${props.y}deg)` }} dangerouslySetInnerHTML={{
		__html: `
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 206.4 206.4" style="enable-background:new 0 0 206.4 206.4;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:#${props.color};}
		</style>
		<path class="st0" d="M0,52.1v103.2c1.6,0,3.2-0.1,4.7-0.2c0.3-12.3,0.4-26.8,0.4-41.3l0,0c0,0,0,0,0-0.1s0-0.1,0-0.2l0,0
			c0-7.7,0-15.4,0-22.7c0-7.4-1.2-15.3,0-20.8c1-4.6,6-8.1,10.7-8.4C12.4,57.9,6.7,52.3,0,52.1z"/>
		<path class="st0" d="M82.8,58.4L82.8,58.4c-0.1-0.1-0.2-0.2-0.3-0.3l0,0l0,0c-4.7-4.5-11.2-7.4-18.6-7.9c-5.4-0.4-13.3,0.1-18.6,0.3
			c2.3,2.1,5.3,2.1,7.6,3.4c7.3,4,13.3,11.8,14.1,22.2c0.8,11.3,0.5,24.4,0.2,37.6c-0.4,14.7-0.8,29.5,0.1,42c4.6,0.3,10,0.4,15.2,0.3
			l0,0h0.1c0,0,0.1,0,0.2,0l0,0c2.7-0.1,5.3-0.2,7.6-0.3v-42v-0.3v-19c0-7,0.7-14.2,0-20.5C89.7,67.8,86.9,62.5,82.8,58.4z"/>
		<path class="st0" d="M169,113.7L169,113.7c-0.1-3.5-0.3-7.1-0.3-10.6c0-9.9,0.5-20.1,0-28.7c-0.6-9.5-7.3-16.8-13.2-20.2
			c-7.2-4.2-19-4.4-30.7-3.9c-0.3,0.3-1,0.1-0.8,0.8c10.7,3.5,20.6,10.3,21.7,24.5c0.6,8.2,0,18,0,27.6c0,3.4,0.1,6.9,0.2,10.3l0,0
			c0.2,6.3,0.3,12.3-0.2,17.5c-1,11.5-8.9,19-15.8,22.2c-2,0.9-4.3,0.8-5.9,2.3c8.2,0.5,15.8,1.4,22.5,0.3c10.7-1.7,19.9-10,22-21.4
			C169.5,128.4,169.3,121.2,169,113.7z"/>
		<path class="st0" d="M122.2,85.7c1.1-5.2-0.5-10.3,0.3-14.9c0.9-5.9,5.6-8.1,11-9.6c-4.8-4-9.8-10.1-18.6-9
			c-13,1.6-17.7,17.4-16.1,33.2C105,86.3,114.6,85.5,122.2,85.7z"/>
		<path class="st0" d="M30.1,54.2c-14,8.1-10.6,35.9-10.4,59.3c0,0.5,0,1,0,1.4v40.8c6.9,1.1,16.4,0.5,23.7,0c0-12.4,0-27.5,0-42.2
			c0-7,0-13.8,0-20.2c0-15.2-2.5-31,11-31.8C50.7,55.5,38.9,49.1,30.1,54.2z"/>
		<path class="st0" d="M133.2,145.3c-1.5-0.6-3.5-0.6-5.1-1.4c-6.2-3.3-5.6-10.5-5.6-21.1c0-3-0.1-6.2-0.1-9.1c0-1.4,0-2.8,0.1-4.1
			c0.8-9,4.5-14.1,10.4-16.6c-4.8-0.2-13.5-0.9-18.6-0.3c-9.4,1.1-13.9,9.9-15.5,20.8l0,0v0.1v0.1l0,0C98,119.6,98,126,98.5,132
			c1.1,12.7,9.3,25.8,23.1,21.7C126.3,152.4,129.6,149.5,133.2,145.3z"/>
		</svg>
  ` }} />
}
