import React from 'react'
import videoBanner from '../../../assets/img/Summer3.jpg'
import { getJSON } from '../../../utils/convertParams2JSON'

const YT = window.YT

export default class Video extends React.Component {

  state = {
    isVideoAvailable: false,
    isPlaying: false,
  }

  video = null

  onPlayerReady = () => { this.setState({ isVideoAvailable: true }) }

  onPlayVideo = () => {
    this.setState({ isPlaying: true }, () => this.video.playVideo())
  }

  onPlayerStateChange = status => {
    if (status.data === 0) this.setState({ isPlaying: false })
  }

  componentDidMount() {
    let url = this.props.video.url
    if (url.search('youtu.be') !== -1) {
      let shortId = url.split('/')
      shortId = shortId[shortId.length - 1]
      url = `${url}?v=${shortId}`
    }
    url = url.split('?').length > 1 ? url.split('?')[1] : 'v=qVIMj-kL4kY'
    url = getJSON(url)
    url = url.v ? url.v : 'qVIMj-kL4kY'

    this.video = new YT.Player('video-placeholder', {
      width: '100%',
      height: '100%',
      videoId: url,
      fitToBackground: true,
      pauseOnScroll: false,
      repeat: false,
      mute: true,
      playerVars: {
        modestbranding: 0,
        controls: 1,
        showinfo: 0,
        wmode: 'transparent',
        branding: 0,
        rel: 0,
        autohide: 0,
        origin: window.location.origin
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange
      }
    })
  }

  render() {
    let { colorTheme, item } = this.props.appState
      , { isPlaying, isVideoAvailable } = this.state
    return <div className='video-container'>
      <div id="video-placeholder"></div>
      {
        !isPlaying && isVideoAvailable
          ? item !== 2
            ? <div className='video-grid image-cover-bg' style={{ background: `url("${videoBanner}")` }}>
              <div className='row'>
                <div className='item item-grid' />
                <div className='item item-grid' />
              </div>
              <div className='row'>
                <div className='item item-grid' />
                <div className='item item-grid' />
              </div>
              <div className='row'>
                <div className='item item-grid' />
                {
                  !isPlaying && isVideoAvailable
                    ? <div className='item item-grid video-button' onClick={this.onPlayVideo} style={{ background: colorTheme.secondary }}>
                      <span className="icon-play_soma"></span>
                    </div>
                    : <div className='item item-grid' />
                }
              </div>
            </div>
            : <div className='video-grid image-cover-bg' style={{ background: `url("${videoBanner}")` }}>
              <div className='row'>
                <div className='play-container'>
                  <div className='column item item-grid' />
                  {
                    !isPlaying && isVideoAvailable
                      ? <div className='column item item-grid video-button' onClick={this.onPlayVideo} style={{ background: colorTheme.secondary }}>
                        <span className="icon-play_soma"></span>
                      </div>
                      : <div className='column item item-grid' />
                  }
                </div>
              </div>
            </div>
          : null
      }
    </div>
  }
}



/*
{
  !isPlaying && isVideoAvailable
    ? <div onClick={this.onPlayVideo} className='item video-button' style={{ height: itemSize, width: itemSize, background: colorTheme.secondary }}>
      <span className="icon-search-05"></span>
    </div>
    : null
}
*/