import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import getTexts from '../services/langTexts'
import { connect } from 'react-redux'


const url = 'https://somamexico.us9.list-manage.com/subscribe/post?u=b86c393cb7617be6e118bd51b&amp;id=f89783f9b0'

class Newsletter extends React.Component {

  state = {
    email: '',
    placeholder: 'correoelectronico@soma.mx'
  }

  onSearchChange = e => {
    this.setState({ email: e.target.value })
  }

  onFocus = () => this.setState({ placeholder: '' })

  onBlur = () => this.setState({ placeholder: 'correoelectronico@soma.mx' })

  render() {
    let { placeholder } = this.state
      , styles = {}
      , { lang } = this.props.appState
      , texts = getTexts('newsletter', lang)

    if (this.props.color) styles = { ...styles, background: this.props.color }

    return <div style={styles} id='newsletter' className='newsletter'>
      <div>
        <h2>{texts.title}</h2>
        <p>{texts.subtitle}</p>
        <div className='newsletter-input-container'>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <React.Fragment>
                {status !== 'success' ? <SOMAForm onFocus={this.onFocus} onBlur={this.onBlur} placeholder={placeholder} onValidated={formData => subscribe(formData)} /> : null}
                {status === "sending" && <p>{texts.sending}</p>}
                {status === "error" && <p dangerouslySetInnerHTML={{ __html: message }} />}
                {status === "success" && <p>{texts.success}</p>}
              </React.Fragment>
            )}
          />
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState,
  userAuth: state.userAuth,
})

export default connect(mapStateToProps)(Newsletter)

const SOMAForm = ({ status, message, onValidated, placeholder, onFocus, onBlur }) => {
  let email
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    })

  return <div>
    <input onFocus={onFocus} onBlur={onBlur} ref={node => (email = node)} type='text' className='newsletter-input' placeholder={placeholder} />
    <span onClick={submit} className="icon-search-05"></span>
  </div>
}