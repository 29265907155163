import React from 'react'
import { Link } from 'react-router-dom'
import { getUrlByType } from '../utils/routesDefinition'

export default class SearchResult extends React.Component {
  render() {
    return <div className='result-container'>
      {
        this.props.resultItems.map(item => <Link to={`${getUrlByType(item.objectType)}?id=${item.id}`} key={item.id} className='result-item'>
          <div>
            {
              item.programs.map(program => <h2 style={{ marginRight: '1vw' }}>{program.program.toUpperCase()}</h2>)
            }
          </div>
          <h3>{item.title}</h3>
        </Link>)
      }
    </div>
  }
}