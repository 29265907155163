import React from 'react'
import { connect } from 'react-redux'

const text = props => <div className='centered'>
  <h2 className='header-title'>{
    props.appState.lang === 'en'
      ? 'WHAT IS THE PES?'
      : '¿QUÉ ES EL PES?'
  }</h2>
</div>

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(text)