import React from 'react'
import { connect } from 'react-redux'
import Header from './components/Header'
import Grid from '../../baseComponents/Grid'
import Content from './components/Content'
import getTemplate from './template'
import Shape from '../../baseComponents/Shape'

import './RegisterScreen.css'

class RegisterScreen extends React.Component {

  getComponent = (component, props) => {
    if (component === null) return component
    switch (component) {
      case 'Content': return <Content {...props} />
      case 'Shape': return <Shape {...props} />
      default: return null
    }
  }

  render() {
    let { appState } = this.props

    return <div id='register'>
      <Header mode='register' title='' onGoBack={this.props.history.goBack} />
      <Grid>
        {
          getTemplate()[appState.item].map((item, index) => {
            return <div
              className={item.className ? item.className : ''}
              style={{ background: this.props.appState.colorTheme.base }}
              key={index} values={{ x: item.x, y: item.y, w: item.w, h: item.h, isResizable: false, static: true, fullMode: item.fullMode }}>
              {this.getComponent(item.component, { ...this.props, ...item })}
            </div>
          })
        }
      </Grid>
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(RegisterScreen)