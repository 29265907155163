import React from 'react'

const objectives = props => {
  let { appState, goals } = props
    , columnSize = appState.itemSize

  goals = goals.sort((a, b) => a.order - b.order)

  if (appState.item === 2) return <div>
    <div className='goal-container item column centered no-left row no-top' style={{ width: (columnSize * 2), height: columnSize }}>
      <h2 className='header-title'>{appState.lang === 'en' ? ' OBJECTIVES' : 'OBJETIVOS'}</h2>
    </div>
    <div className='row no-top' style={{ height: columnSize * 3, flexWrap: 'wrap', flexDirection: 'row' }}>
      {
        goals
          .map((goal, index) => <div key={index}
            className={`goal-container item goal-border ${index % 2 === 0 ? 'no-left' : 'no-right'}`}
            style={{ width: '50%', height: columnSize }} >
            <div>
              <h3>{index + 1}</h3>
              <p>{goals[index].goal}</p>
            </div>
          </div>)
      }
      <div
        className={`goal-container item goal-border no-right`}
        style={{ width: '50%', height: columnSize }} >
      </div>
    </div>
  </div>

  if (appState.item === 4) return <div>
    <div className='row no-top' style={{ height: columnSize }}>
      <div className='goal-container item column centered no-left' style={{ width: (columnSize * 2) }}>
        <h2 className='header-title'>{appState.lang === 'en' ? ' OBJECTIVES' : 'OBJETIVOS'}</h2>
      </div>
      <div className='goal-container item column' style={{ width: (columnSize) }} >
        <div>
          <h3>1</h3>
          <p>{goals[0].goal}</p>
        </div>
      </div>
      <div className='goal-container item column no-right' style={{ width: (columnSize) }} >
        <div>
          <h3>2</h3>
          <p>{goals[1].goal}</p>
        </div>
      </div>
    </div>
    <div className='row' style={{ height: columnSize }}>
      <div className='goal-container  item column no-left' style={{ width: (columnSize) + 4 }} />
      {
        goals
          .map((goal, index) => index > 1 ?
            <div key={index}
              className={`goal-container item column ${index === 5 ? 'no-right' : ''}`}
              style={{ width: (columnSize) + 2 }} >
              <div>
                <h3>{index + 1}</h3>
                <p>{goals[index].goal}</p>
              </div>
            </div> : null)
      }
    </div>
  </div>



  return <div>
    <div className='row no-top' style={{ height: columnSize }}>
      <div className='goal-container  item column no-left' style={{ width: (columnSize) }} />
      <div className='goal-container item column' style={{ width: (columnSize) }} >
        <div>
          <h3>1</h3>
          <p>{goals[0].goal}</p>
        </div>
      </div>
      <div className='goal-container item column' style={{ width: (columnSize) }} >
        <div>
          <h3>2</h3>
          <p>{goals[1].goal}</p>
        </div>
      </div>
      <div className='goal-container item column centered' style={{ width: (columnSize * 2) }}>
        <h2 className='header-title'>{appState.lang === 'en' ? ' OBJECTIVES' : 'OBJETIVOS'}</h2>
      </div>
      <div className='goal-container item column no-right' style={{ width: (columnSize) }} />
    </div>
    <div className='row' style={{ height: columnSize }}>
      <div className='goal-container item column no-left' style={{ width: (columnSize) }} />
      <div className='goal-container item column no-left' style={{ width: (columnSize) }} />
      {
        goals
          .filter((_, i) => i < goals.length)
          .map((goal, index) => index > 1 ?
            <div key={index} className='goal-container item column' style={{ width: (columnSize) }} >
              <div>
                <h3>{index + 1}</h3>
                <p>{goals[index].goal}</p>
              </div>
            </div> : null)
      }
      <div className='goal-container item column no-right' style={{ width: (columnSize) }} />
    </div>
  </div>
}

export default objectives