import React from 'react'
import Loading from '../../baseComponents/Loading'
import Header from './components/Header'
import Grid from './components/Grid'
import Content from './components/Content'
import getTemplate from './template'
import { connect } from 'react-redux'
import { getCalendarItems } from '../../services/pageResources'
import getTexts from '../../services/langTexts'
import store from '../../redux/store'
import { applyFiltersCalendar } from '../../redux/actions/calendar'
import './CalendarScreen.css'

let filterText = getTexts('calendar-screen', store.getState().appState.lang)

class CalendarScreen extends React.Component {

  state = {
    activeFilter: { key: 'noticias', name: filterText.filter2 },
    isFetching: true,
    isFetchingEnd: false,
    items: [],
    amountOfPages: 0,
    error: null,
    text: '',
    showAll: false,
    numPage: 0,
    currentPage: 0,
    filter: [
      { key: 'general', name: filterText.filter1 },
      { key: 'noticias', name: filterText.filter2 },
      { key: 'miercoles', name: 'Miércoles' },
    ]
  }

  onSwitchShowAll = () => this.setState(prevState => ({ showAll: !prevState.showAll }), this.onSearch)

  onSetPage = currentPage => this.setState({ currentPage }, this.onSearch)

  getComponent = (component, props) => {
    let { items, filter, text, numPage, } = this.state
      , { activeFilterKey, showAll, currentPage, } = this.props.calendar
      , activeFilter = null
    if (component === null) return component

    activeFilter = filter.find(f => f.key === activeFilterKey)
    switch (component) {
      case 'Content': return <Content
        pages={numPage}
        currentPage={currentPage}
        onSetPage={this.onSetPage}
        onSwitchShowAll={this.onSwitchShowAll}
        showAll={showAll}
        onSearch={this.onSearch}
        onSearchChange={this.onSearchChange}
        text={text}
        activeFilter={activeFilter}
        onApplyFilter={this.onApplyFilter} {...props}
        items={items}
        filters={this.state.filter} />
      default: return null
    }
  }

  onSearchChange = event => {
    this.setState({ text: event.target.value, })
  }

  onSearch = () => {
    this.setState({ isFetching: true, isFetchingEnd: false, }, async () => {
      try {
        let { activeFilter, text, showAll, currentPage, } = this.state
        let items = await getCalendarItems(activeFilter.key, text, showAll, currentPage)
        this.props.dispatch(applyFiltersCalendar({
          currentPage,
          showAll,
          text,
          activeFilterKey: activeFilter.key,
        }))
        this.setState({ items: items.data, isFetchingEnd: true, numPage: items.data.total_pages }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false })
          }, 800)
        })
      } catch (error) {
        this.setState({ error: error })
      }
    })
  }

  onApplyFilter = filter => {
    this.setState({ activeFilter: filter, currentPage: 0, isFetching: true, isFetchingEnd: false, }, async () => {
      try {
        let { activeFilter, text, showAll, currentPage } = this.state
        let items = await getCalendarItems(activeFilter.key, text, showAll, currentPage)
        this.props.dispatch(applyFiltersCalendar({
          currentPage,
          showAll,
          text,
          activeFilterKey: activeFilter.key,
        }))
        this.setState({ items: items.data, isFetchingEnd: true, numPage: items.data.total_pages }, () => {
          setTimeout(() => {
            this.setState({ isFetching: false })
          }, 800)
        })
      } catch (error) {
        this.setState({ error: error })
      }
    })
  }

  getData = async () => {
    try {
      let { activeFilter, text, showAll, currentPage } = this.state
      let items = await getCalendarItems(activeFilter.key, text, showAll, currentPage)
      this.setState({
        items: items.data,
        isFetchingEnd: true,
        numPage: items.data.total_pages,
      }, () => {
        setTimeout(() => {
          this.setState({ isFetching: false })
        }, 800)
      })
      this.props.dispatch(applyFiltersCalendar({ numPage: items.data.total_pages, }))
    } catch (error) {
      this.setState({ error: error })
    }
  }

  componentDidMount() {
    this.getData()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.appState.lang !== this.props.appState.lang) {
      filterText = getTexts('calendar-screen', store.getState().appState.lang)
      this.setState({
        activeFilter: { key: 'noticias', name: filterText.filter2 },
        isFetching: true,
        isFetchingEnd: false,
        items: [],
        error: null,
        text: '',
        filter: [
          { key: 'general', name: filterText.filter1 },
          { key: 'noticias', name: filterText.filter2 },
          { key: 'miercoles', name: 'Miércoles' },
        ]
      }, this.getData)
    }
  }

  render() {
    let { isFetching, isFetchingEnd } = this.state

    console.log(isFetching, isFetchingEnd)

    if (isFetching) return <Loading isOut={isFetchingEnd} />
    if (this.state.error) return null
    return <div>
      <Header />
      <Grid half>
        {
          getTemplate()[this.props.appState.item].map((item, index) => <div
            key={index}
            values={{ x: item.x, y: item.y, w: item.w, h: item.h, searchMode: item.searchMode }}
          >
            {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
          </div>)
        }
      </Grid>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects,
  calendar: state.calendar,
})
export default connect(mapStateToProps)(CalendarScreen)