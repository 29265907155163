import React from 'react'

const fullDescription = props => {
  let { appState, fullDescription } = props

  if (appState.item === 6) return <div className='container' >
    <div className='row image-cover-bg' style={{ background: `url("${fullDescription.banner}")`, minWidth: appState.itemSize * 2 }}>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
      <div className='column'>
        <div className='item-box' />
        <div className='item-box' />
      </div>
    </div>
    <div className='column full-description' style={{ minWidth: appState.itemSize * 3 }}>
      <h2>{fullDescription.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: fullDescription.paragraph }} />
    </div>
    <div className='column'>
      <div className='item-box' />
      <div className='item-box' />
    </div>
  </div>


  if (appState.item === 4) return <div className='container'>
    <div className='column full-description' style={{ minWidth: appState.itemSize * 3 }}>
      <h2>{fullDescription.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: fullDescription.paragraph }} />
    </div>
    <div className='column image-cover-bg' style={{ background: `url("${fullDescription.banner}")` }}>
      <div className='item-box' />
      <div className='item-box border-bottom' />
      <div className='item-box button no-bottom' />
    </div>
  </div>


  return <div className='container column'>
    <div className='row image-cover-bg' style={{ background: `url("${fullDescription.banner}")` }}>
      <div className='item-box-mobile' />
      <div className='item-box-mobile' />
    </div>
    <div className='full-description' style={{ minHeight: appState.itemSize * 2 }}>
      <h2>{fullDescription.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: fullDescription.paragraph }} />
    </div>

  </div>
}

export default fullDescription

/*

<div className='column'>
      <div className='item-box image-background' style={{ background: `url("${fullDescription.banner}")` }} />
      <div className='item-box' />
    </div>
    <div className='column'>
      <div className='item-box' />
      <div className='item-box' />
    </div>





*/