import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const lang = {
  'es': 'Próximo miércoles',
  'en': 'Next Wednesday'
}

const Activity = props => {
  let banner = props.activity.banner[props.appState.item]
    , data = props.activity
    , title = lang[props.appState.lang]
    , isVisible = props.isVisible
    , animationEnable = false

  let [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (isVisible) setTimeout(() => { setAnimate(true) }, props.time)
  })

  animationEnable = animate

  if (props.notAnimate) animationEnable = true

  if (props.appState.item === 2) {
    return <div className='activity' style={{ height: props.appState.itemSize * 2 }}>
      <div className='row'>
        <div className='item item-grid image-cover-bg' style={{ borderBottom: '1px black solid', overflow: 'hidden' }}>
          <div className='item image-cover-bg block-box' style={{ background: `url(${banner})`, height: '100%', marginTop: animationEnable ? 0 : props.appState.itemSize }} />
        </div>
        <div className='item item-grid image-cover-bg' style={{ borderBottom: '1px black solid', overflow: 'hidden' }}>
          <Link to={props.activity.link} className='item item-button item-container ' style={{ borderTop: '2px black solid', background: props.appState.colorTheme.secondary, marginTop: animationEnable ? 0 : props.appState.itemSize, height: props.appState.itemSize }}>
            <div>
              <h2>{title}</h2>
              <h4>{data.subtitle}</h4>
              <h4>{props.appState.lang === 'en' ? 'Free admission' : 'Entrada libre'}</h4>
            </div>
            <div className='item-action-button'>
              <p>{data.title}</p>
              <span className="icon-search-03"></span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  }

  return <React.Fragment>
    <div className='activity' style={{ height: props.appState.itemSize * 2 }}>
      <div className='row'>
        <div className='item item-grid item-left-top' style={{ height: props.appState.itemSize - 1, }} />
        <div className='item item-grid item-left-bottom' />
      </div>
      <div className='row'>
        <div className='item item-grid item-right-top' style={{ height: props.appState.itemSize - 1, }} />
        <div className='item item-grid item-right-bottom'>
          <Link to={props.activity.link} className='item item-button item-container item-grid ' data-title={data.title} style={{ height: '100%', background: props.appState.colorTheme.secondary, marginTop: animationEnable ? 0 : props.appState.itemSize, }}>
            <div>
              <h2>{title}</h2>
              <h4>{data.subtitle}</h4>
              <h4>{props.appState.lang === 'en' ? 'Free admission' : 'Entrada libre'}</h4>
            </div>
            <div className='item-action-button'>
              <p>{data.title}</p>
              <span className="icon-search-03"></span>
            </div>
          </Link>
        </div>
      </div>
    </div>
    <div className='block-box image-cover-bg' style={{ marginTop: animationEnable ? -props.appState.itemSize * 2 : 0, background: `url(${banner})`, height: props.appState.itemSize * 2 }} />
  </React.Fragment>
}

export default Activity

/*

<div className='block-box' style={{ marginTop: animationEnable ? -props.appState.itemSize * 2 : 0 }}>
    <div className='activity' style={{ height: props.appState.itemSize * 2 }}>
      <div className='row'>
        <div className='item item-grid item-left-top' />
        <div className='item item-grid item-left-bottom' />
      </div>
      <div className='row'>
        <div className='item item-grid item-left-top' />
        <div className='item item-grid item-left-bottom' />
      </div>
    </div>
    <div className='activity' style={{ background: `url(${banner})`, height: props.appState.itemSize * 2 }}>
      <div className='row'>
        <div className='item item-grid item-left-top' />
        <div className='item item-grid item-left-bottom' />
      </div>
      <div className='row'>
        <div className='item item-grid item-right-top' />
        <Link to={props.activity.link} className='item item-button item-container item-grid item-right-bottom' data-title={data.title} style={{ background: props.appState.colorTheme.secondary }}>
          <div>
            <h2>{title}</h2>
            <h4>{data.subtitle}</h4>
          </div>
          <div className='item-action-button'>
            <p>{data.title}</p>
            <span className="icon-search-03"></span>
          </div>
        </Link>
      </div>
    </div>
  </div>

*/