import React from 'react'
import getTexts from '../../../services/langTexts'

const sideButton = props => {

  let { appState } = props
    , columnSize = appState.itemSize
    , texts = getTexts('residences-screen', appState.lang)
  return <div className='container-block'>
    <div className='background' style={{ background: `url("${props.banner}")`, height: (columnSize * 2) }}>
      <div className='container'>
        <div className='column no-left no-right' style={{ width: columnSize - 2, height: (columnSize * 2) }}>
          <div className='item item-top' />
          <div className='item item-bottom item-top' />
        </div>
      </div>
    </div>
    <div onClick={props.onApplyFilter} className='container-info hover-button' data-title={texts.archiveTitle} style={{ cursor: 'pointer', background: props.appState.colorTheme.secondary }}>
      <div className='container-data button-container'>
        <div style={{ margin: '0 1vw' }}>
          <h2>{texts.archiveTitle}</h2>
          <p>{texts.residence}</p>
        </div>
        <div>
          <span className="icon-search-03"></span>
        </div>
      </div>
    </div>
  </div>
}

export default sideButton