import React from 'react'
import Loading from '../../baseComponents/Loading'
import Container from './components/Container'
import InfoBox from './components/InfoBox'
import NotFound from '../NotFoundScreen'
import getTemplate from './template'
import { connect } from 'react-redux'
import { getType } from '../../utils/routesDefinition'
import { getJSON } from '../../utils/convertParams2JSON'
import { getObject } from '../../services/pageResources'
import { favUserHandler } from '../../services/auth'
import { authUser } from '../../redux/actions/userAuth'
import Modal from '../../baseComponents/Modal'
import { getUrl } from '../../utils/routesDefinition'

import './ObjectScreen.css'

class ObjectScreen extends React.Component {

  state = {
    template: [],
    resources: null,
    isFetching: true,
    error: null,
    isModalVisible: false,
    fav: null
  }


  getComponent = (component, props) => {
    let { resources } = this.state
    let type = getType(this.props.match.url)
    if (component === null) return component
    switch (component) {
      case 'Container': return <Container {...props} resources={resources} type={type} />
      case 'InfoBox': return <InfoBox type={type} onClick={this.onClick} {...props} resources={resources} />
      default: return null
    }
  }

  onClick = async () => {
    let type = getType(this.props.match.url)
      , fav = { ...this.state.resources, objectType: type }
    if (!this.props.userAuth.id) this.setState({ isModalVisible: true, fav })
    else await favUserHandler(fav)
  }

  onGoTo = logIn => {
    let { fav } = this.state
      , { userAuth } = this.props
    this.props.dispatch(authUser({ ...userAuth, hasFavToUpdate: fav }))
    this.props.history.push(getUrl(logIn ? 'account' : 'register'))
  }

  componentDidMount() {
    let type = getType(this.props.match.url)
      , params = getJSON(this.props.location.search)
    getObject(params.id, type)
      .then(res => {
        if (this.props.location.search.search('nombre=') === -1) {
          this.props.history.replace(`${this.props.location.pathname}${this.props.location.search}&nombre=${res.objectTitle}`)
        }
        this.setState({ resources: res, template: getTemplate()[this.props.appState.item], isFetching: false })
      })
      .catch(err => {
        console.log(err)
        this.setState({ error: err, isFetching: false })
      })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({ isFetching: true }, () => {
        let type = getType(nextProps.match.url)
          , params = getJSON(nextProps.location.search)
        getObject(params.id, type)
          .then(res => {
            if (nextProps.location.search.search('nombre=') === -1) {
              this.props.history.replace(`${nextProps.location.pathname}${nextProps.location.search}&nombre=${res.objectTitle}`)
            }
            this.setState({ resources: res, template: getTemplate()[nextProps.appState.item], isFetching: false })
          })
          .catch(err => {
            console.log(err)
            this.setState({ error: err, isFetching: false })
          })
      })
    }
  }

  render() {

    let { isFetching, error, isModalVisible, resources } = this.state
      , { appState, } = this.props
      , type = getType(this.props.match.url)

    if (isFetching) return <Loading />
    if (error) return <NotFound />

    return <div id='object'>
      <div id={appState.item === 2 ? 'border-box-mobile' : 'border-box'}>
        <Container {...this.props} resources={resources} type={type} />
        <InfoBox type={type} onClick={this.onClick} {...this.props} resources={resources} />
      </div>
      {
        isModalVisible
          ? <Modal>
            <div className='login-modal-container' onClick={() => { this.setState({ isModalVisible: false }) }}>
              <div
                onClick={e => e.stopPropagation()}
                className='login-modal'
                style={{ background: appState.colorTheme.base, width: appState.itemSize * 2, height: appState.itemSize }}
              >
                <p>Inicia sesión o regístrate</p>
                <div>
                  <span onClick={() => this.onGoTo(true)}>Iniciar sesión</span>
                  <span onClick={() => this.onGoTo()}>Regístrate</span>
                </div>
              </div>
            </div>
          </Modal>
          : null
      }
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  userAuth: state.userAuth
})

export default connect(mapStateToProps)(ObjectScreen)