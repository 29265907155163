import React from 'react'
import { connect } from 'react-redux'
import Menu from '../../../baseComponents/MenuButton'
import Logo from '../../../baseComponents/Logo'
import Grid from '../../../baseComponents/HeaderGrid'
import Shape from '../../../baseComponents/Shape'


const items = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo' },
    { x: 1, y: 0, w: 1, h: 1, component: 'Shape', value: 0, axisX: 90, },
    { x: 2, y: 0, w: 1, h: 1, component: 'Block' },
    { x: 3, y: 0, w: 1, h: 1, component: 'Shape', value: 19, axisX: 90, },
    { x: 4, y: 0, w: 1, h: 1, component: 'Search' },
    { x: 5, y: 0, w: 1, h: 1, component: 'Menu' },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo' },
    { x: 1, y: 0, w: 1, h: 1, component: 'Search' },
    { x: 2, y: 0, w: 1, h: 1, component: 'Shape', value: 7 },
    { x: 3, y: 0, w: 1, h: 1, component: 'Menu' }
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: null },
  ]
}


const Search = props => <div className='centered header-close' onClick={props.onGoBack}>
  <span className="icon-search-04"></span>
</div>


const Title = props => {
  let color = props.appState.colorThemeString
  color = (color === 'theme-2' || color === 'theme-4') ? props.appState.colorTheme.level2 : props.appState.colorTheme.level1
  return <div className='centered' style={{ background: color }}>
    <h1 className='header-title header-fix' style={{ textAlign: 'center' }}>{props.title.toUpperCase()}</h1>
  </div>
}

const getComponent = (component, props) => {
  if (component === null) return component
  switch (component) {
    case 'Logo': return <Logo />
    case 'Search': return <Search {...props} />
    case 'Title': return <Title {...props} />
    case 'Shape': return <Shape {...props} />
    case 'Menu': return <Menu />
    default: return null
  }
}


const header = props => <Grid>
  {
    items[props.appState.headerItem].map((item, index) => <div
      className='header-item'
      style={{ background: props.appState.colorTheme.base }}
      key={index} values={{ x: item.x, y: item.y, w: item.w, h: item.h }}>
      {getComponent(item.component, { ...props, ...item })}
    </div>)
  }
</Grid>

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(header)
