export const setFilters = (filters = null) => ({
  type: 'SET_FILTERS',
  filters
})

export const applyFilters = (filtersApplied = null) => ({
  type: 'APPLY_FILTERS',
  filtersApplied
})

export const setInitialized = (initialized = false) => ({
  type: 'SET_INITIALIZED',
  initialized
})

export const resetAppliedFilters = () => ({
  type: 'RESET_APPLIED_FILTERS'
})

export const setAmountPages = (numPages = 0) => ({
  type: 'SET_AMOUNT_PAGES',
  numPages
})

export const setPage = (page = 0) => ({
  type: 'SET_PAGE',
  page
})