import React from 'react'
import Empty from './Empty'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUrlByType } from '../../../utils/routesDefinition'
import getTexts from '../../../services/langTexts'

class Content extends React.Component {

  state = {
    filter: 'exclusive',
    isDropdownVisible: false,
    currentYear: null,
    activeFilter: false,
  }


  setVisible = () => { this.setState(prevState => ({ isDropdownVisible: !prevState.isDropdownVisible })) }

  onSelect = cb => { this.setState({ isDropdownVisible: false }, cb) }

  getDates = elements => {
    let pivot = 0
      , dates = elements
        .map(element => new Date(element.subtitle).getFullYear())
        .sort()
        .filter(element => !Number.isNaN(element))
        .filter(element => {
          let isValid = pivot !== element
          pivot = element
          return isValid
        })

    return dates
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.active !== 'profile') this.setState({ filter: nextProps.active })
  }


  render() {
    let { active, userAuth, appState, options, videos } = this.props
      , { filter, isDropdownVisible, currentYear, activeFilter } = this.state
      , data = filter === 'artist' ? userAuth.person : userAuth.objects
      , elements = Object.keys(data).map(key => ({
        ...data[key],
        title: filter === 'artist' ? data[key].fullname : data[key].title,
        subtitle: filter === 'artist' ? `${data[key].bornplace} ${data[key].borndate}` : data[key].startDate,
      }))
      , activeElement = options.filter(option => option.type === active)[0]
      , dates = []
      , texts = getTexts('my-account', appState.lang)

    if (filter === 'exclusive') {
      elements = videos.map(video => ({ ...video, subtitle: video.startDate }))
      dates = this.getDates(elements)
      if (currentYear !== null) elements = elements.filter(element => {
        let year = element.startDate.substring(element.startDate.length - 4)
        return Number(year) === Number(currentYear)
      })

    }

    if (!elements.length) return <Empty menu={<div className='mobile-menu' style={{ background: appState.colorTheme.secondary, height: appState.itemSize / 4, borderBottom: 'solid black 2px' }}>
      <div onClick={this.setVisible} className='option-button'>{activeElement.text}</div>
      {
        isDropdownVisible
          ? <div className='option-menu' style={{ background: appState.colorTheme.base }}>
            {options.map((option, index) => <p onClick={() => this.onSelect(option.onClick)} key={index}>{option.text}</p>)}
          </div>
          : null
      }
    </div>} {...this.props} />

    let items = appState.item === 6 ? 9 : 6
      , noElements = elements.length

    for (let index = noElements; index < items; index++) elements.push({ isBlank: true })

    return <div className='object-container'>
      {
        appState.item === 2
          ? <div className='mobile-menu' style={{ background: appState.colorTheme.base, height: appState.itemSize / 3 }}>
            <div onClick={this.setVisible} className='option-button'>{activeElement.text} <span className={`icon-search-05 ${isDropdownVisible ? 'up' : 'down'}`}></span></div>
            {
              isDropdownVisible
                ? <div className='option-menu' style={{ background: appState.colorTheme.base, height: appState.itemSize }}>
                  {options.map((option, index) => <p onClick={() => this.onSelect(option.onClick)} key={index}>{option.text}</p>)}
                </div>
                : null
            }
          </div>
          : null
      }
      <div style={{ marginTop: appState.item === 2 ? (appState.itemSize / 3) - 2 : 0, width: '100%' }} />


      {
        filter === 'exclusive'
          ? <div className=' object-btn centered' style={{ height: appState.itemSize, }}>
            <div className='year-selector'>
              <div className='year-selector-data' onClick={() => { this.setState(prevState => ({ activeFilter: !prevState.activeFilter })) }}>
                <p>{currentYear !== null ? currentYear : texts.all_option}</p>
                <span className={`icon-search-05 ${activeFilter ? 'active' : ''}`} />
              </div>
              {
                activeFilter
                  ? <div className='year-options' style={{ background: this.props.appState.colorTheme.base, maxHeight: this.props.appState.itemSize * 1.25 }}>
                    <p onClick={e => { e.stopPropagation(); this.setState({ currentYear: null, activeFilter: false, }) }}>{texts.all_option}</p>
                    {dates.map((y, index) => <p onClick={e => { e.stopPropagation(); this.setState({ currentYear: Number(y), activeFilter: false, }) }} key={index}>{y}</p>)}
                  </div>
                  : null
              }
            </div>
          </div>
          : null
      }
      {
        elements.map((item, index) => item.isBlank
          ? <div key={index} className='item-button item-container object-btn' style={{ height: appState.itemSize, }} /> : <Link key={index} to={`${getUrlByType(item.objectType)}?id=${item.id}`}
            className='item-button item-container object-btn'
            data-title={item.title}
            style={{ height: appState.itemSize, }}>
            <div>
              <h2>{item.title}</h2>
              <h4>{item.subtitle}</h4>
              {
                item.featured
                  ? <h4 className='featured-object'>{texts.featured}</h4>
                  : null
              }
            </div>
            <div className='item-action-button'>
              <p></p>
              <span className="icon-search-05"></span>
            </div>
          </Link>)
      }
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects
})
export default connect(mapStateToProps)(Content)