const template = {
  6: [
    { x: 0, y: 0, w: 3, h: 3, component: 'Container', id: 'object-container' },
    { x: 3, y: 0, w: 3, h: 3, component: 'InfoBox', allowOverflow: true, id: 'object-info' },
  ],
  4: [
    { x: 0, y: 0, w: 2, h: 3, component: 'Container', id: 'object-container' },
    { x: 2, y: 0, w: 2, h: 3, component: 'InfoBox', allowOverflow: true, id: 'object-info' },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 3, component: 'Container', id: 'object-container' },
    { x: 0, y: 3, w: 2, h: 5, component: 'InfoBox', allowOverflow: true, id: 'object-info' },
  ],
  1: [
    { x: 0, y: 0, w: 3, h: 3, component: 'Container', withExtra: true },
  ]
}



const getTemplate = () => {
  return template
}

export default getTemplate
