import React from 'react'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'

const langText = {
  'es': {
    btnTitle: 'DETALLES',
    btnBottom: 'Ver más'
  },
  'en': {
    btnTitle: 'DETAILS',
    btnBottom: 'See more'
  }
}


const infoBox = props => {
  let { fullDescription, appState } = props
    , lang = langText[appState.lang]
    , columnSize = appState.itemSize

  if (appState.item === 2) return <div className='info-container no-top no-bottom'>
    <div className='column no-left item image-cover-bg' style={{ width: columnSize, background: `url("${fullDescription.banner}")` }} />
    <Link className='column no-right item item-button' style={{ width: columnSize, maxHeight: columnSize, background: appState.colorTheme.secondary }} to={getUrl('pes-about')}>
      <div>
        <h2>{lang.btnTitle}</h2>
      </div>
      <div className='item-action-button'>
        <p>{lang.btnBottom}</p>
        <span className="icon-search-03"></span>
      </div>
    </Link>
  </div>

  if (appState.item === 4) return <div className='info-container image-cover-bg no-bottom' style={{ background: `url("${fullDescription.banner}")` }}>
    <div className='column no-right' style={{ width: columnSize }}>
      <div className='item item-top' />
      <div className='item item-center item-button' style={{ maxHeight: columnSize }}>
      </div>
      <Link className='item item-bottom item-button' style={{ maxHeight: columnSize, background: appState.colorTheme.secondary }} to={getUrl('summer-about')}>
        <div>
          <h2>{lang.btnTitle}</h2>
        </div>
        <div className='item-action-button'>
          <p>{lang.btnBottom}</p>
          <span className="icon-search-03"></span>
        </div>
      </Link>
    </div>
  </div>

  return <div className='info-container image-cover-bg no-bottom' style={{ background: `url("${fullDescription.banner}")` }}>
    <div className='column no-left' style={{ width: columnSize - 2 }}>
      <div className='item item-top' />
      <div className='item item-center' />
      <div className='item item-bottom' style={{ maxHeight: columnSize, height: columnSize }} />
    </div>
    <div className='column' style={{ width: columnSize - 2 }}>
      <div className='item item-top' />
      <div className='item item-center' />
      <div className='item item-bottom item-button' style={{ maxHeight: columnSize }} />
    </div>
    <div className='column no-right' style={{ width: columnSize - 2 }}>
      <div className='item item-top' />
      <div className='item item-center' />
      <Link className='item item-bottom item-button' data-title={'DETALLES'} style={{ maxHeight: columnSize, background: appState.colorTheme.secondary }} to={getUrl('pes-about')}>
        <div>
          <h2>{lang.btnTitle}</h2>
        </div>
        <div className='item-action-button'>
          <p>{lang.btnBottom}</p>
          <span className="icon-search-03"></span>
        </div>
      </Link>
    </div>
  </div>

}
export default infoBox