import React from 'react'
import { connect } from 'react-redux'

class HeaderGrid extends React.Component {



  render() {
    let { appState } = this.props
      , size = appState.headerItemSize
      , padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , height = size
      , transition = {}

    if (this.props.middle && this.props.appState.item > 2) height = height / 2

    if (this.props.isScrollActive && appState.item > 2) {
      height = height / 2
      /* transition = {
        transition: 'all 1s ease'
      } */
    }

    /*
    if (noScroll) {
      console.log(appState)
      padding += this.getScrollbarWidth()
      size += (15 / appState.headerItem)
    }
    */

    padding = Math.round((padding - (size * appState.headerItem)) / 2)
    return <div className='header-animation' style={{ ...transition, position: 'relative', height: height + 4, background: this.props.shouldHide ? 'transparent' : 'black', paddingTop: '4px', paddingLeft: `${4 + padding}px` }}>
      {
        this.props.children.map((item, index) => {
          let { values } = item.props
          return React.cloneElement(item, {
            className: `grid-item ${item.props.className}`,
            style: {
              background: this.props.shouldHide ? 'transparent' : appState.colorTheme.base,
              height: `${(values.h * height) - 2}px`,
              transform: `translate(${size * values.x}px, ${size * values.y}px)`,
              width: `${(values.w * size) - 2}px`,
              overflow: 'hidden',
            }
          })
        })
      }
    </div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(HeaderGrid)


/*

  {
        this.props.children.map((item, index) => {
          let { values } = item.props
          return React.cloneElement(item, {
            className: 'grid-item',
            style: {
              background: appState.colorTheme.base,
              transitionProperty: 'left, top',
              background: appState.colorTheme.base,
              height: `${values.h * size - 2}px`,
              transform: `translate(${size * values.x}px, ${size * values.y}px)`,
              width: `${values.w * size - 2}px`,
            }
          })
        })
      }




  import React from 'react'
  import { connect } from 'react-redux'
  class Grid extends React.Component {
  render() {
    let { appState } = this.props
    let size = appState.itemSize
    let lastElement = this.props.children[this.props.children.length - 1].props['data-grid'].y + 1
    console.log(size * 6, appState.itemSize * 6)
    return <div style={{ position: 'relative', width: size * appState.size, height: size * lastElement }}>
      {this.props.children.map(item => {
        console.log(item)
        return React.cloneElement(item, {
          className: 'item-test',
          style: {
            transform: translate(${size * item.props['data-grid'].x - 2}px, ${size * item.props['data-grid'].y - 2}px),
            height: ${item.props['data-grid'].h * size}px,
            width: ${item.props['data-grid'].w * size}px,
            ...item.props.style,
          }
        })
      })}
    </div>
  }
  }
  const mapStateToProps = state => ({
  appState: state.appState
  })
  export default connect(mapStateToProps)(Grid)


    let template = getTemplate('template-1')[appState.item]



    console.log('padding', padding)

    return <div style={{ background: 'red', height: '100%', width: '100%', border: `${padding}px solid black` }}>
      <div style={{ position: 'relative', background: appState.colorTheme.base, height: '100%' }}>
        {
          template.map((item, index) => {
            let xPos = (size * item.x)
              , yPos = (size * item.y)
            return <div
              key={index}
              className='item-test'
              style={{
                transform: `translate(${xPos}px, ${yPos}px)`,
                height: `${(item.h * size)}px`,
                width: `${(item.w * size)}px`,
              }}
            >
              <div className='border-test'>
                {this.getComponent(item.component, { ...this.props, ...item })}
              </div>
            </div>
          })
        }
      </div>
    </div>

*/