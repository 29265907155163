import React from 'react'
import { connect } from 'react-redux'
import Modal from '../../../baseComponents/Modal'
import { applyFilters, setPage } from '../../../redux/actions/objects'
import getTexts from '../../../services/langTexts'

class Filters extends React.Component {

  state = {
    text: '',
    active: '',
    objects: {},
    showFilterBox: false,
  }

  setActive = active => {
    this.setState(prevState => ({ active: prevState.active === active ? '' : active }))
  }

  onSearchChange = event => {
    this.setState({ text: event.target.value })
  }

  onApplyFilter = (type, tag, isApplied) => {
    let { objects } = this.state
    /*     if (type === 'year') {
          objects.filtersApplied[type] = objects.filtersApplied[type].filter(filter => filter !== tag)
          // tag == objects.filtersApplied[type] ? 0 : tag
        } */
    if (isApplied) {
      objects.filtersApplied[type] = objects.filtersApplied[type].filter(filter => filter.id ? filter.id !== tag.id : filter !== tag)
    } else {
      objects.filtersApplied[type] = [...objects.filtersApplied[type], tag]
    }
    this.setState({ objects })
  }

  onSearch = (event) => {
    let { filtersApplied } = this.state.objects
    if (event) event.preventDefault()
    filtersApplied.text = this.state.text
    this.props.dispatch(applyFilters(filtersApplied))
    this.props.dispatch(setPage(0))
    this.props.onSearch()
  }

  onShowFilter = () => {
    this.setState(prevState => ({ showFilterBox: !prevState.showFilterBox }))
  }

  componentDidMount() {
    let { filtersApplied } = this.props.objects
    this.setState({ text: filtersApplied.text, objects: this.props.objects })
  }

  render() {
    let { text, active, objects } = this.state
      , { appState } = this.props
      , { filtersApplied } = objects
      , height = (appState.itemSize * 2) / 6
      , years = []
      , limit = (new Date()).getFullYear() + 2
      , padding = 0
      , texts = getTexts('objects-screen', appState.lang)

    if (appState.item === 2) {
      padding = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      padding = Math.round((padding - (appState.item * appState.itemSize)) / 2)
    }

    if (this.state.showFilterBox) {
      document.getElementsByTagName('BODY')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('BODY')[0].style.overflow = 'auto'
    }

    for (let year = 2009; year <= limit; year++) years.push(year)

    years = years.reverse()

    if (appState.item === 2) return <div style={{ position: 'relative' }}>
      <div className='filter-input-container-bottom-mobile' style={{ height: height - 2 }}>
        <div onClick={this.onShowFilter} style={{ minWidth: appState.headerItemSize }}>
          <span className="icon-search-02"></span>
        </div>
        <form onSubmit={this.onSearch}>
          <input value={text} onChange={this.onSearchChange} type='text' className='search-input' placeholder={texts.searchInputPlaceholder2} />
        </form>
      </div>
      {
        this.state.showFilterBox
          ? <Modal>
            <div className='filter-box-mobile' style={{ top: (appState.headerItemSize + height) + 4, background: 'black', paddingLeft: `${4 + padding}px`, paddingRight: '4px' }}>
              <div style={{ backgroundColor: appState.colorTheme.base, height: '100%', overflow: 'auto' }}>
                <div className='filters-container-main'>
                  <div className='filter-selector filter-fix' onClick={() => this.setActive('tag_program')}>
                    <p>{texts.programTitleBtn}</p>
                    <span className={`icon-search-05 ${active === 'tag_program' ? 'active' : ''}`} />
                  </div>
                  {
                    active === 'tag_program' &&
                    <ul className="unstyled">
                      {objects.filters.tag_program.map(item => {
                        let isApplied = filtersApplied.tag_program.find(filter => filter.tag === item.tag) !== undefined
                        return <li className='filter-item' onClick={() => this.onApplyFilter('tag_program', item, isApplied)}>
                          <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
                          <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{item.tag}</p>
                        </li>
                      })}
                    </ul>
                  }

                  <div className='filter-selector filter-fix' onClick={() => this.setActive('tag_object')}>
                    <p>{texts.activityTitleBtn}</p>
                    <span className={`icon-search-05 ${active === 'tag_object' ? 'active' : ''}`} />
                  </div>
                  {
                    active === 'tag_object' &&
                    <ul class="unstyled">
                      {objects.filters.tag_object.map(item => {
                        let isApplied = filtersApplied.tag_object.find(filter => filter.tag === item.tag) !== undefined
                        return <li className='filter-item' onClick={() => this.onApplyFilter('tag_object', item, isApplied)}>
                          <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
                          <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{item.tag}</p>
                        </li>
                      })}
                    </ul>
                  }

                  <div className='filter-selector filter-fix' onClick={() => this.setActive('tag_person')}>
                    <p>{texts.personTitleBtn}</p>
                    <span className={`icon-search-05 ${active === 'tag_person' ? 'active' : ''}`} />
                  </div>
                  {
                    active === 'tag_person' &&
                    <ul class="unstyled">
                      {objects.filters.tag_person.map(item => {
                        let isApplied = filtersApplied.tag_person.find(filter => filter.tag === item.tag) !== undefined
                        return <li className='filter-item' onClick={() => this.onApplyFilter('tag_person', item, isApplied)}>
                          <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
                          <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{item.tag}</p>
                        </li>
                      })}
                    </ul>
                  }

                  <div className='filter-selector filter-fix' onClick={() => this.setActive('tag_year')}>
                    <p>{texts.yearTitleBtn}</p>
                    <span className={`icon-search-05 ${active === 'tag_year' ? 'active' : ''}`} />
                  </div>
                  {
                    active === 'tag_year' &&
                    <ul class="unstyled">
                      {years.map(item => {
                        let isApplied = filtersApplied.year.find(filter => filter === item) !== undefined
                        return <li className='filter-item' onClick={() => this.onApplyFilter('year', item, isApplied)}>
                          <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
                          <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{item}</p>
                        </li>
                      })}
                    </ul>
                  }

                </div>
                <div className='filters-button-main'>
                  <p onClick={this.onSearch} style={{ background: appState.colorTheme.secondary }}>{texts.searchFilterBtn}</p>
                </div>
              </div>
            </div>
          </Modal>
          : null
      }
    </div>

    return <div>
      <div className='filter-input-container-bottom' style={{ height }}>
        <form onSubmit={this.onSearch} >
          <span className="icon-search-02"></span>
          <input value={text} onChange={this.onSearchChange} type='text' className='search-input' placeholder={texts.searchInputPlaceholder2} />
        </form>
      </div>
      <div className='filters-container-main'>
        <div className='filter-selector filter-fix' onClick={() => this.setActive('tag_program')}>
          <p>{texts.programTitleBtn}</p>
          <span className={`icon-search-05 ${active === 'tag_program' ? 'active' : ''}`} />
        </div>
        {
          active === 'tag_program' &&
          <ul className="unstyled">
            {objects.filters.tag_program.map(item => {
              let isApplied = filtersApplied.tag_program.find(filter => filter.tag === item.tag) !== undefined
              return <li className='filter-item' onClick={() => this.onApplyFilter('tag_program', item, isApplied)}>
                <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
                <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{item.tag}</p>
              </li>
            })}
          </ul>
        }

        <div className='filter-selector filter-fix' onClick={() => this.setActive('tag_object')}>
          <p>{texts.activityTitleBtn}</p>
          <span className={`icon-search-05 ${active === 'tag_object' ? 'active' : ''}`} />
        </div>
        {
          active === 'tag_object' &&
          <ul class="unstyled">
            {objects.filters.tag_object.map(item => {
              let isApplied = filtersApplied.tag_object.find(filter => filter.tag === item.tag) !== undefined
              return <li className='filter-item' onClick={() => this.onApplyFilter('tag_object', item, isApplied)}>
                <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
                <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{item.tag}</p>
              </li>
            })}
          </ul>
        }

        <div className='filter-selector filter-fix' onClick={() => this.setActive('tag_person')}>
          <p>{texts.personTitleBtn}</p>
          <span className={`icon-search-05 ${active === 'tag_person' ? 'active' : ''}`} />
        </div>
        {
          active === 'tag_person' &&
          <ul class="unstyled">
            {objects.filters.tag_person.map(item => {
              let isApplied = filtersApplied.tag_person.find(filter => filter.tag === item.tag) !== undefined
              return <li className='filter-item' onClick={() => this.onApplyFilter('tag_person', item, isApplied)}>
                <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
                <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{item.tag}</p>
              </li>
            })}
          </ul>
        }

        <div className='filter-selector filter-fix' onClick={() => this.setActive('tag_year')}>
          <p>{texts.yearTitleBtn}</p>
          <span className={`icon-search-05 ${active === 'tag_year' ? 'active' : ''}`} />
        </div>
        {
          active === 'tag_year' &&
          <ul class="unstyled">
            {years.map(item => {
              let isApplied = filtersApplied.year.find(filter => filter === item) !== undefined
              return <li className='filter-item' onClick={() => this.onApplyFilter('year', item, isApplied)}>
                <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
                <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{item}</p>
              </li>
            })}
          </ul>
        }

      </div>
      <div className='filters-button-main'>
        <p onClick={this.onSearch} style={{ background: appState.colorTheme.secondary }}>{texts.searchFilterBtn}</p>
      </div>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects
})
export default connect(mapStateToProps)(Filters)