import React from 'react'
import { Link } from 'react-router-dom'
import logoSoma from '../assets/img/logo.png'
import logoSomaHover from '../assets/img/logo2.png'

export default class Logo extends React.Component {

  state = {
    isHover: false
  }

  onHoverIn = () => { this.setState({ isHover: true }) }
  onHoverOut = () => { this.setState({ isHover: false }) }


  render() {
    let { isHover } = this.state
      , { isScrollActive } = this.props
    let styles = {}
    if (isScrollActive) styles.width = '35%'

    return <div className='centered-logo'>
      <Link to='/' onMouseEnter={this.onHoverIn} onMouseLeave={this.onHoverOut} className='logo-container' style={styles}>
        <img src={!isHover ? logoSoma : logoSomaHover} className='logo' alt='logo' />
      </Link>
      <div className='social-networks' style={{ marginTop: this.props.slim || isScrollActive ? '20px' : '40px' }}>
        <a href='https://www.facebook.com/SOMAmexico/' rel='noopener noreferrer' target='_blank'>
          <span className="icon-search-08"></span>
        </a>
        <a href='https://twitter.com/@SOMAmexico' rel='noopener noreferrer' target='_blank'>
          <span className="icon-search-09"></span>
        </a>
        <a href='https://www.instagram.com/SOMAmexico/' rel='noopener noreferrer' target='_blank'>
          <span className="icon-search-07"></span>
        </a>
      </div>
    </div>
  }
}