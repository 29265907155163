import React from 'react'
import getTexts from '../../../services/langTexts'

const infoBox = props => {
  let { resources } = props
    , texts = getTexts('wednesday-screen', props.appState.lang)

  if (props.appState.item !== 6) return <div className='container-block'>
    <div className='info-box' style={{ height: props.w * props.appState.itemSize, borderBottom: '2px solid black' }}>
      <h2>{resources.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: resources.description }} />
    </div>
    <div className='container-info' style={{ background: props.appState.colorTheme.secondary, height: props.appState.item === 2 ? props.appState.itemSize : 'auto' }}>
      <div onClick={props.onApplyFilter} style={{ cursor: 'pointer' }} className='container-data button-container'>
        <div>
          <h2>{texts.archiveTitle}</h2>
          <p>MIÉRCOLES DE SOMA</p>
        </div>
        <div>
          <span className="icon-search-03"></span>
        </div>
      </div>
    </div>
  </div>

  return <div className='info-box'>
    <h2>{resources.title}</h2>
    <div dangerouslySetInnerHTML={{ __html: resources.description }} />
  </div>
}

export default infoBox