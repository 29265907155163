import React from 'react'
import CalendarItem from './CalendarItem'
import Modal from '../../../baseComponents/Modal'
import { authUser } from '../../../redux/actions/userAuth'
import { favUserHandler } from '../../../services/auth'
import { getUrl } from '../../../utils/routesDefinition'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import getTexts from '../../../services/langTexts'
import Paginator from './Paginator'
import moment from 'moment'

class Content extends React.Component {

  state = {
    showFilters: false,
    isModalVisible: false,
    fav: null
  }

  getParagraph = paragraph => {
    if (typeof paragraph !== 'string') return ''
    let p = paragraph.split('</p>')[0].replace('<p>', '')
    return p.length > 150 ? `<p>${p.substring(0, 147)}...</p>` : p
  }

  onSetFav = async (e, item) => {
    e.stopPropagation()
    e.preventDefault()
    if (!this.props.userAuth.id) this.setState({ isModalVisible: true, fav: item })
    else await favUserHandler(item)
  }

  onGoTo = logIn => {
    let { fav } = this.state
      , { userAuth } = this.props
    this.props.dispatch(authUser({ ...userAuth, hasFavToUpdate: fav }))
    this.props.history.push(getUrl(logIn ? 'account' : 'register'))
  }

  onChangeMode = () => {
    /* let filters = { ...filtersBase, }
    this.props.dispatch(applyFilters(filters))
    this.props.dispatch(setInitialized(true))
    this.props.history.push(getUrl('objects')) */
    this.props.onSwitchShowAll()
  }


  onClickFilterButton = () => this.setState(prevState => ({ showFilters: !prevState.showFilters }))

  render() {
    let { appState, filters, activeFilter, showAll, pages, currentPage } = this.props
      , { calendar, nextWednesday } = this.props.items
      , { showFilters, isModalVisible } = this.state
      , size = appState.itemSize
      , calendarItems = []
      , currentMonth = 0
    let filterText = getTexts('calendar-screen', appState.lang)

    if (nextWednesday.length !== undefined) {
      nextWednesday = {
        id: null,
        nextWednesday: '',
        collaborators: [''],
        title: '',
        description: ''
      }
    }

    if (calendar.length === 0 && appState.item === 2) return <div id='calendar' className='calendar-mobile'>
      <div className='form-value'>
        <form onSubmit={this.props.onSearch}>
          <span className="icon-search-02"></span>
          <input value={this.props.text} onChange={this.props.onSearchChange} type='text' className='search-input' placeholder='Buscar en el Archivo' />
        </form>
      </div>
      <div className='calendar-filter-container'>
        <div className='calendar-active-filter' style={{ height: size / 3 }} onClick={this.onClickFilterButton}>
          <div>
            <p>{activeFilter.name}</p>
            <span className={`icon-search-05 ${showFilters ? 'active' : ''}`} />
          </div>
        </div>
        {
          showFilters
            ? <div className='calendar-filter-options' style={{ height: size, top: ((size / 3) + 2), background: appState.colorTheme.base }}>
              {filters.map(filter => <div onClick={() => this.props.onApplyFilter(filter)}>
                <div className='calendar-checkbox' style={{ background: activeFilter.key === filter.key ? appState.colorTheme.secondary : appState.colorTheme.base }} />
                <p style={{ fontStyle: activeFilter.key === filter.key ? 'bold' : 'inherit' }} key={filter.name}>{filter.name}</p>
              </div>)}
              <div onClick={this.onChangeMode} className='last-years-btn'>
                <div>
                  <p>{!showAll ? filterText.pastNews1 : filterText.nextNews1}</p>
                  <p>{!showAll ? filterText.pastNews2 : filterText.nextNews2}</p>
                </div>
                <span className='icon-search-05' />
              </div>
            </div>
            : null
        }
      </div>
      <div className='calendar-items-container centered no-results'>
        <h2 style={{ textAlign: 'center', marginBottom: '1vw' }}>:(</h2>
        <h2 style={{ textAlign: 'center' }}>{filterText.noResultTitle}</h2>
        <p>{filterText.noResultDesc}</p>
      </div>
    </div>

    if (calendar.length === 0) return <div id='calendar'>
      <div className='side-menu' style={{ minWidth: size, maxWidth: size }}>
        <div className='form-value'>
          <form onSubmit={this.props.onSearch}>
            <span className="icon-search-02"></span>
            <input value={this.props.text} onChange={this.props.onSearchChange} type='text' className='search-input' placeholder='Buscar en el Archivo' />
          </form>
        </div>
        <div className='calendar-filter-container'>
          <div className='calendar-filter-options' style={{ height: size - 2, background: appState.colorTheme.base }}>
            {filters.map(filter => <div onClick={() => this.props.onApplyFilter(filter)}>
              <div className='calendar-checkbox' style={{ background: activeFilter.key === filter.key ? appState.colorTheme.secondary : appState.colorTheme.base }} />
              <p style={{ fontWeight: activeFilter.key === filter.key ? 700 : 100 }} key={filter.name}>{filter.name}</p>
            </div>)}
            <div onClick={this.onChangeMode} className='last-years-btn'>
              <div>
                <p>{!showAll ? filterText.pastNews1 : filterText.nextNews1}</p>
                <p>{!showAll ? filterText.pastNews2 : filterText.nextNews2}</p>
              </div>
              <span className='icon-search-05' />
            </div>
          </div>
        </div>
        <div className='wednesday-item' style={{ background: appState.colorTheme.secondary }}>
          <div className='wednesday-item-container'>
            <h2>{filterText.nextWednesday}</h2>
            <h3>{nextWednesday.collaborators[0]}</h3>
            <h4>{nextWednesday.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: this.getParagraph(nextWednesday.description) }} />
          </div>
        </div>
      </div>
      <div className='calendar-items-container centered no-results'>
        <h2 style={{ textAlign: 'center', marginBottom: '1vw' }}>:(</h2>
        <h2 style={{ textAlign: 'center' }}>{filterText.noResultTitle}</h2>
        <p>{filterText.noResultDesc}</p>
      </div>
    </div>

    calendar = Object.keys(calendar)
      .map(index => {
        return { ...calendar[index], month: moment(calendar[index].firstDate).month(), year: (new Date(calendar[index].firstDate)).getFullYear(), showAll }
      })

    if (showAll) {
      calendar = calendar
        .sort((a, b) => (a.month < b.month) - (a.month > b.month))
        .sort((a, b) => (a.year < b.year) - (a.year > b.year))
    } else {
      calendar = calendar
        .sort((a, b) => (a.month > b.month) - (a.month < b.month))
    }

    currentMonth = calendar[0].month
    calendarItems.push({ type: 'month', month: calendar[0].month, year: calendar[0].year, showAll })

    for (let index = 0; index < calendar.length; index++) {
      if (currentMonth !== calendar[index].month) {
        calendarItems.push({ type: 'month', month: calendar[index].month, year: calendar[index].year, showAll })
        currentMonth = calendar[index].month
      }
      calendarItems.push(calendar[index])
    }

    if (appState.item === 2) return <div id='calendar' className='calendar-mobile'>
      <div className='form-value'>
        <form onSubmit={this.props.onSearch} >
          <span className="icon-search-02"></span>
          <input value={this.props.text} onChange={this.props.onSearchChange} type='text' className='search-input' placeholder='Buscar en el Archivo' />
        </form>
      </div>
      <div className='calendar-filter-container'>
        <div className='calendar-active-filter' style={{ height: size / 3 }} onClick={this.onClickFilterButton}>
          <div>
            <p>{activeFilter.name}</p>
            <span className={`icon-search-05 ${showFilters ? 'active' : ''}`} />
          </div>
        </div>
        {
          showFilters
            ? <div className='calendar-filter-options' style={{ height: size, top: ((size / 3) + 2), background: appState.colorTheme.base }}>
              {filters.map(filter => <div onClick={() => this.props.onApplyFilter(filter)}>
                <div className='calendar-checkbox' style={{ background: activeFilter.key === filter.key ? appState.colorTheme.secondary : appState.colorTheme.base }} />
                <p style={{ fontStyle: activeFilter.key === filter.key ? 'bold' : 'inherit' }} key={filter.name}>{filter.name}</p>
              </div>)}
              <div onClick={this.onChangeMode} className='last-years-btn'>
                <div>
                  <p>{!showAll ? filterText.pastNews1 : filterText.nextNews1}</p>
                  <p>{!showAll ? filterText.pastNews2 : filterText.nextNews2}</p>
                </div>
                <span className='icon-search-05' />
              </div>
            </div>
            : null
        }
      </div>
      <div className='calendar-items-container'>
        {
          calendarItems.map((item, index) => <CalendarItem key={index} mobile {...item} {...appState} size={size} />)
        }
        {
          pages > 1
            ? <Paginator onSearch={this.props.onSetPage} numPages={pages} page={currentPage} />
            : null
        }
      </div>
      {
        isModalVisible
          ? <Modal>
            <div className='login-modal-container' onClick={() => { this.setState({ isModalVisible: false }) }}>
              <div
                onClick={e => e.stopPropagation()}
                className='login-modal'
                style={{ background: appState.colorTheme.base, width: appState.itemSize * 2, height: appState.itemSize }}
              >
                <p>{filterText.modalTitle}</p>
                <div>
                  <span onClick={() => this.onGoTo(true)}>{filterText.modalLogin}</span>
                  <span onClick={() => this.onGoTo()}>{filterText.modalSignUp}</span>
                </div>
              </div>
            </div>
          </Modal>
          : null
      }
    </div>


    return <div id='calendar'>
      <div className='side-menu' style={{ minWidth: size, maxWidth: size }}>
        <div className='form-value'>
          <form onSubmit={this.props.onSearch} style={{ minHeight: appState.itemSize / 4 }}>
            <span className="icon-search-02"></span>
            <input value={this.props.text} onChange={this.props.onSearchChange} type='text' className='search-input' placeholder='Buscar en el Archivo' />
          </form>
        </div>
        <div className='calendar-filter-container'>
          <div className='calendar-filter-options' style={{ height: size - 2, background: appState.colorTheme.base }}>
            {filters.map((filter, index) => <div key={index} onClick={() => this.props.onApplyFilter(filter)}>
              <div className='calendar-checkbox' style={{ background: activeFilter.key === filter.key ? appState.colorTheme.secondary : appState.colorTheme.base }} />
              <p style={{ fontWeight: activeFilter.key === filter.key ? 700 : 100 }} key={filter.name}>{filter.name}</p>
            </div>)}
            <div onClick={this.onChangeMode} className='last-years-btn'>
              <div>
                <p>{!showAll ? filterText.pastNews1 : filterText.nextNews1}</p>
                <p>{!showAll ? filterText.pastNews2 : filterText.nextNews2}</p>
              </div>
              <span className='icon-search-05' />
            </div>
          </div>
        </div>
        {
          nextWednesday.id === null
            ? <div className='wednesday-item' style={{ background: appState.colorTheme.secondary }}>
              <div className='wednesday-item-container'>
                <h2>{filterText.nextWednesday}</h2>
                <h3>{nextWednesday.collaborators[0]}</h3>
                <h4>{nextWednesday.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: this.getParagraph(nextWednesday.description) }} />
              </div>
            </div>
            : <Link to={`${getUrl('objectsObject')}?id=${nextWednesday.id}`} className='wednesday-item' style={{ background: appState.colorTheme.secondary }}>
              <div className='wednesday-item-container'>
                <h2>{filterText.nextWednesday}</h2>
                <h3>{nextWednesday.collaborators[0]}</h3>
                <h4>{nextWednesday.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: this.getParagraph(nextWednesday.description) }} />
              </div>
            </Link>
        }
      </div>
      <div className='calendar-items-container'>
        {
          calendarItems.map((item, index) => <CalendarItem key={index} onClick={this.onSetFav} mobile data={item} {...item} {...appState} size={size} />)
        }
        {
          pages > 1
            ? <Paginator onSearch={this.props.onSetPage} numPages={pages} page={currentPage} />
            : null
        }
      </div>
      {
        isModalVisible
          ? <Modal>
            <div className='login-modal-container' onClick={() => { this.setState({ isModalVisible: false }) }}>
              <div
                onClick={e => e.stopPropagation()}
                className='login-modal'
                style={{ background: appState.colorTheme.base, width: appState.itemSize * 2, height: appState.itemSize }}
              >
                <p>{filterText.modalTitle}</p>
                <div>
                  <span onClick={() => this.onGoTo(true)}>{filterText.modalLogin}</span>
                  <span onClick={() => this.onGoTo()}>{filterText.modalSignUp}</span>
                </div>
              </div>
            </div>
          </Modal>
          : null
      }
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects,
  userAuth: state.userAuth
})
export default connect(mapStateToProps)(Content)