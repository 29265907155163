import React from 'react'
import { getCountryList } from '../../../utils/countries'
import { updateUserService } from '../../../services/auth'
import getTexts from '../../../services/langTexts'

export default class User extends React.Component {

  state = {
    isFormActive: false,
    avatar: '',
    name: '',
    state: '',
    country: '',
    occupation: '',
    image: null,
    formTouched: false,
    isOptionVisible: false,
    message: '',
    lastActive: ''
  }

  onSelectedImage = e => {
    const files = Array.from(e.target.files)
    this.setState({ image: files[0], formTouched: true, avatar: URL.createObjectURL(files[0]) })
  }

  onSelectImage = () => { this.imageSelector.click() }

  onClickInput = () => { this.setState(prevState => ({ isOptionVisible: !prevState.isOptionVisible })) }

  onSetOption = country => { this.setState({ country, isOptionVisible: false, formTouched: true }) }

  onCancel = () => {
    let user = this.props.userAuth
    this.setState({
      image: null,
      formTouched: false,
      isOptionVisible: false,
      avatar: user.avatar,
      name: user.user.name,
      state: user.user.state,
      country: user.user.country,
      occupation: user.user.ocupation
    }, () => {
      this.props.onSetType(this.state.lastActive)
    })
  }

  onUpdateUserHandler = async () => {
    let user = this.props.userAuth
      , { image, name, state, country, occupation } = this.state
    let res = await updateUserService({
      id: user.id,
      email: user.user.email,
      name: name.length < 3 ? user.user.name : name,
      state: state.length ? state : user.user.state,
      occupation: occupation.length ? occupation : user.user.ocupation,
      country: country.length ? country : user.user.country,
      image,
      birthYear: user.user.birth_year
    })
    this.setState({ message: res.status === 200 ? '¡Tus cambios se han realizado!' : '¡Oh no! Algo salió mal, intenta otra vez.' })
  }

  componentDidMount() {
    this.setState({ lastActive: this.props.active })
    if (this.props.active === 'profile') {
      let user = this.props.userAuth
      this.setState({
        isFormActive: this.props.active === 'profile',
        avatar: user.avatar,
        name: user.user.name,
        state: user.user.state,
        country: user.user.country,
        occupation: user.user.ocupation
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.props.active) {
      let user = this.props.userAuth
      if (nextProps.active !== 'profile') this.setState({ lastActive: nextProps.active })
      this.setState({
        isFormActive: nextProps.active === 'profile',
        avatar: user.avatar,
        name: user.user.name,
        state: user.user.state,
        country: user.user.country,
        occupation: user.user.ocupation
      })
    }
  }

  render() {
    let { active, userAuth, appState, edit, options } = this.props
      , { isFormActive, avatar, name, state, country, occupation, formTouched, isOptionVisible } = this.state
      , texts = getTexts('my-account', appState.lang)

    if (isFormActive) {
      return <div className='user-container'>
        <input ref={ref => this.imageSelector = ref} style={{ display: 'none' }} type='file' id='avatar' name='avatar' accept='image/png, image/jpeg' onChange={this.onSelectedImage} />
        <div onClick={this.onSelectImage} className='image-cover-bg' style={{ height: this.props.appState.itemSize, background: `url('${avatar}')` }}>
          <div className='item-button image-cover-bg centered' style={{ height: this.props.appState.itemSize, background: 'rgba(0, 0, 0, 0.4)' }} >
            <span className='icon-search-03 select-icon' style={{ color: appState.colorTheme.secondary }} />
          </div>
        </div>

        <div className='user-data-container'>
          <h4>{texts.hello},</h4>
          <input type='text' placeholder={userAuth.user.name} value={name} onChange={e => { this.setState({ name: e.target.value, formTouched: true }) }} />
          <div >
            <p>{texts.location}: </p>
            <input type='text' placeholder={userAuth.user.state} value={state} onChange={e => { this.setState({ state: e.target.value, formTouched: true }) }} />
            <div>
              <div onClick={this.onClickInput} className='select-input'>{country.length ? country : userAuth.user.country}</div>
              {
                isOptionVisible
                  ? <div className='select-input-option' style={{ height: this.props.appState.itemSize / 2, background: this.props.appState.colorTheme.base }}>
                    {getCountryList(this.props.appState.lang).map(item => <p onClick={() => this.onSetOption(item.name)}>{item.name}</p>)}
                    {this.state.showAlert ? <p>{this.state.fieldMessage}</p> : null}
                  </div>
                  : null
              }
            </div>
            <p className='margin-top'>{texts.profession}:</p>
            <input type='text' placeholder={userAuth.user.ocupation} value={occupation} onChange={e => { this.setState({ occupation: e.target.value, formTouched: true }) }} />
            <div className='form-container'>
              <p onClick={this.onCancel}>{texts.cancel}</p>
              {
                formTouched
                  ? <p onClick={this.onUpdateUserHandler}>{texts.save}</p>
                  : null
              }
            </div>

            <p className='message'>{this.state.message}</p>
          </div>
        </div>
      </div>
    }

    if (appState.item > 2) return <div className='user-container'>
      <div className='image-cover-bg' style={{ height: this.props.appState.itemSize, background: `url('${userAuth.avatar}')` }} />
      <div className='user-data-container'>
        <h2>{userAuth.user.name}</h2>
        <div className='data-block'>
          <p>{texts.location}: {userAuth.user.state}, {userAuth.user.country}</p>
          <p>{texts.profession}: {userAuth.user.ocupation}</p>
          <div className='button' onClick={edit.onClick}>
            <p>{edit.text}</p>
            <span className='icon-search-05' />
          </div>
        </div>

        <ul className='unstyled'>
          {options.map((option, index) => {
            let isApplied = option.type === active
            return <li key={index} className='filter-item' onClick={option.onClick}>
              <div className='checkbox' style={{ backgroundColor: isApplied ? appState.colorTheme.secondary : 'transparent' }} />
              <p className='li-text' style={{ color: isApplied ? appState.colorTheme.secondary : 'black' }}>{option.text}</p>
            </li>
          })}
        </ul>
      </div>
    </div>

    return <div className='user-container'>
      <div className='image-cover-bg' style={{ height: this.props.appState.itemSize, background: `url('${userAuth.avatar}')` }} />
      <div className='user-data-container'>
        <h4>{texts.hello},</h4>
        <h2>{userAuth.user.name}</h2>
        <div className='data-block'>
          <p>{texts.location}: {userAuth.user.state}, {userAuth.user.country}</p>
          <p>{texts.profession}: {userAuth.user.ocupation}</p>
          {
            appState.item !== 2
              ? <div className='button' onClick={edit.onClick}>
                <p>{edit.text}</p>
                <span className='icon-search-05' />
              </div>
              : null
          }
        </div>
      </div>
    </div>
  }
}