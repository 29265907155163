import React from 'react'
import Image from '../../../baseComponents/Image'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../utils/routesDefinition'
import { connect } from 'react-redux'
import Menu from '../../../baseComponents/MenuButton'
import Logo from '../../../baseComponents/Logo'
import Shape from '../../../baseComponents/Shape'

import Grid from '../../../baseComponents/HeaderGrid'


const items = {
  6: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo' },
    { x: 1, y: 0, w: 1, h: 1, component: 'Block' },
    { x: 2, y: 0, w: 1, h: 1, component: 'Block', },
    { x: 3, y: 0, w: 1, h: 1, component: 'Block', },
    { x: 4, y: 0, w: 1, h: 1, component: 'Block' },
    { x: 5, y: 0, w: 1, h: 1, component: 'Menu' },
  ],
  4: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Logo' },
    { x: 1, y: 0, w: 1, h: 1, component: 'Block' },
    { x: 2, y: 0, w: 1, h: 1, component: 'Block' },
    { x: 3, y: 0, w: 1, h: 1, component: 'Menu' }
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: null },
  ]
}


const Search = props => <div onClick={props.onActiveSearch} className='centered search-btn header-item'>
  <span className="icon-search-02"></span>
</div>

const Register = props => !props.userAuth.id
  ? <Link to={getUrl('register')} className='centered register-button' style={{ background: props.appState.colorTheme.secondary }} onClick={props.onClick}>
    <h2>REGÍSTRATE</h2>
    <p>Crea tu propio archivo</p>
  </Link>
  : <Link to={getUrl('account')} className='centered register-button' style={{ background: props.appState.colorTheme.secondary }} onClick={props.onClick}>
    <h2>Mi cuenta</h2>
    <p>Ver mis archivos</p>
  </Link>




/*


<div className='centered register-button hover-button'
  style={{ background: props.appState.colorTheme.secondary }}
  onClick={() => { document.getElementById('newsletter').scrollIntoView() }}
  data-title="Suscríbete">
  <h2 style={{ alignSelf: 'center', textAlign: 'center' }}>Suscríbete</h2>
</div>


*/

const getComponent = (component, props) => {
  if (component === null) return component

  switch (component) {
    case 'Logo': return <Logo />
    case 'Search': return <Search {...props} />
    case 'Wildcard': {
      if (props.template !== 'template-4' && props.item !== 1)
        return <Image {...props} cover={props.item === 1 ? props.banner[0] : props.banner[1]} />
      if (props.template === 'template-3' && props.item === 1)
        return <Image {...props} cover={props.item === 1 ? props.banner[0] : props.banner[1]} />
      let values = { value: 7 }
      if (props.item === 1) {
        values.value = 3
        values.axisX = 270
      }
      return <Shape {...props} {...values} />
    }
    case 'Register': return <Register {...props} />
    case 'Menu': return <Menu />
    default: return null
  }
}


const header = props => <Grid>
  {
    items[props.appState.headerItem].map((item, index) => <div
      className='header-item'
      style={{ background: props.appState.colorTheme.base }}
      key={index} values={{ x: item.x, y: item.y, w: item.w, h: item.h }}>
      {getComponent(item.component, { ...props, ...item })}
    </div>)
  }
</Grid>

const mapStateToProps = state => ({
  appState: state.appState,
  userAuth: state.userAuth,
})

export default connect(mapStateToProps)(header)

/*

<ResponsiveGridLayout
  className='layout'
  breakpoints={{ lg: 1200, md: 1024, sm: 800, xs: 768, xxs: 0 }}
  cols={{ lg: 6, md: 6, sm: 4, xs: 4, xxs: 4 }}
  margin={[4, 0]}
  rowHeight={props.appState.headerItemSize}
>
  {
    items[props.appState.headerItem].map((item, index) => <div
      className='header-item'
      style={{ background: props.appState.colorTheme.base }}
      key={index} data-grid={{ x: item.x, y: item.y, w: item.w, h: item.h, isResizable: false, static: true }}>
      {getComponent(item.component, props)}
    </div>)
  }
</ResponsiveGridLayout>

*/