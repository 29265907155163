import React from 'react'
import Loading from '../../baseComponents/Loading'
import Header from './components/Header'
import Grid from '../../baseComponents/Grid'
import User from './components/User'
import NotFound from '../NotFoundScreen'
import Button from './components/Button'
import Content from './components/Content'
import getTemplate from './template'
import { connect } from 'react-redux'
import { getFavObjects, getFavPersons, getFavExclusiveVideos } from '../../services/auth'
import getTexts from '../../services/langTexts'

import './ObjectsScreen.css'

class ObjectsScreen extends React.Component {

  state = {
    isFetching: true,
    items: [],
    error: null,
    active: 'exclusive',
    exclusiveVideos: []
  }


  getComponent = (component, props) => {
    let { active, exclusiveVideos } = this.state
    if (component === null) return component
    switch (component) {
      case 'User': {
        let data = [this.getSettings('exclusive'), this.getSettings('objects'), this.getSettings('artist')]
        return <User onSetType={this.onSetType} options={data} edit={this.getSettings('profile')} {...props} active={active} />
      }
      case 'Content': {
        let data = [this.getSettings('exclusive'), this.getSettings('objects'), this.getSettings('artist'), this.getSettings('profile')]
        return <Content videos={exclusiveVideos} options={data} {...props} active={active} />
      }
      case 'Filter': {
        let data = this.getSettings(props.type)
        return <Button {...props} {...data} active={active === props.type} />
      }
      default: return null
    }
  }

  getSettings = type => {
    let { lang } = this.props.appState
      , texts = getTexts('my-account', lang)
    switch (type) {
      case 'exclusive': return { type: 'exclusive', text: texts.extended_videos, onClick: () => this.onSetType('exclusive') }
      case 'artist': return { type: 'artist', text: texts.followed_artists, onClick: () => this.onSetType('artist') }
      case 'objects': return { type: 'objects', text: texts.activities, onClick: () => this.onSetType('objects') }
      case 'profile': return { type: 'profile', text: texts.edit_profile, onClick: () => this.onSetType('profile') }
      default: return { type: 'artist', text: texts.followed_artists, onClick: () => this.onSetType('artist') }
    }
  }

  onSetType = type => this.setState({ active: type })

  async componentDidMount() {
    try {
      await getFavObjects()
      await getFavPersons()
      let exclusiveVideos = await getFavExclusiveVideos()
      this.setState({ isFetching: false, exclusiveVideos })
    } catch (error) {
      this.setState({ isFetching: false, error, })
    }
  }

  render() {
    if (this.state.isFetching) return <Loading />
    if (this.state.error) return <NotFound />
    return <div id='myAccount'>
      <Header />
      <Grid half>
        {
          getTemplate()[this.props.appState.item].map((item, index) => <div
            key={index}
            values={{ x: item.x, y: item.y, w: item.w, h: item.h, searchMode: item.searchMode }}
          >
            {this.getComponent(item.component, { ...this.props, ...this.state.settings, ...item })}
          </div>)
        }
      </Grid>
    </div>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  objects: state.objects,
  userAuth: state.userAuth,
})
export default connect(mapStateToProps)(ObjectsScreen)
