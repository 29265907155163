import React from 'react'

const imageGrid = props => {

  let { appState, banner, position } = props
    , columnSize = appState.itemSize
    , adjust = position === 'left' ? 1 : 3
  banner = typeof banner === 'string' ? banner : 'https://source.unsplash.com/1600x900/?reading'

  if (appState.item === 2) return <div className='container image-cover-bg' style={{ background: `url("${banner}")`, height: columnSize * 2 }}>
    <div className={`column ${position === 'left' ? 'no-left' : ''}`} style={{ width: columnSize - 2 }}>
      <div className='item item-top' />
      <div className='item item-bottom' />
    </div>
    <div className={`column ${position === 'right' ? 'no-right' : ''}`} style={{ width: columnSize - adjust }}>
      <div className='item item-top' />
      <div className='item item-bottom' />
    </div>
  </div>


  if (appState.item === 4) return <div className='container image-cover-bg' style={{ background: `url("${banner}")` }}>
    <div className={`column ${position === 'left' ? 'no-left' : ''}`} style={{ width: columnSize }}>
      <div className='item item-top' />
      <div className='item item-bottom' />
    </div>
    <div className={`column ${position === 'right' ? 'no-right' : ''}`} style={{ width: columnSize - adjust }}>
      <div className='item item-top' />
      <div className='item item-bottom' />
    </div>
  </div>

  return <div className='container image-cover-bg' style={{ background: `url("${banner}")` }}>
    <div className={`column ${position === 'left' ? 'no-left' : ''}`} style={{ width: columnSize }}>
      <div className='item item-top' />
      <div className='item item-bottom' />
    </div>
    <div className='column' style={{ width: columnSize }}>
      <div className='item item-top' />
      <div className='item item-bottom' />
    </div>
    <div className={`column ${position === 'right' ? 'no-right' : ''}`} style={{ width: columnSize - adjust }}>
      <div className='item item-top' />
      <div className='item item-bottom' />
    </div>
  </div>
}

export default imageGrid